export enum HttpErrorCode {
    Unknown = -1,
    OK = 200,
    Created = 201,
    Accepted = 202,
    NoContent = 204,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    Conflict = 409,
    UnprocessableEntity = 422,
    InternalServerError = 500,
}
