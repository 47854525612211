<a class="button"
   [ngClass]="[size, theme]"
   [class.is-loading]="loading"
   [attr.tabindex]="tabindex"
   [class.has-icon]="!!icon"
   [class.disabled]="disabled || loading"
   [attr.target]="openInNewTab ? '_blank' : null"
   [attr.href]="href !== '' ? href : null"
   (click)="navigate()"
   (keydown.enter)="navigate()">
    <app-spinner class="loader" *ngIf="loading"></app-spinner>
    <app-icon *ngIf="icon" [icon]="icon" size="18px"></app-icon>
    <ng-content></ng-content>
</a>
