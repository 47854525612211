import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ofActionCompleted, Select } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import { PerpetualLicensesState } from '../../states/perpetual-licenses.state';
import { FetchPerpetualLicenses } from '../../states/perpetual-licenses.actions';
import { PushMenuActiveAccountId } from 'src/app/states/menu.actions';
export class PerpetualLicensesPageComponent {
    constructor(store, actions, route, title) {
        this.store = store;
        this.actions = actions;
        this.route = route;
        this.title = title;
        this.licenses = [];
        /**
         * Określa czy aktualnie trwa pobieranie danych.
         */
        this.isLoading = false;
        this.gc = new Subject();
        /**
         * Token służący do anulowania pobierania danych.
         */
        this.cancellationToken = new Subject();
    }
    ngOnInit() {
        this.title.setTitle('Perpetual licenses - Dataedo Account');
        this.initEvents();
    }
    fetchData() {
        this.cancellationToken.next();
        this.isLoading = true;
        this.store.dispatch(new FetchPerpetualLicenses(this.accountId, this.cancellationToken));
    }
    initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));
        this.licensesListener
            .pipe(takeUntil(this.gc))
            .subscribe(licenses => this.licenses = licenses);
        this.actions
            .pipe(ofActionCompleted(FetchPerpetualLicenses))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchLicenses(event));
    }
    onPathChange(params) {
        this.accountId = parseInt(params.accountId, 10);
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));
        this.fetchData();
    }
    onCompletedFetchLicenses(event) {
        const response = ResponseHandlingHelper.parse(event.result);
        this.isLoading = false;
    }
    trackLicenseById(index, license) {
        return license.id;
    }
    ngOnDestroy() {
        this.cancellationToken.next();
        this.cancellationToken.complete();
        this.gc.next();
        this.gc.complete();
    }
}
tslib_1.__decorate([
    Select(PerpetualLicensesState.licenses)
], PerpetualLicensesPageComponent.prototype, "licensesListener", void 0);
