import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngxs/store';
import {HttpClient} from '@angular/common/http';
import {finalize, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnDestroy {

    @Input()
    public name: string;

    /**
     * Adres do pobrania faktury.
     */
    @Input()
    public fileUrl: string | null;

    public isLoading = false;

    private cancellationToken = new Subject();

    constructor(
        private store: Store,
        private http: HttpClient
    ) {
    }

    public download() {
        if (this.isLoading) {
            // Pobieranie aktualnie trwa.
            return;
        }

        this.isLoading = true;

        this.http.get(this.fileUrl, {responseType: 'blob'})
            .pipe(finalize(() => this.isLoading = false))
            .pipe(takeUntil(this.cancellationToken))
            .subscribe(blob => this.onFileReady(blob));
    }

    private onFileReady(blob) {
        const link = document.createElement('a');
        link.download = this.name;
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    public ngOnDestroy(): void {
        this.cancellationToken.next();
        this.cancellationToken.complete();
    }
}
