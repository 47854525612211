import {Subject} from 'rxjs';

export class FetchSessions {
    public static readonly type = '[Sessions] FetchSessions';

    constructor(public cancellationToken?: Subject<any>) {
    }

}

export class RevokeSession {
    public static readonly type = '[Sessions] RevokeSession';

    constructor(public sessionId: number, public cancellationToken?: Subject<any>) {
    }

}
