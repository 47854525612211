import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-timestamp',
    templateUrl: './timestamp.component.html',
    styleUrls: ['./timestamp.component.scss']
})
export class TimestampComponent {

    @Input()
    public timestamp: number;

}
