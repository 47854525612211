import {Subject} from 'rxjs';

export class FetchOrders {
    public static readonly type = '[Orders] FetchOrders';

    constructor(public accountId: number, public cancellationToken?: Subject<any>) {
    }

}

export class FetchOrder {
    public static readonly type = '[Orders] FetchOrder';

    constructor(public accountId: number, public orderNo: string, public cancellationToken?: Subject<any>) {
    }

}
