import {Action, Actions, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {mergeMap, takeUntil, tap} from 'rxjs/operators';
import {TrialsRepositoryService} from '../api/repositories/trials-repository.service';
import {FetchTrials} from './trials.actions';
import {TrialsModelResponse} from '../api/responses/trials-response';
import {Logout} from './auth.actions';
import {SessionsRepositoryService} from '../api/repositories/sessions-repository.service';
import {SessionsModelResponse} from '../api/responses/sessions-response';
import {FetchSessions, RevokeSession} from './sessions.actions';
import IntercomHelper from '../helpers/intercom-helper';

interface SessionsStateModel {
    sessions: SessionsModelResponse[];
}

@State<SessionsStateModel>({
    name: 'Sessions',
    defaults: {
        sessions: [],
    }
})
export class SessionsState {
    constructor(
        private actions: Actions,
        private sessionsRepository: SessionsRepositoryService
    ) {
    }

    @Selector()
    public static sessions(state: SessionsStateModel): SessionsModelResponse[] {
        return state.sessions;
    }

    @Action(FetchSessions)
    public fetchSessions(ctx: StateContext<SessionsStateModel>, action: FetchSessions) {
        let request = this.sessionsRepository.list()
            .pipe(tap(response => ctx.patchState({
                sessions: response.data.sessions
            })));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(RevokeSession)
    public revokeSession(ctx: StateContext<SessionsStateModel>, action: RevokeSession) {
        return this.sessionsRepository.revoke(action.sessionId)
            .pipe(mergeMap(() => ctx.dispatch(new FetchSessions(action.cancellationToken))));
    }

    @Action(Logout)
    public logout(ctx: StateContext<SessionsStateModel>, action: Logout) {
        ctx.patchState({
            sessions: [],
        });
    }
}
