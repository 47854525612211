import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PackagesRepositoryService {
    constructor(http) {
        this.http = http;
    }
    getModules(packageCode) {
        return this.http.get(`${environment.apiUrl}v1/packages/${packageCode}/modules`);
    }
}
PackagesRepositoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PackagesRepositoryService_Factory() { return new PackagesRepositoryService(i0.ɵɵinject(i1.HttpClient)); }, token: PackagesRepositoryService, providedIn: "root" });
