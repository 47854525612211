import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import AccountsResponse from '../responses/accounts-response';
import AccountSubscriptionResponse from 'src/app/api/responses/account-subscription.response';
import AccountBillingResponse from 'src/app/api/responses/account-billing-response';
import FormDataHelper from 'src/app/helpers/form-data-helper';
import AccountSummaryResponse from '../responses/account-summary.response';
import OfflineLicenseResponse from '../responses/offline-license-response';

@Injectable({
    providedIn: 'root'
})
export class AccountsRepositoryService {
    constructor(private http: HttpClient) {
    }

    public list(): Observable<AccountsResponse> {
        return this.http.get<AccountsResponse>(`${environment.apiUrl}v1/accounts`);
    }

    public summaryByAccountId(accountId: number): Observable<AccountSummaryResponse> {
        return this.http.get<AccountSummaryResponse>(`${environment.apiUrl}v1/accounts/${accountId}`);
    }

    public subscriptionByAccountId(accountId: number): Observable<AccountSubscriptionResponse> {
        return this.http.get<AccountSubscriptionResponse>(`${environment.apiUrl}v1/accounts/${accountId}/subscription`);
    }

    public billingByAccountId(accountId: number): Observable<AccountBillingResponse> {
        return this.http.get<AccountBillingResponse>(`${environment.apiUrl}v1/accounts/${accountId}/billing`);
    }

    public updateBillingByAccountId(accountId: number, data: { country: string; vatID: string }): Observable<void> {
        return this.http.put<void>(
            `${environment.apiUrl}v1/accounts/${accountId}/billing`,
            FormDataHelper.convertToUrlEncoded(data),
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
        );
    }

    public offlineLicense(accountId: number): Observable<string> {
        return this.http.get(`${environment.apiUrl}v1/accounts/${accountId}/offline-license`, {responseType: 'text'});
    }
}
