<div *ngIf="visible" class="modal">
    <div #modal class="modal-box" [style.width]="width">
        <div class="modal-header" *ngIf="showHeader">
            <ng-content select="[modalHeader]"></ng-content>
        </div>

        <div class="modal-content">
            <ng-content></ng-content>
        </div>

        <div class="modal-footer" *ngIf="showFooter">
            <ng-content select="[modalFooter]"></ng-content>
        </div>
    </div>
</div>
