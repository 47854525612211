import {Action, Selector, State, StateContext} from '@ngxs/store';
import {SessionsRepositoryService} from '../api/repositories/sessions-repository.service';
import {PopAlert, PushAlert} from './alerts.actions';
import {append, patch, removeItem, updateItem} from '@ngxs/store/operators';

export interface AlertOptions {
    /**
     * Traktuj treść wiadomości jako HTML.
     *
     * Domyślnie wyłączone.
     */
    html?: boolean;

    /**
     * Czas wyświetlania wiadomości.
     *
     * Wartość zero lub mniejsza oznacza, że wiadomość
     * będzie widoczna aż do zamknięcia. Wymusza to włączenie
     * opcji "cancellable".
     *
     * Domyślnie obliczane na podstawie liczby znaków.
     */
    duration?: number;

    /**
     * Typ wiadomosci okreslajacy sposob jej wyswietlania.
     *
     * Domyślnie "info".
     */
    type?: 'info' | 'success' | 'warning' | 'error';

    /**
     * Określa czy powiadomienie ma być natychmiast zamknięte
     * przy pojawieniu się nowego powiadomienia.
     *
     * W przypadku krytycznych powiadomień opcja powinna
     * być wyłączona aby użytkownik mógł przeczytać wiadomość.
     *
     * Domyślnie włączone.
     */
    overridable?: boolean;

    /**
     * Określa czy można ręcznie zamknąć alert.
     *
     * Domyślnie włączone.
     */
    cancellable?: boolean;
}

export interface Alert {
    message: string;
    options?: AlertOptions;
}

interface AlertsStateModel {
    alerts: Alert[];
}

@State<AlertsStateModel>({
    name: 'Alerts',
    defaults: {
        alerts: [],
    }
})
export class AlertsState {
    @Selector()
    public static topAlert(state: AlertsStateModel): Alert | null {
        return state.alerts.length > 0 ? state.alerts[0] : null;
    }

    @Action(PushAlert)
    public pushAlert(ctx: StateContext<AlertsStateModel>, action: PushAlert) {
        action.options = {
            // Domyslna konfiguracja.
            html: false,
            duration: 800 + (80 * action.message.length),
            type: 'info',
            overridable: true,
            cancellable: true,

            // Nadpisanie konfiguracją użytkownika.
            ...action.options
        };

        const newAlert = {
            message: action.message,
            options: action.options
        };

        // Wyszukiwanie pierwszego powiadomienia w kolejce
        // ktore moze byc nadpisane przez kolejne powiadomienie.
        const alerts = ctx.getState().alerts;
        const overrideableIndex = alerts.findIndex(x => x.options.overridable);
        if (overrideableIndex >= 0) {
            return ctx.setState(patch({
                alerts: updateItem<Alert>(overrideableIndex, newAlert)
            }));
        }

        // Jesli nie ma w kolejce malo waznych powiadomien
        // to nowe powiadomienie musi zostaje dodane na koniec kolejki.
        return ctx.setState(patch({
            alerts: append<Alert>([newAlert])
        }));
    }

    @Action(PopAlert)
    public popAlert(ctx: StateContext<AlertsStateModel>, action: PopAlert) {
        ctx.setState(patch({
            alerts: removeItem<Alert>(0)
        }));
    }
}
