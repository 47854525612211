import {ControlValueAccessor} from '@angular/forms';
import {Input} from '@angular/core';

export default class BaseFormComponent implements ControlValueAccessor {

    @Input()
    public disabled = false;

    public value: any = null;

    protected onChangeCallback = (_: any) => {
        // Should be empty.
    };

    public registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn: any): void {
        // Not used, used for touch input.
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public writeValue(obj: any): void {
        this.value = obj;
        this.onChangeCallback(this.value);
    }

    public onChange(value: any) {
        if (this.value !== value) {
            this.writeValue(value);
        }
    }

}
