/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./licenses-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../elements/spinner/spinner.component.ngfactory";
import * as i3 from "../../elements/spinner/spinner.component";
import * as i4 from "../../elements/menu/menu.component.ngfactory";
import * as i5 from "../../elements/menu/menu.component";
import * as i6 from "@angular/common";
import * as i7 from "./licenses-menu.component";
import * as i8 from "@ngxs/store";
var styles_LicensesMenuComponent = [i0.styles];
var RenderType_LicensesMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LicensesMenuComponent, data: {} });
export { RenderType_LicensesMenuComponent as RenderType_LicensesMenuComponent };
function View_LicensesMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner", [["class", "menu-loading"]], [[2, "small", null]], null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).fitToSize; _ck(_v, 0, 0, currVal_0); }); }
function View_LicensesMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "menu-empty-space"]], null, null, null, null, null))], null, null); }
export function View_LicensesMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu", [["class", "menu-content"]], null, null, null, i4.View_MenuComponent_0, i4.RenderType_MenuComponent)), i1.ɵdid(1, 638976, null, 0, i5.MenuComponent, [], { groups: [0, "groups"], items: [1, "items"], showGroupsNames: [2, "showGroupsNames"], showEmptyGroups: [3, "showEmptyGroups"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LicensesMenuComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["emptySpace", 2]], null, 0, null, View_LicensesMenuComponent_2)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "menu-version"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Dataedo sp. z o.o."])), (_l()(), i1.ɵted(8, null, [", ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mainMenuGroups; var currVal_1 = _co.mainMenuItems; var currVal_2 = true; var currVal_3 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.isLoading; var currVal_5 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.version; _ck(_v, 8, 0, currVal_6); }); }
export function View_LicensesMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-licenses-menu", [], null, null, null, View_LicensesMenuComponent_0, RenderType_LicensesMenuComponent)), i1.ɵdid(1, 245760, null, 0, i7.LicensesMenuComponent, [i8.Store, i8.Actions], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LicensesMenuComponentNgFactory = i1.ɵccf("app-licenses-menu", i7.LicensesMenuComponent, View_LicensesMenuComponent_Host_0, {}, {}, []);
export { LicensesMenuComponentNgFactory as LicensesMenuComponentNgFactory };
