import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import CountriesResponse from 'src/app/api/responses/countries-response';

@Injectable({
    providedIn: 'root'
})
export class CountriesRepositoryService {

    constructor(private http: HttpClient) {
    }

    public list(): Observable<CountriesResponse> {
        return this.http.get<CountriesResponse>(`${environment.apiUrl}v1/countries`);
    }
}
