import {
    ChangeDetectorRef,
    Component,
    ElementRef, EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit, Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnChanges, OnDestroy {

    /**
     * Element for which we will display the modal.
     */
    @Input()
    public reference: HTMLElement;

    /**
     * Preferred tooltip popover width.
     */
    @Input()
    public width: 'auto' | string = 'auto';

    /**
     * If clicking outside the modal close a modal.
     */
    @Input()
    public cancellable = true;

    @Input()
    public showHeader = true;

    @Input()
    public showFooter = true;

    @ViewChild('modal', {static: false})
    public modal: ElementRef;

    @Output()
    public opened = new EventEmitter();

    /**
     * Control whether tooltip is visible or not.
     */
    public visible = false;

    private toggleHandler = () => this.toggle();
    private toggleKeyboardHandler = (event: KeyboardEvent) => this.onKey(event);

    constructor(private cdr: ChangeDetectorRef) {
    }

    public ngOnInit(): void {
        if (this.reference) {
            this.bindNativeEvents(this.reference);
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.reference) {
            if (changes.reference.previousValue) {
                this.unbindNativeEvents(changes.reference.previousValue);
            }

            if (changes.reference.currentValue) {
                this.bindNativeEvents(changes.reference.currentValue);
            }
        }
    }

    private bindNativeEvents(reference: HTMLElement) {
        reference.addEventListener('click', this.toggleHandler);
        reference.addEventListener('keydown', this.toggleKeyboardHandler);
    }

    private unbindNativeEvents(reference: HTMLElement) {
        reference.removeEventListener('click', this.toggleHandler);
        reference.removeEventListener('keydown', this.toggleKeyboardHandler);
    }

    @HostListener('document:click', ['$event'])
    public onDocumentClick(event: MouseEvent) {
        if (!this.visible || !this.cancellable) {
            // Element niewidoczny, ignoruj wywołanie.
            return;
        }

        this.cdr.detectChanges();
        let node = event.target as HTMLElement;
        while (node) {
            if (node === this.reference || node === this.modal.nativeElement) {
                return;
            }

            node = node.parentNode as HTMLElement;
        }

        // Neither reference nor container was clicked.
        this.hide();
    }

    public onKey(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.toggle();
        }
    }

    public toggle() {
        if (this.visible) {
            this.hide();
        } else {
            this.show();
        }
    }

    public show() {
        this.visible = true;
        this.opened.emit();
    }

    public hide() {
        this.visible = false;
    }

    public ngOnDestroy(): void {
        if (this.reference) {
            this.unbindNativeEvents(this.reference);
        }
    }

}
