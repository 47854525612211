import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'keys',
})
export class KeysPipe implements PipeTransform {

    public transform(value: unknown): Array<string|number> {
        if (typeof value !== 'object') {
            return [];
        }

        return Object.keys(value);
    }

}
