import {Action, Actions, Selector, State, StateContext} from '@ngxs/store';
import {
    AuthTokenRenewal,
    ConfirmLoginRequest,
    ConfirmOAuth2, FetchProfile,
    IntendedUrl,
    LoginRequest,
    LoginWithGoogle,
    LoginWithMicrosoft,
    Logout,
    SessionRegisterRequest,
} from './auth.actions';
import {SessionsRepositoryService} from '../api/repositories/sessions-repository.service';
import {takeUntil, tap} from 'rxjs/operators';
import IntercomHelper from '../helpers/intercom-helper';
import {Oauth2RepositoryService} from 'src/app/api/repositories/oauth2-repository.service';
import ProfileResponse from '../api/responses/profile-response';

interface AuthStateModel {
    email: string;
    profile: ProfileResponse | null,
    token: string | null;
    expiresAt: number | null;

    /**
     * Adres pod który użytkownik początkowo wszedł,
     * ale został przekierowany do strony logowania.
     */
    intendedUrl: string | null;
}

@State<AuthStateModel>({
    name: 'Auth',
    defaults: {
        email: '',
        profile: null,
        token: null,
        expiresAt: null,
        intendedUrl: null,
    },
})
export class AuthState {
    constructor(
        private actions: Actions,
        private oauth2Repository: Oauth2RepositoryService,
        private sessionsRepository: SessionsRepositoryService,
    ) {
    }

    @Selector()
    public static email(state: AuthStateModel): string {
        return state.email;
    }

    @Selector()
    public static profile(state: AuthStateModel): ProfileResponse|null {
        return state.profile;
    }

    @Selector()
    public static intendedUrl(state: AuthStateModel): string | null {
        return state.intendedUrl;
    }

    @Selector()
    public static token(state: AuthStateModel): string {
        return state.token;
    }

    @Selector()
    public static isAuthenticated(state: AuthStateModel): boolean {
        // Nie sprawdzamy czy token jest ważny (pole expiresAt),
        // ponieważ jeśli nie jest ważny to API zwróci 401 i nastąpi
        // automatyczne i pełne wylogowanie za pomocą interceptora HTTP.

        // Pole expiresAt może być wykorzystane do poinformowania użytkownika
        // o tym, że jego sesja zaraz wygaśnie jeśli nie wykona żadnej akcji.

        return !!state.token;
    }

    @Action(LoginRequest)
    public loginRequest(ctx: StateContext<AuthStateModel>, action: LoginRequest) {
        let request = this.sessionsRepository.create(action.email)
            .pipe(tap(_ => ctx.patchState({email: action.email})));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(LoginWithGoogle)
    public loginWithGoogle(ctx: StateContext<AuthStateModel>, action: LoginWithGoogle) {
        let request = this.oauth2Repository.loginWithGoogle();

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(LoginWithMicrosoft)
    public loginWithMicrosoft(ctx: StateContext<AuthStateModel>, action: LoginWithMicrosoft) {
        let request = this.oauth2Repository.loginWithMicrosoft();

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(ConfirmLoginRequest)
    public confirmLoginRequest(ctx: StateContext<AuthStateModel>, action: ConfirmLoginRequest) {
        let request = this.sessionsRepository
            .confirm(action.email, action.code, action.remember)
            .pipe(tap(response => {
                ctx.patchState({
                    email: action.email,
                    token: response.data.token,
                    expiresAt: response.data.expires_at,
                });

                IntercomHelper.update({
                    email: action.email,
                    user_id: response.data.user_id,
                });
            }));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(ConfirmOAuth2)
    public confirmOAuth2(ctx: StateContext<AuthStateModel>, action: ConfirmOAuth2) {
        ctx.patchState({
            email: action.email,
            token: action.token,
            expiresAt: action.expiresAt,
        });

        IntercomHelper.update({
            email: action.email,
            user_id: action.userId,
        });
    }

    @Action(SessionRegisterRequest)
    public registerRequest(ctx: StateContext<AuthStateModel>, action: SessionRegisterRequest) {
        let request = this.sessionsRepository
            .register(action.email, action.code, action.firstName, action.lastName)
            .pipe(tap(response => ctx.patchState({
                email: action.email,
                token: response.data.token,
                expiresAt: response.data.expires_at,
            })));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(FetchProfile)
    public fetchProfile(ctx: StateContext<AuthStateModel>, action: FetchProfile) {
        let request = this.sessionsRepository
            .profile()
            .pipe(tap(response => ctx.patchState({profile: response})));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(AuthTokenRenewal)
    public authTokenRenewal(ctx: StateContext<AuthStateModel>, action: AuthTokenRenewal) {
        ctx.patchState({expiresAt: action.expiresAt});
    }

    @Action(IntendedUrl)
    public intendedUrl(ctx: StateContext<AuthStateModel>, action: IntendedUrl) {
        ctx.patchState({intendedUrl: action.url});
    }

    @Action(Logout)
    public logout(ctx: StateContext<AuthStateModel>, action: Logout) {
        IntercomHelper.reboot();
        localStorage.clear();

        ctx.patchState({
            email: '',
            profile: null,
            token: null,
            expiresAt: null,
            intendedUrl: ctx.getState().intendedUrl,
        });
    }
}
