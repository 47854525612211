import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ActionCompletion, Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { PushMenuActiveAccountId } from 'src/app/states/menu.actions';
import { Subject, timer } from 'rxjs';
import { AccountRolesState } from 'src/app/states/account-roles.state';
import { FetchAccountRoles } from 'src/app/states/account-roles.actions';
import ResponseHandlingHelper from 'src/app/helpers/response-handling-helper';
import { AuthState } from 'src/app/states/auth.state';
import { HttpErrorCode } from 'src/app/api/http-error-code.enum';
export class AccountRolesPageComponent {
    constructor(router, route, actions, store, title) {
        this.router = router;
        this.route = route;
        this.actions = actions;
        this.store = store;
        this.title = title;
        this.roles = null;
        this.isLoading = true;
        /**
         * Token służący do anulowania pobierania danych.
         */
        this.cancellationToken = new Subject();
        this.gc = new Subject();
    }
    ngOnInit() {
        this.title.setTitle('Administration - Dataedo Account');
        this.initEvents();
    }
    initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));
        this.userEmailListener
            .pipe(takeUntil(this.gc))
            .subscribe(email => this.userEmail = email);
        this.rolesListener
            .pipe(takeUntil(this.gc))
            .subscribe(roles => this.roles = roles);
        this.actions
            .pipe(ofActionCompleted(FetchAccountRoles))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedDataReload(event));
    }
    onPathChange(params) {
        this.accountId = parseInt(params.accountId, 10);
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));
        this.scheduleFetchData();
    }
    scheduleFetchData() {
        this.cancellationToken.next();
        timer(300)
            .pipe(takeUntil(this.cancellationToken))
            .subscribe(() => {
            this.isLoading = true;
            this.store.dispatch(new FetchAccountRoles(this.accountId, this.cancellationToken));
        });
    }
    onCompletedDataReload(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = ResponseHandlingHelper.parse(event.result);
            if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
                yield this.router.navigate(['accounts', this.accountId]);
            }
            this.isLoading = false;
        });
    }
    trackById(index, role) {
        return role.id;
    }
    ngOnDestroy() {
        this.cancellationToken.next();
        this.cancellationToken.complete();
        this.gc.next();
        this.gc.complete();
    }
}
tslib_1.__decorate([
    Select(AccountRolesState.roles)
], AccountRolesPageComponent.prototype, "rolesListener", void 0);
tslib_1.__decorate([
    Select(AuthState.email)
], AccountRolesPageComponent.prototype, "userEmailListener", void 0);
