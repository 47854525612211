import {Action, Actions, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {takeUntil, tap} from 'rxjs/operators';
import {FetchTrials} from './trials.actions';
import {FetchOrder, FetchOrders} from './orders.actions';
import {OrdersRepositoryService} from '../api/repositories/orders-repository.service';
import {OrdersModelResponse} from '../api/responses/orders-response';
import {OrderModelResponse} from '../api/responses/order-response';
import {Logout} from './auth.actions';

interface OrdersStateModel {
    order: OrderModelResponse|null;
    orders: OrdersModelResponse[];
}

@State<OrdersStateModel>({
    name: 'Orders',
    defaults: {
        order: null,
        orders: [],
    }
})
export class OrdersState {
    constructor(
        private actions: Actions,
        private ordersRepository: OrdersRepositoryService
    ) {
    }

    @Selector()
    public static order(state: OrdersStateModel): OrderModelResponse|null {
        return state.order;
    }

    @Selector()
    public static orders(state: OrdersStateModel): OrdersModelResponse[] {
        return state.orders;
    }

    @Action(FetchOrders)
    public fetchOrders(ctx: StateContext<OrdersStateModel>, action: FetchOrders) {
        let request = this.ordersRepository.listByAccountId(action.accountId)
            .pipe(tap(response => ctx.patchState({
                orders: response.data.items
            })));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(FetchOrder)
    public fetchOrder(ctx: StateContext<OrdersStateModel>, action: FetchOrder) {
        let request = this.ordersRepository.findByAccountIdAndOrderNo(action.accountId, action.orderNo)
            .pipe(tap(response => ctx.patchState({
                order: response.data
            })));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(Logout)
    public logout(ctx: StateContext<OrdersStateModel>, action: Logout) {
        ctx.patchState({
            order: null,
            orders: [],
        });
    }
}
