import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ActionCompletion, Actions, ofActionCompleted, Select, Store} from '@ngxs/store';
import {TrialsState} from '../../states/trials.state';
import {Observable, Subject} from 'rxjs';
import {TrialsModelResponse} from '../../api/responses/trials-response';
import {Title} from '@angular/platform-browser';
import {PushMenuActiveAccountId} from '../../states/menu.actions';
import {FetchTrials} from '../../states/trials.actions';
import {takeUntil} from 'rxjs/operators';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import {FetchSessions, RevokeSession} from '../../states/sessions.actions';
import {SessionsModelResponse} from '../../api/responses/sessions-response';
import {SessionsState} from '../../states/sessions.state';
import {PushAlert} from '../../states/alerts.actions';

@Component({
    selector: 'app-sessions-page',
    templateUrl: './sessions-page.component.html',
    styleUrls: ['./sessions-page.component.scss'],
})
export class SessionsPageComponent implements OnInit, OnDestroy {

    @Select(SessionsState.sessions)
    public sessionsListener: Observable<SessionsModelResponse[]>;

    public sessions: SessionsModelResponse[] = [];

    /**
     * Określa czy aktualnie trwa pobieranie danych.
     */
    public isLoading = false;

    public revokePending: { [sessionId: number]: boolean } = {};

    private gc = new Subject();

    /**
     * Token służący do anulowania pobierania danych.
     */
    private cancellationToken = new Subject();

    constructor(
        private store: Store,
        private actions: Actions,
        private title: Title,
    ) {
    }

    public ngOnInit() {
        this.title.setTitle('Sessions - Dataedo Account');
        this.initEvents();
        this.fetchData();
    }

    private fetchData() {
        this.isLoading = true;

        this.store.dispatch(new PushMenuActiveAccountId(undefined));
        this.store.dispatch(new FetchSessions(this.cancellationToken));
    }

    private initEvents() {
        this.sessionsListener
            .pipe(takeUntil(this.gc))
            .subscribe(sessions => this.sessions = sessions);

        this.actions
            .pipe(ofActionCompleted(RevokeSession))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onRevokeSessionCompleted(event));

        this.actions
            .pipe(ofActionCompleted(FetchSessions))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onFetchSessionsCompleted(event));
    }

    private onFetchSessionsCompleted(event: ActionCompletion<FetchSessions, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);

        this.isLoading = false;
    }

    private onRevokeSessionCompleted(event: ActionCompletion<RevokeSession, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);
        if (event.result.error) {
            this.store.dispatch(new PushAlert('Failed to revoke session.'));
        }

        this.revokePending[event.action.sessionId] = false;
    }

    public trackSessionById(index: number, session: SessionsModelResponse): number {
        return session.id;
    }

    public revoke(session: SessionsModelResponse): void {
        this.revokePending[session.id] = true;
        this.store.dispatch(new RevokeSession(session.id));
    }

    public ngOnDestroy(): void {
        this.cancellationToken.next();
        this.cancellationToken.complete();

        this.gc.next();
        this.gc.complete();
    }
}
