<input #model
       type="checkbox"
       [attr.disabled]="disabled ? 'disabled' : null"
       [value]="1"
       (change)="onChange(model.checked)"
       (keydown)="onChange(model.checked)"
       (keyup)="onChange(model.checked)">
<div class="checkbox">
    <app-icon *ngIf="value" class="checkmark" size="12px" icon="checkmark"></app-icon>
</div>
