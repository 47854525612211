import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {FetchProfile} from '../../states/auth.actions';
import {SessionsRepositoryService} from '../../api/repositories/sessions-repository.service';
import {finalize, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-logged-in-page',
    templateUrl: './logged-in-page.component.html',
    styleUrls: ['./logged-in-page.component.scss'],
})
export class LoggedInPageComponent implements OnInit, OnDestroy {

    public loading: boolean = true;

    private componentDestroyed = new Subject();

    constructor(private sessionsRepository: SessionsRepositoryService) {
    }

    public ngOnInit() {
        this.sessionsRepository.profile()
            .pipe(finalize(() => this.loading = false))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe({
                next: () => null, // ignore response
                error: () => null, // ignore errors
            });
    }

    public ngOnDestroy(): void {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

}
