import {Component, ElementRef, HostBinding, Input, OnChanges, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-icon',
    template: '',
    styles: [':host { font-size: 0; }']
})
export class IconComponent implements OnInit, OnChanges {

    /**
     * A file name (without extension) from the directory:
     * webui/src/assets/icons/{theme}/{icon}.svg
     *
     * Use 'blank' icon to display empty space instead of icon.
     */
    @Input()
    public icon: string;

    @Input()
    @HostBinding('style.width')
    @HostBinding('style.height')
    public size = '22px';

    @Input()
    @HostBinding('style.display')
    public display = 'inline-block';

    @Input()
    @HostBinding('style.verticalAlign')
    public verticalAlign = 'middle';

    @Input()
    public theme: 'simple-small'|'color' = 'simple-small';

    constructor(
        private el: ElementRef,
        private http: HttpClient
    ) {
    }

    public ngOnInit() {
        this.loadIcon();
    }

    public ngOnChanges() {
        this.loadIcon();
    }

    private loadIcon() {
        if (!this.icon) {
            return console.warn('The icon attribute of the icon component is required, usage: <app-icon icon="..."></app-icon>.');
        }

        const url = `assets/icons/${this.theme}/${this.icon}.svg`;

        this.http.get(url, {responseType: 'text'}).subscribe(svg => {
            // Icon must have set fill="currentColor" and/or stroke="currentColor"
            // (depending on the icon structure and shapes).
            this.el.nativeElement.innerHTML = svg;
        });
    }
}

