import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ofActionCompleted, Select } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PushMenuActiveAccountId } from 'src/app/states/menu.actions';
import ResponseHandlingHelper from 'src/app/helpers/response-handling-helper';
import { AccountsState } from 'src/app/states/accounts.state';
import { DownloadOfflineLicense, FetchAccountSubscription } from 'src/app/states/accounts.actions';
import { HttpErrorCode } from 'src/app/api/http-error-code.enum';
export class SubscriptionPageComponent {
    constructor(store, actions, router, route, title, accountRegenerateTokenService) {
        this.store = store;
        this.actions = actions;
        this.router = router;
        this.route = route;
        this.title = title;
        this.accountRegenerateTokenService = accountRegenerateTokenService;
        this.subscription = null;
        this.gc = new Subject();
        /**
         * Określa czy aktualnie trwa pobieranie danych.
         */
        this.isLoading = false;
        this.isCreatingToken = false;
        this.isDownloadingLicense = false;
        /**
         * Token służący do anulowania pobierania danych.
         */
        this.cancellationToken = new Subject();
    }
    ngOnInit() {
        this.title.setTitle('Subscription - Dataedo Account');
        this.initEvents();
    }
    initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));
        this.subscriptionListener
            .pipe(takeUntil(this.gc))
            .subscribe(subscription => this.onSubscriptionChanged(subscription));
        this.actions
            .pipe(ofActionCompleted(FetchAccountSubscription))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetch(event));
        this.actions
            .pipe(ofActionCompleted(DownloadOfflineLicense))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedDownloadOfflineLicense(event));
    }
    onSubscriptionChanged(subscription) {
        if (subscription) {
            this.subscriptionToken = subscription.subscriptionToken;
        }
        return this.subscription = subscription;
    }
    onPathChange(params) {
        this.accountId = parseInt(params.accountId, 10);
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));
        this.fetchData();
    }
    fetchData() {
        this.isLoading = true;
        this.cancellationToken.next();
        this.store.dispatch(new FetchAccountSubscription(this.accountId, this.cancellationToken));
    }
    onCompletedFetch(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = ResponseHandlingHelper.parse(event.result);
            if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
                yield this.router.navigate(['/accounts', this.accountId]);
            }
            this.isLoading = false;
        });
    }
    onCompletedDownloadOfflineLicense(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isDownloadingLicense = false;
        });
    }
    loadPackageDetailsFor(packageDetailsModal, packageCode) {
        this.showDetailsForPackageId = packageCode;
        packageDetailsModal.show();
    }
    downloadOfflineLicense() {
        this.isDownloadingLicense = true;
        this.store.dispatch(new DownloadOfflineLicense(this.accountId, this.gc));
    }
    regenerateToken() {
        if (confirm('Are you sure you want to regenerate subscription token?')) {
            this.isCreatingToken = true;
            this.accountRegenerateTokenService.regenerateToken(this.accountId).subscribe((response) => {
                this.subscriptionToken = response.data.subscriptionToken;
                this.isCreatingToken = false;
            });
        }
    }
    copyMessage(object, text) {
        navigator.clipboard.writeText(text).then(r => alert(`${object} copied to clipboard`));
    }
    hideToken(token) {
        if (token.length < 10) {
            return token;
        }
        const start = token.slice(0, 3);
        const end = token.slice(-3);
        let stars = '';
        for (let i = 0; i < token.length - 6; i++) {
            stars += '*';
        }
        return start + stars + end;
    }
    ngOnDestroy() {
        this.cancellationToken.next();
        this.cancellationToken.complete();
        this.gc.next();
        this.gc.complete();
    }
}
tslib_1.__decorate([
    Select(AccountsState.subscription)
], SubscriptionPageComponent.prototype, "subscriptionListener", void 0);
