<app-stack-layout>
    <h1>Orders & Invoices</h1>

    <app-empty class="fullsize mt-lg" *ngIf="!isLoading && orders.length === 0">
        <app-stack-layout class="text-center">
            <h2>No results found</h2>
            <p class="mt-sm">We didn't find any order in your history.</p>
        </app-stack-layout>
    </app-empty>
    <div class="mt-lg" *ngIf="orders.length > 0 && orders[0].account_id === accountId">
        <table>
            <tr>
                <th>#</th>
                <th>Order no</th>
                <th>
                    <app-stack-layout class="header" direction="row">
                        <span>Date</span>
                        <app-icon class="ml-xs" icon="down-arrow" size="14px"></app-icon>
                    </app-stack-layout>
                </th>
                <th>Payment Status</th>
                <th>Subscr. Status</th>
                <th>Customer</th>
                <th>Product</th>
                <th>Period</th>
                <th class="text-right">Amount</th>
                <th>Invoice</th>
                <th></th>
                <th class="table-fill-column"></th>
            </tr>
            <tr *ngFor="let order of orders; let i = index; trackBy: trackById">
                <td>{{ i + 1 }}</td>
                <td>
                    <a [routerLink]="['/accounts', accountId, 'orders', order.order_no.replace('/', '-')]">
                        {{ order.order_no }}
                    </a>
                </td>
                <td>
                    <app-timestamp [timestamp]="order.ordered_at"></app-timestamp>
                </td>
                <td class="text-no-wrap">
                    <app-badge [color]="order.status.color">{{ order.status.text }}</app-badge>
                </td>
                <td class="text-no-wrap">
                    <app-badge color="success" *ngIf="order.subscriptionActive">Active</app-badge>
                    <app-badge color="lightgray" *ngIf="!order.subscriptionActive">Inactive</app-badge>
                </td>
                <td class="text-no-wrap">
                    {{ order.billing_address?.company }}
                </td>
                <td class="text-no-wrap">
                    <div *ngFor="let line of order.lines">
                        <span *ngIf="line.quantity">{{ line.quantity }} x </span>{{ line.name }}
                    </div>
                </td>
                <td class="text-no-wrap">
                    <span *ngIf="order.subscriptionStart">
                        <app-timestamp [timestamp]="order.subscriptionStart"></app-timestamp>
                    </span>
                    <span *ngIf="order.subscriptionEnd">
                        - <app-timestamp [timestamp]="order.subscriptionEnd"></app-timestamp>
                    </span>
                </td>
                <td class="text-no-wrap text-right">{{ order.amount | moneyFormat:order.currency }}</td>
                <td>
                    <app-document [name]="order.invoice_no"
                                  [fileUrl]="order._invoice_file_url">
                    </app-document>
                </td>
                <td>
                    <div class="text-no-wrap on-tr-hover">
                        <app-button-link class="mr-xs" size="small" [route]="['/accounts', accountId, 'orders', order.order_no.replace('/', '-')]">
                            More
                        </app-button-link>
                    </div>
                </td>
                <td class="table-fill-column"></td>
            </tr>
        </table>
    </div>
    <app-spinner class="fullsize" *ngIf="isLoading"></app-spinner>
</app-stack-layout>
