<!-- TODO: Co w przypadku gdy nie ma żadnych pozycji? -->
<app-menu class="menu-content" [showGroupsNames]="true" [showEmptyGroups]="true" [items]="mainMenuItems" [groups]="mainMenuGroups"></app-menu>

<app-spinner class="menu-loading" *ngIf="isLoading; else emptySpace"></app-spinner>
<ng-template #emptySpace><div class="menu-empty-space"></div></ng-template>

<div class="menu-version">
    <b>Dataedo sp. z o.o.</b>, {{ version }}
</div>

