import {Action, Actions, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {takeUntil, tap} from 'rxjs/operators';
import {TrialsRepositoryService} from '../api/repositories/trials-repository.service';
import {FetchTrials} from './trials.actions';
import {FetchEducationalLicenses} from './educational-licenses.actions';
import {EducationalLicensesRepositoryService} from '../api/repositories/educational-licenses-repository.service';
import {EducationalLicensesModelResponse} from '../api/responses/educational-licenses-response';
import {Logout} from './auth.actions';

interface EducationalLicensesStateModel {
    licenses: EducationalLicensesModelResponse[];
}

@State<EducationalLicensesStateModel>({
    name: 'EducationalLicenses',
    defaults: {
        licenses: [],
    }
})
export class EducationalLicensesState {
    constructor(
        private actions: Actions,
        private educationalLicensesRepository: EducationalLicensesRepositoryService
    ) {
    }

    @Selector()
    public static licenses(state: EducationalLicensesStateModel): EducationalLicensesModelResponse[] {
        return state.licenses;
    }

    @Action(FetchEducationalLicenses)
    public fetchLicenses(ctx: StateContext<EducationalLicensesStateModel>, action: FetchEducationalLicenses) {
        let request = this.educationalLicensesRepository.list()
            .pipe(tap(response => ctx.patchState({
                licenses: response.data.items
            })));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(Logout)
    public logout(ctx: StateContext<EducationalLicensesStateModel>, action: Logout) {
        ctx.patchState({
            licenses: [],
        });
    }
}
