<app-stack-layout>
    <h1>Billing & Payment</h1>
    <p class="mt-xs text-small text-secondary">This list is available only for account owners and users with billing role.</p>

    <ng-container *ngIf="billing && billing.accountId === accountId">
        <div class="mt-xl">
            <div>
                <div class="billing-address">
                    <div class="text-no-wrap">
                        <b>{{ billing?.billingDetails?.companyName || 'Your company name' }}</b>
                    </div>
                    <div class="text-no-wrap">
                        VAT ID: {{ billing?.billingDetails?.vatID || '-' }}
                    </div>
                    <div class="text-no-wrap" *ngFor="let address of (billing?.billingDetails?.address || [])">
                        {{ address }}
                    </div>
                    <div class="text-no-wrap" *ngIf="billing?.billingDetails?.city || billing?.billingDetails?.state || billing?.billingDetails?.postalCode">
                        <span *ngIf="billing?.billingDetails?.city">{{ billing?.billingDetails?.city }}, </span>
                        <span *ngIf="billing?.billingDetails?.state">{{ billing?.billingDetails?.state }}, </span>
                        {{ billing?.billingDetails?.postalCode || '-' }}
                    </div>
                    <div class="text-no-wrap" *ngIf="billing?.billingDetails?.country">
                        {{ billing?.billingDetails?.country }}
                    </div>
                </div>
            </div>

            <div class="mt-xs" *ngIf="billing._canUpdateBilling">
                <app-button-link [route]="['/accounts', accountId, 'billing', 'edit']" icon="edit" size="small">Edit</app-button-link>
            </div>
        </div>

        <div class="mt-xl">
            <table>
                <tr>
                    <th>Billing currency:</th>
                    <td class="text-no-wrap">{{ billing.currency || '-' }}</td>
                    <td class="table-fill-column"></td>
                </tr>
                <tr>
                    <th>Payment method:</th>
                    <td class="text-no-wrap">{{ (billing?.paymentMethod || '-')|titlecase }}</td>
                    <td class="table-fill-column"></td>
                </tr>
            </table>
        </div>
    </ng-container>
    <app-spinner class="fullsize" *ngIf="isLoadingBilling"></app-spinner>
</app-stack-layout>
