import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { AlertsState } from '../../states/alerts.state';
import { Subject, timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { PopAlert } from '../../states/alerts.actions';
export class AlertsComponent {
    constructor(store) {
        this.store = store;
        /**
         * Aktualnie wyswietlany alert.
         */
        this.alert = null;
        /**
         * Czy powiadomienie jest parzyste.
         *
         * Zmieniane przy pojawieniu się nowego powiadomienia
         * w celu animowania przejścia między powiadomieniami.
         */
        this.evenAlert = false;
        this.cancellationToken = new Subject();
        this.gc = new Subject();
    }
    ngOnInit() {
        this.topAlert
            .pipe(takeUntil(this.gc))
            .pipe(tap(_ => this.cancellationToken.next()))
            .subscribe(alert => alert && this.onAlert(alert));
    }
    onAlert(alert) {
        this.alert = alert;
        this.evenAlert = !this.evenAlert;
        timer(alert.options.duration)
            .pipe(takeUntil(this.cancellationToken))
            .subscribe(_ => this.hideCurrent());
    }
    hideCurrent() {
        this.alert = null;
        this.store.dispatch(new PopAlert());
    }
    closeCurrent() {
        if (this.alert && this.alert.options.cancellable) {
            this.cancellationToken.next();
            this.hideCurrent();
        }
    }
    ngOnDestroy() {
        this.gc.next();
        this.gc.complete();
    }
}
tslib_1.__decorate([
    Select(AlertsState.topAlert)
], AlertsComponent.prototype, "topAlert", void 0);
