import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { takeUntil, tap } from 'rxjs/operators';
import { FetchCountries } from 'src/app/states/countries.actions';
let CountriesState = class CountriesState {
    constructor(actions, countriesRepository) {
        this.actions = actions;
        this.countriesRepository = countriesRepository;
    }
    static glossary(state) {
        return state.glossary;
    }
    fetchCountries(ctx, action) {
        let request = this.countriesRepository.list()
            .pipe(tap(response => ctx.patchState({
            glossary: response.data
        })));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
};
tslib_1.__decorate([
    Action(FetchCountries)
], CountriesState.prototype, "fetchCountries", null);
tslib_1.__decorate([
    Selector()
], CountriesState, "glossary", null);
CountriesState = tslib_1.__decorate([
    State({
        name: 'Countries',
        defaults: {
            glossary: [],
        }
    })
], CountriesState);
export { CountriesState };
