<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
    <app-stack-layout>
        <h2>Enter access code</h2>
        <p class="mt-sm contains-email">We've sent one time access code to your email address: <b>{{ form.value.email }}</b><p>

        <app-label class="mt-md">Access code</app-label>
        <app-input textAlign="center" formControlName="code"></app-input>
        <div *ngIf="formErrors.code">
            <app-error>{{ formErrors.code[0] }}</app-error>
        </div>

        <app-stack-layout direction="row" class="mt-xs">
            <label #remember appTextAlign>
                <app-checkbox class="mr-xs" formControlName="remember"></app-checkbox>
                <span class="text-small">Keep me signed in</span>
            </label>

            <app-tooltip [reference]="remember">
                <div class="remember-tooltip">
                    <p>Your session token will be preserved with a very long expiration time.</p>
                    <p class="text-small mt-sm"><b>This function is unsafe on a public computers</b>, use this option only on devices that only you have access to.</p>
                </div>
            </app-tooltip>
        </app-stack-layout>

        <app-button [loading]="isLoading" theme="primary" class="mt-xs">Sign in</app-button>

        <small class="mt-sm">
            <a routerLink="/">&larr; Change email address</a>
        </small>

        <small class="mt-lg">
            <a routerLink="/login/troubleshooting" target="_blank">I didn't receive email.</a>
        </small>
    </app-stack-layout>
</form>
