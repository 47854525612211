/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-bottom-space.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./page-bottom-space.component";
var styles_PageBottomSpaceComponent = [i0.styles];
var RenderType_PageBottomSpaceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageBottomSpaceComponent, data: {} });
export { RenderType_PageBottomSpaceComponent as RenderType_PageBottomSpaceComponent };
export function View_PageBottomSpaceComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_PageBottomSpaceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-bottom-space", [], null, null, null, View_PageBottomSpaceComponent_0, RenderType_PageBottomSpaceComponent)), i1.ɵdid(1, 49152, null, 0, i2.PageBottomSpaceComponent, [], null, null)], null, null); }
var PageBottomSpaceComponentNgFactory = i1.ɵccf("app-page-bottom-space", i2.PageBottomSpaceComponent, View_PageBottomSpaceComponent_Host_0, {}, {}, []);
export { PageBottomSpaceComponentNgFactory as PageBottomSpaceComponentNgFactory };
