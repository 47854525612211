import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { takeUntil, tap } from 'rxjs/operators';
import { FetchOrder, FetchOrders } from './orders.actions';
import { Logout } from './auth.actions';
let OrdersState = class OrdersState {
    constructor(actions, ordersRepository) {
        this.actions = actions;
        this.ordersRepository = ordersRepository;
    }
    static order(state) {
        return state.order;
    }
    static orders(state) {
        return state.orders;
    }
    fetchOrders(ctx, action) {
        let request = this.ordersRepository.listByAccountId(action.accountId)
            .pipe(tap(response => ctx.patchState({
            orders: response.data.items
        })));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    fetchOrder(ctx, action) {
        let request = this.ordersRepository.findByAccountIdAndOrderNo(action.accountId, action.orderNo)
            .pipe(tap(response => ctx.patchState({
            order: response.data
        })));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    logout(ctx, action) {
        ctx.patchState({
            order: null,
            orders: [],
        });
    }
};
tslib_1.__decorate([
    Action(FetchOrders)
], OrdersState.prototype, "fetchOrders", null);
tslib_1.__decorate([
    Action(FetchOrder)
], OrdersState.prototype, "fetchOrder", null);
tslib_1.__decorate([
    Action(Logout)
], OrdersState.prototype, "logout", null);
tslib_1.__decorate([
    Selector()
], OrdersState, "order", null);
tslib_1.__decorate([
    Selector()
], OrdersState, "orders", null);
OrdersState = tslib_1.__decorate([
    State({
        name: 'Orders',
        defaults: {
            order: null,
            orders: [],
        }
    })
], OrdersState);
export { OrdersState };
