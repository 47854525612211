import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import PerpetualLicensesResponse from '../responses/perpetual-licenses-response';

@Injectable({
    providedIn: 'root'
})
export class PerpetualLicensesRepositoryService {
    constructor(private http: HttpClient) {
    }

    public listByAccountId(accountId: number): Observable<PerpetualLicensesResponse> {
        return this.http.get<PerpetualLicensesResponse>(`${environment.apiUrl}v1/accounts/${accountId}/perpetual-licenses`);
    }
}
