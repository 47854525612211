import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class Oauth2RepositoryService {
    constructor(http) {
        this.http = http;
    }
    loginWithGoogle() {
        return this.http.post(`${environment.apiUrl}v1/login/google`, null)
            .pipe(tap(response => window.location.href = response.data.redirectUrl));
    }
    loginWithMicrosoft() {
        return this.http.post(`${environment.apiUrl}v1/login/microsoft`, null)
            .pipe(tap(response => window.location.href = response.data.redirectUrl));
    }
}
Oauth2RepositoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Oauth2RepositoryService_Factory() { return new Oauth2RepositoryService(i0.ɵɵinject(i1.HttpClient)); }, token: Oauth2RepositoryService, providedIn: "root" });
