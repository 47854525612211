import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GuestPageComponent} from './pages/guest-page/guest-page.component';
import {LoginEmailPageComponent} from './pages/login-email-page/login-email-page.component';
import {LoginConfirmPageComponent} from './pages/login-confirm-page/login-confirm-page.component';
import {NotFoundPageComponent} from './pages/not-found-page/not-found-page.component';
import {LogoutPageComponent} from './pages/logout-page/logout-page.component';
import {LoginTroubleshootingComponent} from './pages/login-troubleshooting/login-troubleshooting.component';
import {LoginRegisterPageComponent} from './pages/login-register-page/login-register-page.component';
import {TrialsPageComponent} from './pages/trials-page/trials-page.component';
import {AuthGuard} from './api/guards/auth.guard';
import {EducationalLicensesPageComponent} from './pages/educational-licenses-page/educational-licenses-page.component';
import {PerpetualLicensesPageComponent} from './pages/perpetual-licenses-page/perpetual-licenses-page.component';
import {OrdersPageComponent} from './pages/orders-page/orders-page.component';
import {QuotesPageComponent} from './pages/quotes-page/quotes-page.component';
import {OrderPageComponent} from './pages/order-page/order-page.component';
import {QuotePageComponent} from './pages/quote-page/quote-page.component';
import {AccountsPageComponent} from 'src/app/pages/accounts-page/accounts-page.component';
import {AccountMenuPageComponent} from 'src/app/pages/account-menu-page/account-menu-page.component';
import {AccountRolesPageComponent} from 'src/app/pages/account-roles-page/account-roles-page.component';
import {SubscriptionUsersPageComponent} from 'src/app/pages/subscription-users-page/subscription-users-page.component';
import {SubscriptionPageComponent} from 'src/app/pages/subscription-page/subscription-page.component';
import {AccountBillingPageComponent} from 'src/app/pages/account-billing-page/account-billing-page.component';
import {AccountBillingEditPageComponent} from 'src/app/pages/account-billing-edit-page/account-billing-edit-page.component';
import {AccountRolesEditPageComponent} from 'src/app/pages/account-roles-edit-page/account-roles-edit-page.component';
import {SingleSignOnPageComponent} from './pages/single-sign-on-page/single-sign-on-page.component';
import {AccountHomePageComponent} from './pages/account-home-page/account-home-page.component';
import {SessionsPageComponent} from './pages/sessions-page/sessions-page.component';
import {SubscriptionUsersEditPageComponent} from './pages/subscription-users-edit-page/subscription-users-edit-page.component';
import {ConvertLegacyLicensePageComponent} from './pages/convert-legacy-license-page/convert-legacy-license-page.component';
import {AppComponent} from './app.component';
import {LoggedInPageComponent} from './pages/logged-in-page/logged-in-page.component';


const routes: Routes = [
    {
        path: '', component: GuestPageComponent, data: {showWelcome: true}, children: [
            {path: '', component: LoginEmailPageComponent},
            {path: 'register', component: LoginRegisterPageComponent},
            {path: 'login', component: LoginConfirmPageComponent},
            {path: 'login/troubleshooting', component: LoginTroubleshootingComponent},
            {path: 'logout', component: LogoutPageComponent},
        ],
    },
    {
        path: '', component: GuestPageComponent, data: {showWelcome: false}, children: [
            {path: 'sso', component: SingleSignOnPageComponent, canActivate: [AuthGuard]},
            {path: 'convert-legacy-key', component: ConvertLegacyLicensePageComponent},
        ],
    },
    {path: 'dashboard', redirectTo: 'accounts', canActivate: [AuthGuard]},
    {path: '', component: LoggedInPageComponent, children: [
        {
            path: '', component: AccountMenuPageComponent, canActivate: [AuthGuard], children: [
                {path: 'sessions', component: SessionsPageComponent, canActivate: [AuthGuard]},
                {path: 'accounts', component: AccountsPageComponent, canActivate: [AuthGuard]},
                {path: 'trials', component: TrialsPageComponent, canActivate: [AuthGuard]},
                {path: 'educational-licenses', component: EducationalLicensesPageComponent, canActivate: [AuthGuard]},
                {path: 'accounts/:accountId', component: AccountHomePageComponent, canActivate: [AuthGuard]},
                {path: 'accounts/:accountId/billing', component: AccountBillingPageComponent, canActivate: [AuthGuard]},
                {
                    path: 'accounts/:accountId/billing/edit',
                    component: AccountBillingEditPageComponent,
                    canActivate: [AuthGuard],
                },
                {path: 'accounts/:accountId/subscription', component: SubscriptionPageComponent, canActivate: [AuthGuard]},
                {path: 'accounts/:accountId/roles', component: AccountRolesPageComponent, canActivate: [AuthGuard]},
                {
                    path: 'accounts/:accountId/roles/edit',
                    component: AccountRolesEditPageComponent,
                    canActivate: [AuthGuard],
                },
                {path: 'accounts/:accountId/users', component: SubscriptionUsersPageComponent, canActivate: [AuthGuard]},
                {path: 'accounts/:accountId/users/edit', component: SubscriptionUsersEditPageComponent, canActivate: [AuthGuard]},
                {path: 'accounts/:accountId/quotes', component: QuotesPageComponent, canActivate: [AuthGuard]},
                {path: 'accounts/:accountId/quotes/:quoteNo', component: QuotePageComponent, canActivate: [AuthGuard]},
                {path: 'accounts/:accountId/orders', component: OrdersPageComponent, canActivate: [AuthGuard]},
                {path: 'accounts/:accountId/orders/:orderNo', component: OrderPageComponent, canActivate: [AuthGuard]},
                {
                    path: 'accounts/:accountId/perpetual-licenses',
                    component: PerpetualLicensesPageComponent,
                    canActivate: [AuthGuard],
                },
            ],
        },
    ]},
    {path: '**', component: NotFoundPageComponent},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
            paramsInheritanceStrategy: 'always',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
