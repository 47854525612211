import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {AuthState} from '../../states/auth.state';
import {ActionCompletion, Actions, ofActionCompleted, ofActionSuccessful, Store} from '@ngxs/store';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmLoginRequest} from '../../states/auth.actions';
import {PushAlert} from '../../states/alerts.actions';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import {takeUntil} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';

interface FormData {
    email: string;
    code: string;
    remember: boolean;
}

@Component({
    selector: 'app-login-confirm-page',
    templateUrl: './login-confirm-page.component.html',
    styleUrls: ['./login-confirm-page.component.scss']
})
export class LoginConfirmPageComponent implements OnInit, OnDestroy {

    /**
     * Określa czy aktualnie trwa próba zalogowania.
     */
    public isLoading = false;

    public form: FormGroup;
    public formErrors: { [key: string]: string[] } = {};

    /**
     * Token służący do anulowania aktualnej operacji logowania.
     */
    private cancellationToken = new Subject();

    private gc = new Subject();

    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private actions: Actions,
        private router: Router,
        private title: Title,
        private formBuilder: FormBuilder
    ) {
    }

    public ngOnInit() {
        this.title.setTitle('Login - Dataedo Account');
        this.initForm();
        this.initEvents();

        if (!this.form.value.email) {
            // Osoby wchodzace na ta strone musza trafic tutaj z podstrony na ktorej podali
            // adres e-mail lub z maila ktory w query params przesyla adres email.
            return this.missingEmailAddress();
        }

        if (this.form.value.code) {
            // Jesli uzytkownik trafi na strone klikajac w mailu "Sign in now"
            // to formularz zawiera juz kod i powinien rozpoczac jego wysylanie
            // aby nastapilo automatyczne zalogowanie po wejsciu w link.
            this.onSubmit(this.form.value);
        }
    }

    private initEvents() {
        this.actions.pipe(ofActionSuccessful(ConfirmLoginRequest))
            .pipe(takeUntil(this.gc))
            .subscribe(action => this.onSuccessfulConfirmation(action));

        this.actions.pipe(ofActionCompleted(ConfirmLoginRequest))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedConfirmation(event));
    }

    private initForm() {
        this.form = this.formBuilder.group({
            email: this.route.snapshot.queryParams.email || this.store.selectSnapshot(AuthState.email),
            code: this.route.snapshot.queryParams.code || '',
            remember: false,
        } as FormData);
    }

    private missingEmailAddress() {
        // Wyświetlenie komunikatu, że wymagane jest podanie adresu email.
        const message = 'Please provide your email address to continue.';
        this.store.dispatch(new PushAlert(message));

        // Przekierowanie do pierwszej strony logowania (podanie maila).
        return this.router.navigate(['/'], {
            replaceUrl: true,
        });
    }

    public onSubmit(formData: FormData) {
        this.isLoading = true;
        this.formErrors = {};

        this.store.dispatch(new ConfirmLoginRequest(formData.email, formData.code, formData.remember, this.cancellationToken));
    }

    private onSuccessfulConfirmation(action: ConfirmLoginRequest) {
        // Wyświetlenie komunikatu o poprawnym logowaniu.
        const message = 'You have been logged in successfully.';
        this.store.dispatch(new PushAlert(message, {type: 'success'}));

        // Przekierowanie do strony do wpisania kodu uwierzytelniającego.
        return this.router.navigate(['/'], {
            replaceUrl: true,
        });
    }

    private onCompletedConfirmation(event: ActionCompletion<ConfirmLoginRequest, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);

        if (response.isError) {
            // Wyświetlenie błędów formularza.
            this.formErrors = response.error.form;
        }

        this.isLoading = false;
    }

    @HostListener('document:keydown.escape', ['$event'])
    public onEscape(event: KeyboardEvent) {
        // Cancel login action on escape button.
        this.cancellationToken.next();
    }

    public ngOnDestroy(): void {
        this.gc.next();
        this.gc.complete();
    }
}
