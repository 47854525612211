import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import BaseFormComponent from '../BaseFormComponent';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: forwardRef(() => InputComponent),
    }]
})
export class InputComponent extends BaseFormComponent {

    @Input()
    public type: 'text' | 'password' = 'text';

    @Input()
    public name: string = null;

    @Input()
    public placeholder: string = null;

    @Input()
    public textAlign: 'left'|'center'|'right' = 'left';

    @Input()
    public size: 'small'|'normal' = 'normal';

    @Input()
    public previewMode: 'yes'|'no'|'auto' = 'no';

    @Input()
    public disabled = false;

}
