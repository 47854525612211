import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { takeUntil, tap } from 'rxjs/operators';
import { Logout } from './auth.actions';
import { DownloadOfflineLicense, FetchAccountBilling, FetchAccountSubscription, FetchAccountSummary, UpdateBillingDetails, } from 'src/app/states/accounts.actions';
import ResponseHandlingHelper from '../helpers/response-handling-helper';
let AccountsState = class AccountsState {
    constructor(actions, accountsRepositoryService) {
        this.actions = actions;
        this.accountsRepositoryService = accountsRepositoryService;
    }
    static summary(state) {
        return state.summary;
    }
    static subscription(state) {
        return state.subscription;
    }
    static billing(state) {
        return state.billing;
    }
    fetchAccountSummary(ctx, action) {
        let request = this.accountsRepositoryService.summaryByAccountId(action.accountId)
            .pipe(tap(response => ctx.patchState({
            summary: response.data
        })));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    fetchAccountSubscription(ctx, action) {
        let request = this.accountsRepositoryService.subscriptionByAccountId(action.accountId)
            .pipe(tap(response => ctx.patchState({
            subscription: response.data
        })));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    downloadOfflineLicense(ctx, action) {
        let request = this.accountsRepositoryService.offlineLicense(action.accountId)
            .pipe(tap(response => {
            ResponseHandlingHelper.downloadFile(response, 'license_file.dkey');
        }));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    fetchAccountBilling(ctx, action) {
        let request = this.accountsRepositoryService.billingByAccountId(action.accountId)
            .pipe(tap(response => ctx.patchState({
            billing: response.data
        })));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    updateBillingDetails(ctx, action) {
        let request = this.accountsRepositoryService.updateBillingByAccountId(action.accountId, action.formData);
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    logout(ctx, action) {
        ctx.patchState({
            subscription: null,
        });
    }
};
tslib_1.__decorate([
    Action(FetchAccountSummary)
], AccountsState.prototype, "fetchAccountSummary", null);
tslib_1.__decorate([
    Action(FetchAccountSubscription)
], AccountsState.prototype, "fetchAccountSubscription", null);
tslib_1.__decorate([
    Action(DownloadOfflineLicense)
], AccountsState.prototype, "downloadOfflineLicense", null);
tslib_1.__decorate([
    Action(FetchAccountBilling)
], AccountsState.prototype, "fetchAccountBilling", null);
tslib_1.__decorate([
    Action(UpdateBillingDetails)
], AccountsState.prototype, "updateBillingDetails", null);
tslib_1.__decorate([
    Action(Logout)
], AccountsState.prototype, "logout", null);
tslib_1.__decorate([
    Selector()
], AccountsState, "summary", null);
tslib_1.__decorate([
    Selector()
], AccountsState, "subscription", null);
tslib_1.__decorate([
    Selector()
], AccountsState, "billing", null);
AccountsState = tslib_1.__decorate([
    State({
        name: 'Accounts',
        defaults: {
            summary: null,
            subscription: null,
            billing: null,
        }
    })
], AccountsState);
export { AccountsState };
