import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import OrdersResponse from '../responses/orders-response';
import OrderResponse from '../responses/order-response';

@Injectable({
    providedIn: 'root'
})
export class OrdersRepositoryService {
    constructor(private http: HttpClient) {
    }

    public listByAccountId(accountId: number): Observable<OrdersResponse> {
        return this.http.get<OrdersResponse>(`${environment.apiUrl}v1/accounts/${accountId}/orders`);
    }

    public findByAccountIdAndOrderNo(accountId: number, orderNo: string): Observable<OrderResponse> {
        const orderNoNorm = orderNo.replace('/', '-');

        return this.http.get<OrderResponse>(`${environment.apiUrl}v1/accounts/${accountId}/orders/${orderNoNorm}`);
    }
}
