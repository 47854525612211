import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import TrialsResponse from '../responses/trials-response';

@Injectable({
    providedIn: 'root'
})
export class TrialsRepositoryService {
    constructor(private http: HttpClient) {
    }

    public list(): Observable<TrialsResponse> {
        return this.http.get<TrialsResponse>(`${environment.apiUrl}v1/trials`);
    }

    public offlineLicense(trialId: number): Observable<string> {
        return this.http.get(`${environment.apiUrl}v1/trials/${trialId}/offline-license`, {responseType: 'text'});
    }
}
