import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ofActionCompleted, Select } from '@ngxs/store';
import { DownloadOfflineTrialLicense, FetchTrials } from '../../states/trials.actions';
import { takeUntil } from 'rxjs/operators';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import { TrialsState } from '../../states/trials.state';
import { environment } from '../../../environments/environment';
import { PushMenuActiveAccountId } from 'src/app/states/menu.actions';
export class TrialsPageComponent {
    constructor(store, actions, title) {
        this.store = store;
        this.actions = actions;
        this.title = title;
        this.freeTrialUrl = `${environment.websiteUrl}free-trial`;
        this.changePackageUrl = `${environment.websiteUrl}upgrade-trial`;
        this.trials = [];
        this.isDownloadingTrialId = null;
        /**
         * Określa czy aktualnie trwa pobieranie danych.
         */
        this.isLoading = false;
        this.gc = new Subject();
        /**
         * Token służący do anulowania pobierania danych.
         */
        this.cancellationToken = new Subject();
    }
    ngOnInit() {
        this.title.setTitle('Trials - Dataedo Account');
        this.initEvents();
        this.fetchData();
    }
    fetchData() {
        this.isLoading = true;
        this.store.dispatch(new PushMenuActiveAccountId(null));
        this.store.dispatch(new FetchTrials(this.cancellationToken));
    }
    initEvents() {
        this.trialsListener
            .pipe(takeUntil(this.gc))
            .subscribe(trials => this.trials = trials);
        this.actions
            .pipe(ofActionCompleted(FetchTrials))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchTrials(event));
        this.actions
            .pipe(ofActionCompleted(DownloadOfflineTrialLicense))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedDownloadOfflineTrialLicense(event));
    }
    onCompletedFetchTrials(event) {
        const response = ResponseHandlingHelper.parse(event.result);
        this.isLoading = false;
    }
    trackTrialById(index, trial) {
        return trial.id;
    }
    downloadOfflineLicense(trial) {
        this.isDownloadingTrialId = trial.id;
        this.store.dispatch(new DownloadOfflineTrialLicense(trial.id, this.gc));
    }
    onCompletedDownloadOfflineTrialLicense(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isDownloadingTrialId = null;
        });
    }
    ngOnDestroy() {
        this.cancellationToken.next();
        this.cancellationToken.complete();
        this.gc.next();
        this.gc.complete();
    }
}
tslib_1.__decorate([
    Select(TrialsState.trials)
], TrialsPageComponent.prototype, "trialsListener", void 0);
