<ng-container *ngIf="!fileUrl else downloadable">
    <!-- TODO: Informacja o dostepnosci dokumentu w nastepnym dniu roboczym? -->
    {{ name }}
</ng-container>

<ng-template #downloadable>
    <a class="text-no-wrap" #downloadLink appTextAlign="oneline" tabindex="0" (click)="download()" (keydown.enter)="download()">
        <span class="icon mr-xs">
            <app-spinner *ngIf="isLoading; else icon"></app-spinner>
            <ng-template #icon>
                <app-icon size="22px" icon="document"></app-icon>
            </ng-template>
        </span>

        <span>{{ name }}</span>
    </a>

    <app-tooltip [reference]="downloadLink" placement="bottom">Download pdf</app-tooltip>
</ng-template>
