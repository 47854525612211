<app-stack-layout>
    <h1>Account</h1>

    <ng-container *ngIf="summary && summary.accountId === accountId">
        <div class="overflow-x mt-md">
            <table>
                <tr>
                    <td class="text-no-wrap text-secondary">Account ID:</td>
                    <td>{{ summary.accountId }}</td>
                    <td class="table-fill-column"></td>
                </tr>
                <tr>
                    <td class="text-no-wrap text-secondary">Account name:</td>
                    <td class="text-no-wrap text-bold">{{ summary.accountName }}</td>
                    <td class="table-fill-column"></td>
                </tr>
                <tr>
                    <td class="text-no-wrap text-secondary">Your Access:</td>
                    <td class="text-no-wrap">{{ summary.yourAccess.displayName }}</td>
                    <td class="table-fill-column"></td>
                </tr>
                <tr>
                    <td class="text-no-wrap text-secondary">Your License:</td>
                    <td class="text-no-wrap">
                        <ng-container *ngFor="let license of summary.yourLicenses; let i = index">
                            <span *ngIf="i > 0">, </span>
                            <span>{{ license.displayName }}</span>
                        </ng-container>
                    </td>
                    <td class="table-fill-column"></td>
                </tr>
                <tr *ngIf="summary.modules.length > 0">
                    <td class="text-no-wrap text-secondary text-top">Subscription:</td>
                    <td class="text-no-wrap">
                        <div *ngFor="let module of summary.modules">
                            <ng-container *ngIf="checkIfDateIsEarlier(module.subscriptionStart) && checkIfDateIsLater(module.subscriptionEnd)">
                            <span *ngIf="module.count">{{ module.count }}x</span> {{ module.name }}
                            </ng-container>
                        </div>
                        <div *ngIf="summary._canViewSubscription">
                            <a [routerLink]="['/accounts', summary.accountId, 'subscription']">More</a>
                        </div>
                    </td>
                    <td class="table-fill-column"></td>
                </tr>
            </table>
        </div>
    </ng-container>

    <app-spinner class="fullsize" *ngIf="isLoading"></app-spinner>
</app-stack-layout>
