import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {AuthState} from '../../states/auth.state';
import {Observable, Subject} from 'rxjs';
import ProfileResponse from '../../api/responses/profile-response';
import {FetchProfile} from '../../states/auth.actions';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {

    @Select(AuthState.profile)
    public profile$: Observable<ProfileResponse | null>;

    private componentDestroyed = new Subject();

    constructor(private store: Store) {
    }

    public ngOnInit(): void {
        this.store.dispatch(new FetchProfile(this.componentDestroyed));
    }

    public ngOnDestroy(): void {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }
}
