import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ofActionCompleted, ofActionSuccessful, Select } from '@ngxs/store';
import { SubscriptionsState } from '../../states/subscriptions.state';
import { Subject, timer } from 'rxjs';
import { AuthState } from '../../states/auth.state';
import { takeUntil } from 'rxjs/operators';
import { FetchSubscriptionUsers, SubscriptionUsersAssignLicenses, SubscriptionUsersRevokeLicenses, SubscriptionUsersSetDomains, } from '../../states/subscriptions.actions';
import { PushMenuActiveAccountId } from '../../states/menu.actions';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import { HttpErrorCode } from '../../api/http-error-code.enum';
import { PushAlert } from '../../states/alerts.actions';
export class SubscriptionUsersEditPageComponent {
    constructor(router, route, actions, store, title) {
        this.router = router;
        this.route = route;
        this.actions = actions;
        this.store = store;
        this.title = title;
        this.formErrors = {};
        this.domainsFormErrors = {};
        this.users = null;
        this.isLoading = true;
        /**
         * Określa czy aktualnie trwa dodawanie uzytkownika do okreslonego pakietu.
         * Jesli tak niemozliwe jest dodanie kolejnego poki nie zakonczy sie poprzednie.
         *
         * - no - użytkownik nie jest aktualnie dodawany do roli,
         * - pending - trwa oczekiwanie na odpowiedź od serwera,
         * - awaiting-refresh - użytkownik został dodany, oczekiwanie na odświeżenie danych.
         */
        this.isAssigningUser = {};
        this.isSavingDomains = {};
        /**
         * Okresla czy aktualnie trwa usuwanie uzytkownika dla wybranej roli.
         */
        this.isRevokingUser = {};
        /**
         * Token służący do anulowania odświeżania danych.
         */
        this.cancellationToken = new Subject();
        /**
         * Informuje o zmianie identyfikatora konta.
         *
         * Na sam koniec przed zniszczeniem komponentu wysyłana jest wartość "null".
         */
        this.accountIdChanged = new Subject();
        this.newEmail = {};
        this.domains = {};
        this.gc = new Subject();
    }
    get hasAnyUsers() {
        if (!this.users) {
            // Dane nie zostały jeszcze załadowane.
            return false;
        }
        if (this.users.accountId !== this.accountId) {
            // Dane są już załadowane (np. z cache),
            // ale dotyczą innej organizacji/konta.
            return false;
        }
        for (const packageId of Object.keys(this.users.usersByPackage)) {
            if (this.users.usersByPackage[packageId].licensesCount > 0) {
                return true;
            }
        }
        return false;
    }
    ngOnInit() {
        this.title.setTitle('Subscription users - Dataedo Account');
        this.initEvents();
    }
    initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));
        this.userEmailListener
            .pipe(takeUntil(this.gc))
            .subscribe(email => this.userEmail = email);
        this.usersListener
            .pipe(takeUntil(this.gc))
            .subscribe(users => this.onUsersChanged(users));
        this.actions
            .pipe(ofActionCompleted(FetchSubscriptionUsers))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedDataReload(event));
        this.actions
            .pipe(ofActionSuccessful(FetchSubscriptionUsers))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onSuccessfulDataReload(event));
        this.actions
            .pipe(ofActionCompleted(SubscriptionUsersAssignLicenses))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedLicenseAssign(event));
        this.actions
            .pipe(ofActionCompleted(SubscriptionUsersSetDomains))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedSubscriptionUsersSetDomains(event));
        this.actions
            .pipe(ofActionCompleted(SubscriptionUsersRevokeLicenses))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedLicenseRevoke(event));
    }
    onPathChange(params) {
        const newAccountId = parseInt(params.accountId, 10);
        if (this.accountId !== newAccountId) {
            this.accountIdChanged.next(this.accountId);
            this.isAssigningUser = {};
            this.isRevokingUser = {};
        }
        this.accountId = newAccountId;
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));
        this.scheduleFetchData();
    }
    onUsersChanged(users) {
        if (users) {
            for (const packageId of Object.keys(users.usersByPackage)) {
                this.newEmail[packageId] = this.newEmail[packageId] || '';
                this.domains[packageId] = this.domains[packageId] || users.usersByPackage[packageId].domains;
            }
        }
        this.users = users;
    }
    getLicensesLeft(group) {
        return Math.max(0, group.licensesCount - group.users.length);
    }
    scheduleFetchData() {
        this.cancellationToken.next();
        timer(300)
            .pipe(takeUntil(this.cancellationToken))
            .subscribe(() => {
            this.isLoading = true;
            this.store.dispatch(new FetchSubscriptionUsers(this.accountId, this.cancellationToken));
        });
    }
    onCompletedDataReload(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Obsługa błędów serwera.
            const response = ResponseHandlingHelper.parse(event.result);
            if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
                yield this.router.navigate(['accounts', this.accountId]);
            }
            this.isLoading = false;
        });
    }
    onSuccessfulDataReload(event) {
        // Wyłączenie loaderów dla dodawania nowych użytkowników.
        let anyLicenseAssigned = false;
        for (const packageId of Object.keys(this.isAssigningUser)) {
            if (this.isAssigningUser[packageId] === 'awaiting-refresh') {
                delete (this.isAssigningUser[packageId]);
                this.newEmail[packageId] = '';
                anyLicenseAssigned = true;
            }
        }
        // Wyłączenie loaderów dla usuwania aktualnych użytkowników.
        let anyLicenseRevoked = false;
        for (const packageId of Object.keys(this.isRevokingUser)) {
            const emails = this.isRevokingUser[packageId];
            for (const email of Object.keys(emails)) {
                if (this.isRevokingUser[packageId][email] === 'awaiting-refresh') {
                    delete (this.isRevokingUser[packageId][email]);
                    anyLicenseRevoked = true;
                }
            }
        }
        if (anyLicenseAssigned && anyLicenseRevoked) {
            this.store.dispatch(new PushAlert('Licenses have been successfully updated.', { type: 'success' }));
        }
        else if (anyLicenseAssigned) {
            this.store.dispatch(new PushAlert('Licenses have been successfully assigned.', { type: 'success' }));
        }
        else if (anyLicenseRevoked) {
            this.store.dispatch(new PushAlert('Licenses have been successfully revoked.', { type: 'success' }));
        }
        this.isLoading = false;
    }
    trackByEmail(index, user) {
        return user.email;
    }
    assignUser(email, packageId) {
        const emails = email.split(/[\s,]+/);
        if (this.isAssigningUser[packageId] === 'pending' || this.isAssigningUser[packageId] === 'awaiting-refresh') {
            // Nie można wykonac operacji poniewaz dla tej roli jest juz wykonywana.
            return;
        }
        this.formErrors[packageId] = {};
        this.isAssigningUser[packageId] = 'pending';
        this.store.dispatch(new SubscriptionUsersAssignLicenses(this.accountId, packageId, emails, this.accountIdChanged));
    }
    onCompletedLicenseAssign(event) {
        const response = ResponseHandlingHelper.parse(event.result);
        if (response.isError && response.error.status === HttpErrorCode.Conflict) {
            this.isAssigningUser[event.action.packageId] = 'no';
            this.store.dispatch(new PushAlert(response.error.message, { type: 'warning', overridable: false }));
        }
        if (response.isError && response.error.status === HttpErrorCode.UnprocessableEntity) {
            // Copy all errors from keys: "emails.0", "emails.1", ... to single "email" error.
            for (const inputName of Object.keys(response.error.form)) {
                if (inputName.indexOf('emails.') === 0) {
                    response.error.form.email = response.error.form.email || [];
                    response.error.form.email = [
                        ...response.error.form.email,
                        ...response.error.form[inputName],
                    ];
                }
            }
            this.formErrors[event.action.packageId] = response.error.form;
            this.isAssigningUser[event.action.packageId] = 'no';
        }
        if (this.isAssigningUser[event.action.packageId] === 'pending') {
            this.isAssigningUser[event.action.packageId] = 'awaiting-refresh';
            this.scheduleFetchData();
        }
    }
    revokeUser(modal, email, packageId) {
        modal.hide();
        const emails = email.split(/[\s,]+/);
        if (this.isRevokingUser[packageId] && (this.isRevokingUser[packageId][email] === 'pending' || this.isRevokingUser[packageId][email] === 'awaiting-refresh')) {
            // Nie można wykonac operacji poniewaz dla tej roli i tego adresu jest juz wykonywana.
            return;
        }
        this.isRevokingUser[packageId] = this.isRevokingUser[packageId] || {};
        this.isRevokingUser[packageId][email] = 'pending';
        this.store.dispatch(new SubscriptionUsersRevokeLicenses(this.accountId, packageId, emails, this.accountIdChanged));
    }
    onCompletedLicenseRevoke(event) {
        for (const email of event.action.emails) {
            if (this.isRevokingUser[event.action.packageId]) {
                this.isRevokingUser[event.action.packageId][email] = 'awaiting-refresh';
            }
        }
        this.scheduleFetchData();
    }
    updateDomains(domain, packageId) {
        const domains = domain.split(/[\s,]+/);
        if (this.isSavingDomains[packageId] === 'pending' || this.isSavingDomains[packageId] === 'awaiting-refresh') {
            // Nie można wykonac operacji poniewaz dla tej roli jest juz wykonywana.
            return;
        }
        this.domainsFormErrors[packageId] = {};
        this.isSavingDomains[packageId] = 'pending';
        this.store.dispatch(new SubscriptionUsersSetDomains(this.accountId, packageId, domains, this.accountIdChanged));
    }
    onCompletedSubscriptionUsersSetDomains(event) {
        const response = ResponseHandlingHelper.parse(event.result);
        if (response.isError && response.error.status === HttpErrorCode.UnprocessableEntity) {
            // Copy all errors from keys: "emails.0", "emails.1", ... to single "email" error.
            for (const inputName of Object.keys(response.error.form)) {
                if (inputName.indexOf('emails.') === 0) {
                    response.error.form.domains = response.error.form.domains || [];
                    response.error.form.domains = [
                        ...response.error.form.domains,
                        ...response.error.form[inputName],
                    ];
                }
            }
            this.domainsFormErrors[event.action.packageId] = response.error.form;
            this.isSavingDomains[event.action.packageId] = 'no';
        }
        if (this.isSavingDomains[event.action.packageId] === 'pending') {
            this.store.dispatch(new PushAlert('Domains has been saved.', { type: 'success' }));
            this.isSavingDomains[event.action.packageId] = 'no';
            this.scheduleFetchData();
        }
    }
    ngOnDestroy() {
        this.cancellationToken.next();
        this.cancellationToken.complete();
        this.accountIdChanged.next(null);
        this.accountIdChanged.complete();
        this.gc.next();
        this.gc.complete();
    }
    // remove invalid charachers on keyup (while editing allowed domain)
    removeInvalidCharsFromDomain(input) {
        // .replace(usuniecie znakow specjalnych).replace(usuniecie podwojnej spacji)
        input.value = input.value.replace(/[^a-zA-Z0-9._,-]/g, '').replace('  ', ' ').toLowerCase();
    }
}
tslib_1.__decorate([
    Select(SubscriptionsState.users)
], SubscriptionUsersEditPageComponent.prototype, "usersListener", void 0);
tslib_1.__decorate([
    Select(AuthState.email)
], SubscriptionUsersEditPageComponent.prototype, "userEmailListener", void 0);
