import {ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import BaseFormComponent from 'src/app/elements/BaseFormComponent';
import SelectOption from 'src/app/elements/select/select-option';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: forwardRef(() => SelectComponent),
    }]
})
export class SelectComponent extends BaseFormComponent implements OnInit {

    @Input()
    public options: SelectOption[] = [];

    @Input()
    public name: string = null;

    @Input()
    public hasEmptyValue = false;

    @Input()
    public emptyValueText = '';

    @Input()
    public textAlign: 'left' | 'center' | 'right' = 'left';

    @Input()
    public size: 'small' | 'normal' = 'normal';

    @Input()
    public loading = false;

    @Input()
    public previewMode: 'yes' | 'no' | 'auto' = 'no';

    @Input()
    public disabled = false;

    @ViewChild('model', {static: true})
    public model: ElementRef;

    constructor(private cdr: ChangeDetectorRef) {
        super();
    }

    public ngOnInit(): void {
        this.cdr.detectChanges();

        const select = this.model.nativeElement;
        if (select.selectedIndex >= 0) {
            this.onChange(select.options[select.selectedIndex].value);
        }
    }

}
