import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { PopAlert, PushAlert } from './alerts.actions';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
let AlertsState = class AlertsState {
    static topAlert(state) {
        return state.alerts.length > 0 ? state.alerts[0] : null;
    }
    pushAlert(ctx, action) {
        action.options = Object.assign({ 
            // Domyslna konfiguracja.
            html: false, duration: 800 + (80 * action.message.length), type: 'info', overridable: true, cancellable: true }, action.options);
        const newAlert = {
            message: action.message,
            options: action.options
        };
        // Wyszukiwanie pierwszego powiadomienia w kolejce
        // ktore moze byc nadpisane przez kolejne powiadomienie.
        const alerts = ctx.getState().alerts;
        const overrideableIndex = alerts.findIndex(x => x.options.overridable);
        if (overrideableIndex >= 0) {
            return ctx.setState(patch({
                alerts: updateItem(overrideableIndex, newAlert)
            }));
        }
        // Jesli nie ma w kolejce malo waznych powiadomien
        // to nowe powiadomienie musi zostaje dodane na koniec kolejki.
        return ctx.setState(patch({
            alerts: append([newAlert])
        }));
    }
    popAlert(ctx, action) {
        ctx.setState(patch({
            alerts: removeItem(0)
        }));
    }
};
tslib_1.__decorate([
    Action(PushAlert)
], AlertsState.prototype, "pushAlert", null);
tslib_1.__decorate([
    Action(PopAlert)
], AlertsState.prototype, "popAlert", null);
tslib_1.__decorate([
    Selector()
], AlertsState, "topAlert", null);
AlertsState = tslib_1.__decorate([
    State({
        name: 'Alerts',
        defaults: {
            alerts: [],
        }
    })
], AlertsState);
export { AlertsState };
