import {Action, Actions, Selector, State, StateContext} from '@ngxs/store';
import {takeUntil, tap} from 'rxjs/operators';
import {
    FetchSubscriptionUsers,
    SubscriptionUsersAssignLicenses,
    SubscriptionUsersRevokeLicenses, SubscriptionUsersSetDomains,
} from './subscriptions.actions';
import {SubscriptionsRepositoryService} from '../api/repositories/subscriptions-repository.service';
import {Logout} from './auth.actions';
import {SubscriptionUsersModelResponse} from 'src/app/api/responses/subscription-users-response';
import {SubscriptionUsersRepositoryService} from 'src/app/api/repositories/subscription-users-repository.service';

interface SubscriptionsStateModel {
    users: SubscriptionUsersModelResponse;
}

@State<SubscriptionsStateModel>({
    name: 'Subscriptions',
    defaults: {
        users: null,
    }
})
export class SubscriptionsState {
    constructor(
        private actions: Actions,
        private subscriptionsRepository: SubscriptionsRepositoryService,
        private subscriptionUsersRepository: SubscriptionUsersRepositoryService
    ) {
    }

    @Selector()
    public static users(state: SubscriptionsStateModel): SubscriptionUsersModelResponse {
        return state.users;
    }

    @Action(FetchSubscriptionUsers)
    public fetchSubscriptionUsers(ctx: StateContext<SubscriptionsStateModel>, action: FetchSubscriptionUsers) {
        let request = this.subscriptionUsersRepository.listByAccountId(action.accountId)
            .pipe(tap(response => ctx.patchState({
                users: response.data
            })));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(SubscriptionUsersAssignLicenses)
    public subscriptionUsersAssignLicenses(ctx: StateContext<SubscriptionsStateModel>, action: SubscriptionUsersAssignLicenses) {
        let request = this.subscriptionUsersRepository.assignLicenses(action.accountId, action.packageId, action.emails);

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(SubscriptionUsersRevokeLicenses)
    public subscriptionUsersRevokeLicenses(ctx: StateContext<SubscriptionsStateModel>, action: SubscriptionUsersRevokeLicenses) {
        let request = this.subscriptionUsersRepository.revokeLicenses(action.accountId, action.packageId, action.emails);

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(SubscriptionUsersSetDomains)
    public subscriptionUsersSetDomains(ctx: StateContext<SubscriptionsStateModel>, action: SubscriptionUsersSetDomains) {
        let request = this.subscriptionUsersRepository.setDomains(action.accountId, action.packageId, action.domains);

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(Logout)
    public logout(ctx: StateContext<SubscriptionsStateModel>, action: Logout) {
        ctx.patchState({
            users: null,
        });
    }
}
