import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { takeUntil, tap } from 'rxjs/operators';
import { FetchMenu, PushMenuActiveAccountId } from './menu.actions';
import { Logout } from './auth.actions';
let MenuState = class MenuState {
    constructor(actions, menuRepository) {
        this.actions = actions;
        this.menuRepository = menuRepository;
    }
    static activeAccountId(state) {
        return state.activeAccountId;
    }
    static menu(state) {
        return state.menu;
    }
    pushMenuActiveAccountId(ctx, action) {
        ctx.patchState({
            activeAccountId: action.accountId
        });
    }
    fetchMenu(ctx, action) {
        let request = this.menuRepository.items()
            .pipe(tap(response => ctx.patchState({
            menu: response.data
        })));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    logout(ctx, action) {
        ctx.patchState({
            activeAccountId: null,
            menu: null,
        });
    }
};
tslib_1.__decorate([
    Action(PushMenuActiveAccountId)
], MenuState.prototype, "pushMenuActiveAccountId", null);
tslib_1.__decorate([
    Action(FetchMenu)
], MenuState.prototype, "fetchMenu", null);
tslib_1.__decorate([
    Action(Logout)
], MenuState.prototype, "logout", null);
tslib_1.__decorate([
    Selector()
], MenuState, "activeAccountId", null);
tslib_1.__decorate([
    Selector()
], MenuState, "menu", null);
MenuState = tslib_1.__decorate([
    State({
        name: 'Menu',
        defaults: {
            activeAccountId: null,
            menu: null,
        }
    })
], MenuState);
export { MenuState };
