<app-stack-layout>
    <h2>Your code not arrived?</h2>
    <p class="mt-md">Please follow below instructions:</p>
    <ol class="mt-sm">
        <li><b>Check "spam" folder</b>,</li>
        <li><b>Wait a minute</b>, some network issues may delay sending email,</li>
        <li><a routerLink="/"><b>Generate another code</b></a> and check email address correctness.</li>
    </ol>

    <app-button-link class="mt-md" href="/login">
        I've got a code
    </app-button-link>

    <small class="mt-lg">
        We hope that above steps fixed your issue,
        if for some reason problem still persist then please
        <a [href]="contactHref">contact our support team</a>.
    </small>
</app-stack-layout>
