import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ofActionCompleted, Select } from '@ngxs/store';
import { AccountsState } from 'src/app/states/accounts.state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FetchAccountBilling, UpdateBillingDetails } from 'src/app/states/accounts.actions';
import { PushMenuActiveAccountId } from 'src/app/states/menu.actions';
import ResponseHandlingHelper from 'src/app/helpers/response-handling-helper';
import { HttpErrorCode } from 'src/app/api/http-error-code.enum';
import { CountriesState } from 'src/app/states/countries.state';
import { FetchCountries } from 'src/app/states/countries.actions';
import { PushAlert } from 'src/app/states/alerts.actions';
export class AccountBillingEditPageComponent {
    constructor(store, actions, router, route, formBuilder, title) {
        this.store = store;
        this.actions = actions;
        this.router = router;
        this.route = route;
        this.formBuilder = formBuilder;
        this.title = title;
        this.billing = null;
        this.countries = [];
        this.countriesOptions = [];
        this.formErrors = {};
        this.gc = new Subject();
        /**
         * Określa czy aktualnie trwa pobieranie danych.
         */
        this.isLoadingBilling = false;
        this.isLoadingCountries = false;
        this.isFormProcessing = false;
        /**
         * Token służący do anulowania pobierania danych.
         */
        this.cancellationToken = new Subject();
        this.formCancellationToken = new Subject();
    }
    ngOnInit() {
        this.title.setTitle('Billing & Payment - Dataedo Account');
        this.initForm();
        this.initEvents();
    }
    initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));
        this.billingListener
            .pipe(takeUntil(this.gc))
            .subscribe(billing => {
            this.billing = billing;
            if (billing && billing.billingDetails) {
                this.form.get('company').setValue(billing.billingDetails.companyName);
                this.form.get('address').setValue((billing.billingDetails.address && billing.billingDetails.address[0]) || '');
                this.form.get('address2').setValue((billing.billingDetails.address && billing.billingDetails.address[1]) || '');
                this.form.get('postalCode').setValue(billing.billingDetails.postalCode);
                this.form.get('city').setValue(billing.billingDetails.city);
                this.form.get('state').setValue(billing.billingDetails.state);
                this.form.get('country').setValue(billing.billingDetails.country);
                this.form.get('vatID').setValue(billing.billingDetails.vatID);
            }
        });
        this.countriesListener
            .pipe(takeUntil(this.gc))
            .subscribe(countries => {
            this.countries = countries;
            this.countriesOptions = countries.map(x => ({ value: x.id, text: x.name }));
        });
        this.actions
            .pipe(ofActionCompleted(FetchAccountBilling))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchBilling(event));
        this.actions
            .pipe(ofActionCompleted(FetchCountries))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchCountries(event));
        this.actions
            .pipe(ofActionCompleted(UpdateBillingDetails))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedUpdateBilling(event));
    }
    initForm() {
        this.form = this.formBuilder.group({
            company: '',
            address: '',
            address2: '',
            postalCode: '',
            city: '',
            state: '',
            country: '',
            vatID: '',
        });
    }
    onPathChange(params) {
        this.accountId = parseInt(params.accountId, 10);
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));
        this.fetchData();
    }
    fetchData() {
        this.isLoadingBilling = true;
        this.isLoadingCountries = true;
        this.isFormProcessing = false;
        this.cancellationToken.next();
        this.store.dispatch(new FetchCountries(this.cancellationToken));
        this.store.dispatch(new FetchAccountBilling(this.accountId, this.cancellationToken));
    }
    onCompletedFetchBilling(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = ResponseHandlingHelper.parse(event.result);
            if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
                yield this.router.navigate(['/accounts', this.accountId]);
            }
            this.isLoadingBilling = false;
        });
    }
    onCompletedFetchCountries(event) {
        this.isLoadingCountries = false;
    }
    onSubmit(formData) {
        this.formCancellationToken.next();
        this.isFormProcessing = true;
        this.formErrors = {};
        this.store.dispatch(new UpdateBillingDetails(this.accountId, formData, this.formCancellationToken));
    }
    onCompletedUpdateBilling(event) {
        const response = ResponseHandlingHelper.parse(event.result);
        if (response.isError && response.error.status === HttpErrorCode.UnprocessableEntity) {
            this.formErrors = response.error.form;
        }
        if (response.isSuccessful) {
            this.store.dispatch(new PushAlert('Billing details have been successfully updated.', { type: 'success' }));
            this.router.navigate(['/accounts', this.accountId, 'billing']);
        }
        this.isFormProcessing = false;
    }
    ngOnDestroy() {
        this.cancellationToken.next();
        this.cancellationToken.complete();
        this.gc.next();
        this.gc.complete();
    }
}
tslib_1.__decorate([
    Select(AccountsState.billing)
], AccountBillingEditPageComponent.prototype, "billingListener", void 0);
tslib_1.__decorate([
    Select(CountriesState.glossary)
], AccountBillingEditPageComponent.prototype, "countriesListener", void 0);
