<select #model
        [attr.name]="name"
        [attr.disabled]="disabled || previewMode === 'yes' ? 'disabled' : null"
        [style.textAlign]="textAlign"
        [class.is-loading]="loading"
        [class.small]="size === 'small'"
        [class.only-edit-mode]="previewMode === 'no'"
        [class.only-preview-mode]="previewMode === 'yes'"
        (change)="onChange(model.value)">
    <option *ngIf="hasEmptyValue" [value]="null" [selected]="value === null">{{ emptyValueText }}</option>
    <option *ngFor="let option of options"
            [value]="option.value"
            [selected]="value === option.value">
        {{ option.text }}
    </option>
</select>
<app-spinner class="loader" *ngIf="loading"></app-spinner>
<app-icon class="icon" icon="expand-arrow" size="10px"></app-icon>
