<button class="button"
        [ngClass]="[size, theme]"
        [class.is-loading]="loading"
        [attr.tabindex]="tabindex"
        [class.has-icon]="!!icon"
        [disabled]="disabled || loading">
    <app-spinner class="loader" *ngIf="loading"></app-spinner>
    <app-icon [style.visibility]="loading ? 'hidden' : 'visible'" *ngIf="icon" [icon]="icon" [theme]="iconTheme" [size]="size === 'small' ? '14px' : '18px'"></app-icon>
    <ng-content></ng-content>
</button>
