/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./input.component";
var styles_InputComponent = [i0.styles];
var RenderType_InputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputComponent, data: {} });
export { RenderType_InputComponent as RenderType_InputComponent };
export function View_InputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["model", 1]], null, 0, "input", [], [[8, "type", 0], [1, "name", 0], [1, "placeholder", 0], [1, "disabled", 0], [4, "textAlign", null], [2, "small", null], [2, "only-edit-mode", null], [2, "only-preview-mode", null], [8, "value", 0]], [[null, "change"], [null, "keydown"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange(i1.ɵnov(_v, 0).value) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (_co.onChange(i1.ɵnov(_v, 0).value) !== false);
        ad = (pd_1 && ad);
    } if (("keyup" === en)) {
        var pd_2 = (_co.onChange(i1.ɵnov(_v, 0).value) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; var currVal_1 = _co.name; var currVal_2 = _co.placeholder; var currVal_3 = (_co.disabled ? "disabled" : null); var currVal_4 = _co.textAlign; var currVal_5 = (_co.size === "small"); var currVal_6 = (_co.previewMode === "no"); var currVal_7 = (_co.previewMode === "yes"); var currVal_8 = _co.value; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_InputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-input", [], null, null, null, View_InputComponent_0, RenderType_InputComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.InputComponent]), i1.ɵdid(2, 49152, null, 0, i3.InputComponent, [], null, null)], null, null); }
var InputComponentNgFactory = i1.ɵccf("app-input", i3.InputComponent, View_InputComponent_Host_0, { type: "type", name: "name", placeholder: "placeholder", textAlign: "textAlign", size: "size", previewMode: "previewMode", disabled: "disabled" }, {}, []);
export { InputComponentNgFactory as InputComponentNgFactory };
