import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { AuthState } from '../../states/auth.state';
import { Subject } from 'rxjs';
import { FetchProfile } from '../../states/auth.actions';
export class NavbarComponent {
    constructor(store) {
        this.store = store;
        this.componentDestroyed = new Subject();
    }
    ngOnInit() {
        this.store.dispatch(new FetchProfile(this.componentDestroyed));
    }
    ngOnDestroy() {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }
}
tslib_1.__decorate([
    Select(AuthState.profile)
], NavbarComponent.prototype, "profile$", void 0);
