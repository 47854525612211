import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import MenuResponse from '../responses/menu-response';
import AccountsResponse from '../responses/accounts-response';

@Injectable({
    providedIn: 'root'
})
export class MenuRepositoryService {

    constructor(private http: HttpClient) {
    }

    public items(): Observable<MenuResponse> {
        return this.http.get<MenuResponse>(`${environment.apiUrl}v1/menu`);
    }
}
