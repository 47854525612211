/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./package-modules.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../elements/spinner/spinner.component.ngfactory";
import * as i3 from "../../elements/spinner/spinner.component";
import * as i4 from "@angular/common";
import * as i5 from "../../pipes/keys.pipe";
import * as i6 from "../../elements/modal/modal.component.ngfactory";
import * as i7 from "../../elements/modal/modal.component";
import * as i8 from "../../elements/button/button.component.ngfactory";
import * as i9 from "../../elements/button/button.component";
import * as i10 from "./package-modules.component";
import * as i11 from "../../api/repositories/packages-repository.service";
var styles_PackageModulesComponent = [i0.styles];
var RenderType_PackageModulesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PackageModulesComponent, data: {} });
export { RenderType_PackageModulesComponent as RenderType_PackageModulesComponent };
function View_PackageModulesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["class", "mb-md"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 1, 0, currVal_0); }); }
function View_PackageModulesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner", [["style", "display: flex; min-width: 140px;"]], [[2, "small", null]], null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).fitToSize; _ck(_v, 0, 0, currVal_0); }); }
function View_PackageModulesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "text-small text-secondary"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_PackageModulesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], [[2, "mt-md", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PackageModulesComponent_5)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.modules[_v.context.$implicit]; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (_v.context.index > 0); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }); }
function View_PackageModulesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "pr-md"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PackageModulesComponent_4)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.modules)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PackageModulesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.KeysPipe, []), (_l()(), i1.ɵeld(1, 0, [["toggle", 1]], null, 1, "span", [["class", "toggle-link"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(3, 0, null, null, 12, "app-modal", [], null, [[null, "opened"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("opened" === en)) {
        var pd_1 = (_co.loadPackageDetails() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ModalComponent_0, i6.RenderType_ModalComponent)), i1.ɵdid(4, 770048, [["modal", 4]], 0, i7.ModalComponent, [i1.ChangeDetectorRef], { reference: [0, "reference"], cancellable: [1, "cancellable"], showHeader: [2, "showHeader"], showFooter: [3, "showFooter"] }, { opened: "opened" }), (_l()(), i1.ɵeld(5, 0, null, 1, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PackageModulesComponent_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PackageModulesComponent_2)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PackageModulesComponent_3)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "text-right mt-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "app-button", [["size", "small"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ButtonComponent_0, i8.RenderType_ButtonComponent)), i1.ɵdid(14, 49152, null, 0, i9.ButtonComponent, [], { size: [0, "size"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1); var currVal_1 = true; var currVal_2 = false; var currVal_3 = false; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.header; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.loading; _ck(_v, 9, 0, currVal_5); var currVal_6 = _co.modules; _ck(_v, 11, 0, currVal_6); var currVal_7 = "small"; _ck(_v, 14, 0, currVal_7); }, null); }
export function View_PackageModulesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-package-modules", [], null, null, null, View_PackageModulesComponent_0, RenderType_PackageModulesComponent)), i1.ɵdid(1, 180224, null, 0, i10.PackageModulesComponent, [i11.PackagesRepositoryService], null, null)], null, null); }
var PackageModulesComponentNgFactory = i1.ɵccf("app-package-modules", i10.PackageModulesComponent, View_PackageModulesComponent_Host_0, { packageCode: "packageCode", header: "header" }, {}, ["*"]);
export { PackageModulesComponentNgFactory as PackageModulesComponentNgFactory };
