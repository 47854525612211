import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ofActionCompleted, Select } from '@ngxs/store';
import { Subject } from 'rxjs';
import { PushMenuActiveAccountId } from '../../states/menu.actions';
import { takeUntil } from 'rxjs/operators';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import { FetchSessions, RevokeSession } from '../../states/sessions.actions';
import { SessionsState } from '../../states/sessions.state';
import { PushAlert } from '../../states/alerts.actions';
export class SessionsPageComponent {
    constructor(store, actions, title) {
        this.store = store;
        this.actions = actions;
        this.title = title;
        this.sessions = [];
        /**
         * Określa czy aktualnie trwa pobieranie danych.
         */
        this.isLoading = false;
        this.revokePending = {};
        this.gc = new Subject();
        /**
         * Token służący do anulowania pobierania danych.
         */
        this.cancellationToken = new Subject();
    }
    ngOnInit() {
        this.title.setTitle('Sessions - Dataedo Account');
        this.initEvents();
        this.fetchData();
    }
    fetchData() {
        this.isLoading = true;
        this.store.dispatch(new PushMenuActiveAccountId(undefined));
        this.store.dispatch(new FetchSessions(this.cancellationToken));
    }
    initEvents() {
        this.sessionsListener
            .pipe(takeUntil(this.gc))
            .subscribe(sessions => this.sessions = sessions);
        this.actions
            .pipe(ofActionCompleted(RevokeSession))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onRevokeSessionCompleted(event));
        this.actions
            .pipe(ofActionCompleted(FetchSessions))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onFetchSessionsCompleted(event));
    }
    onFetchSessionsCompleted(event) {
        const response = ResponseHandlingHelper.parse(event.result);
        this.isLoading = false;
    }
    onRevokeSessionCompleted(event) {
        const response = ResponseHandlingHelper.parse(event.result);
        if (event.result.error) {
            this.store.dispatch(new PushAlert('Failed to revoke session.'));
        }
        this.revokePending[event.action.sessionId] = false;
    }
    trackSessionById(index, session) {
        return session.id;
    }
    revoke(session) {
        this.revokePending[session.id] = true;
        this.store.dispatch(new RevokeSession(session.id));
    }
    ngOnDestroy() {
        this.cancellationToken.next();
        this.cancellationToken.complete();
        this.gc.next();
        this.gc.complete();
    }
}
tslib_1.__decorate([
    Select(SessionsState.sessions)
], SessionsPageComponent.prototype, "sessionsListener", void 0);
