import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FetchOrder, FetchOrders} from '../../states/orders.actions';
import {ActionCompletion, Actions, ofActionCompleted, Select, Store} from '@ngxs/store';
import {OrdersState} from '../../states/orders.state';
import {OrdersModelResponse} from '../../api/responses/orders-response';
import {OrderModelResponse} from '../../api/responses/order-response';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import {animate, style, transition, trigger} from '@angular/animations';
import {PushMenuActiveAccountId} from 'src/app/states/menu.actions';
import {HttpErrorCode} from 'src/app/api/http-error-code.enum';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-order-page',
    templateUrl: './order-page.component.html',
    styleUrls: ['./order-page.component.scss'],
})
export class OrderPageComponent implements OnInit, OnDestroy {

    public accountId: number;

    public now = Date.now() / 1000;

    @Select(OrdersState.order)
    public orderListener: Observable<OrderModelResponse | null>;

    public order: OrderModelResponse | null = null;

    public orderNo: string;

    private gc = new Subject();

    /**
     * Określa czy aktualnie trwa pobieranie danych.
     */
    public isLoading = false;

    /**
     * Token służący do anulowania pobierania danych.
     */
    private cancellationToken = new Subject();

    constructor(
        private store: Store,
        private actions: Actions,
        private router: Router,
        private route: ActivatedRoute,
        private title: Title
    ) {
    }

    public ngOnInit() {
        this.title.setTitle('Order - Dataedo Account');
        this.initEvents();
    }

    private initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));

        this.orderListener
            .pipe(takeUntil(this.gc))
            .subscribe(order => this.order = order);

        this.actions
            .pipe(ofActionCompleted(FetchOrder))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchOrder(event));
    }

    private onPathChange(params: Params) {
        this.accountId = parseInt(params.accountId, 10);
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));

        this.orderNo = params.orderNo.replace('-', '/');
        this.fetchData();
    }

    private fetchData() {
        this.isLoading = true;

        this.cancellationToken.next();
        this.store.dispatch(new FetchOrder(this.accountId, this.orderNo, this.cancellationToken));
    }

    private async onCompletedFetchOrder(event: ActionCompletion<FetchOrder, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);
        if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
            await this.router.navigate(['/accounts', this.accountId]);
        }

        this.isLoading = false;
    }

    public ngOnDestroy(): void {
        this.cancellationToken.next();
        this.cancellationToken.complete();

        this.gc.next();
        this.gc.complete();
    }
}
