import { AuthState } from '../../states/auth.state';
import { PushAlert } from '../../states/alerts.actions';
import { IntendedUrl } from '../../states/auth.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "@angular/router";
export class AuthGuard {
    constructor(store, router) {
        this.store = store;
        this.router = router;
        this.message = 'Please sign in to access this resource.';
        this.loginFormUrl = '/';
    }
    canActivate(next, state) {
        const loggedIn = this.store.selectSnapshot(AuthState.isAuthenticated);
        if (loggedIn) {
            return true;
        }
        // Store the attempted URL for redirecting.
        this.store.dispatch(new IntendedUrl(state.url));
        // Navigate to the login page.
        this.store.dispatch(new PushAlert(this.message, { type: 'warning' }));
        this.router.navigate([this.loginFormUrl]);
        return false;
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
