/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./document.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../elements/spinner/spinner.component.ngfactory";
import * as i3 from "../../elements/spinner/spinner.component";
import * as i4 from "../../elements/icon/icon.component.ngfactory";
import * as i5 from "../../elements/icon/icon.component";
import * as i6 from "@angular/common/http";
import * as i7 from "../../directives/text-align.directive";
import * as i8 from "@angular/common";
import * as i9 from "../../elements/tooltip/tooltip.component.ngfactory";
import * as i10 from "../../elements/tooltip/tooltip.component";
import * as i11 from "./document.component";
import * as i12 from "@ngxs/store";
var styles_DocumentComponent = [i0.styles];
var RenderType_DocumentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentComponent, data: {} });
export { RenderType_DocumentComponent as RenderType_DocumentComponent };
function View_DocumentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.name; _ck(_v, 1, 0, currVal_0); }); }
function View_DocumentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner", [], [[2, "small", null]], null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).fitToSize; _ck(_v, 0, 0, currVal_0); }); }
function View_DocumentComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icon", [["icon", "document"], ["size", "22px"]], [[4, "width", null], [4, "height", null], [4, "display", null], [4, "verticalAlign", null]], null, null, i4.View_IconComponent_0, i4.RenderType_IconComponent)), i1.ɵdid(1, 638976, null, 0, i5.IconComponent, [i1.ElementRef, i6.HttpClient], { icon: [0, "icon"], size: [1, "size"] }, null)], function (_ck, _v) { var currVal_4 = "document"; var currVal_5 = "22px"; _ck(_v, 1, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).size; var currVal_1 = i1.ɵnov(_v, 1).size; var currVal_2 = i1.ɵnov(_v, 1).display; var currVal_3 = i1.ɵnov(_v, 1).verticalAlign; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_DocumentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["downloadLink", 1]], null, 7, "a", [["appTextAlign", "oneline"], ["class", "text-no-wrap"], ["tabindex", "0"]], null, [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.download() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 81920, null, 0, i7.TextAlignDirective, [i1.ElementRef], { textAlign: [0, "textAlign"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["class", "icon mr-xs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentComponent_3)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["icon", 2]], null, 0, null, View_DocumentComponent_4)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "app-tooltip", [["placement", "bottom"]], null, null, null, i9.View_TooltipComponent_0, i9.RenderType_TooltipComponent)), i1.ɵdid(9, 245760, null, 0, i10.TooltipComponent, [], { placement: [0, "placement"], reference: [1, "reference"] }, null), (_l()(), i1.ɵted(-1, 0, ["Download pdf"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "oneline"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isLoading; var currVal_2 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_4 = "bottom"; var currVal_5 = i1.ɵnov(_v, 0); _ck(_v, 9, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.name; _ck(_v, 7, 0, currVal_3); }); }
export function View_DocumentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["downloadable", 2]], null, 0, null, View_DocumentComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.fileUrl; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_DocumentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-document", [], null, null, null, View_DocumentComponent_0, RenderType_DocumentComponent)), i1.ɵdid(1, 180224, null, 0, i11.DocumentComponent, [i12.Store, i6.HttpClient], null, null)], null, null); }
var DocumentComponentNgFactory = i1.ɵccf("app-document", i11.DocumentComponent, View_DocumentComponent_Host_0, { name: "name", fileUrl: "fileUrl" }, {}, []);
export { DocumentComponentNgFactory as DocumentComponentNgFactory };
