<a #reference
   tabindex="0"
   class="license-key"
   (keydown.enter)="$event.target.click()"
   [ngxClipboard] [cbContent]="licenseKey"
   (cbOnSuccess)="licenseKeyCopied($event)">
    <code>{{ licenseKey.substr(0, 4) }}...{{ licenseKey.substr(-4) }}</code>
    <app-icon class="ml-sm" icon="copy-to-clipboard" size="16px"></app-icon>
</a>

<app-tooltip placement="bottom" [reference]="reference">
    Copy to clipboard
</app-tooltip>
