/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./checkbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../icon/icon.component.ngfactory";
import * as i3 from "../icon/icon.component";
import * as i4 from "@angular/common/http";
import * as i5 from "@angular/common";
import * as i6 from "@angular/forms";
import * as i7 from "./checkbox.component";
var styles_CheckboxComponent = [i0.styles];
var RenderType_CheckboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckboxComponent, data: {} });
export { RenderType_CheckboxComponent as RenderType_CheckboxComponent };
function View_CheckboxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icon", [["class", "checkmark"], ["icon", "checkmark"], ["size", "12px"]], [[4, "width", null], [4, "height", null], [4, "display", null], [4, "verticalAlign", null]], null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(1, 638976, null, 0, i3.IconComponent, [i1.ElementRef, i4.HttpClient], { icon: [0, "icon"], size: [1, "size"] }, null)], function (_ck, _v) { var currVal_4 = "checkmark"; var currVal_5 = "12px"; _ck(_v, 1, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).size; var currVal_1 = i1.ɵnov(_v, 1).size; var currVal_2 = i1.ɵnov(_v, 1).display; var currVal_3 = i1.ɵnov(_v, 1).verticalAlign; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_CheckboxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["model", 1]], null, 0, "input", [["type", "checkbox"]], [[1, "disabled", 0], [8, "value", 0]], [[null, "change"], [null, "keydown"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange(i1.ɵnov(_v, 0).checked) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (_co.onChange(i1.ɵnov(_v, 0).checked) !== false);
        ad = (pd_1 && ad);
    } if (("keyup" === en)) {
        var pd_2 = (_co.onChange(i1.ɵnov(_v, 0).checked) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckboxComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.value; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.disabled ? "disabled" : null); var currVal_1 = 1; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_CheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-checkbox", [], null, null, null, View_CheckboxComponent_0, RenderType_CheckboxComponent)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.CheckboxComponent]), i1.ɵdid(2, 49152, null, 0, i7.CheckboxComponent, [], null, null)], null, null); }
var CheckboxComponentNgFactory = i1.ɵccf("app-checkbox", i7.CheckboxComponent, View_CheckboxComponent_Host_0, { disabled: "disabled" }, {}, []);
export { CheckboxComponentNgFactory as CheckboxComponentNgFactory };
