import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActionCompletion, Actions, ofActionCompleted, Select, Store} from '@ngxs/store';
import {Observable, Subject} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {PushMenuActiveAccountId} from 'src/app/states/menu.actions';
import ResponseHandlingHelper from 'src/app/helpers/response-handling-helper';
import {AccountsState} from 'src/app/states/accounts.state';
import {AccountSubscriptionModelResponse} from 'src/app/api/responses/account-subscription.response';
import {DownloadOfflineLicense, FetchAccountSubscription} from 'src/app/states/accounts.actions';
import {HttpErrorCode} from 'src/app/api/http-error-code.enum';
import {Title} from '@angular/platform-browser';
import {ModalComponent} from '../../elements/modal/modal.component';
import {AccountRegenerateTokenService} from '../../api/repositories/account-regenerate-token.service';

@Component({
    selector: 'app-subscription-page',
    templateUrl: './subscription-page.component.html',
    styleUrls: ['./subscription-page.component.scss']
})
export class SubscriptionPageComponent implements OnInit, OnDestroy {

    public accountId: number;
    public subscriptionToken: string;

    @Select(AccountsState.subscription)
    public subscriptionListener: Observable<AccountSubscriptionModelResponse | null>;
    public subscription: AccountSubscriptionModelResponse | null = null;

    public showDetailsForPackageId?: string;

    private gc = new Subject();

    /**
     * Określa czy aktualnie trwa pobieranie danych.
     */
    public isLoading = false;
    public isCreatingToken = false;

    public isDownloadingLicense = false;

    /**
     * Token służący do anulowania pobierania danych.
     */
    private cancellationToken = new Subject();

    constructor(
        private store: Store,
        private actions: Actions,
        private router: Router,
        private route: ActivatedRoute,
        private title: Title,
        private accountRegenerateTokenService: AccountRegenerateTokenService
    ) {
    }

    public ngOnInit() {
        this.title.setTitle('Subscription - Dataedo Account');
        this.initEvents();
    }

    private initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));

        this.subscriptionListener
            .pipe(takeUntil(this.gc))
            .subscribe(subscription => this.onSubscriptionChanged(subscription));

        this.actions
            .pipe(ofActionCompleted(FetchAccountSubscription))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetch(event));

        this.actions
            .pipe(ofActionCompleted(DownloadOfflineLicense))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedDownloadOfflineLicense(event));
    }

    private onSubscriptionChanged(subscription: AccountSubscriptionModelResponse) {
        if (subscription) {
            this.subscriptionToken = subscription.subscriptionToken;
        }

        return this.subscription = subscription;
    }

    private onPathChange(params: Params) {
        this.accountId = parseInt(params.accountId, 10);
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));

        this.fetchData();
    }

    private fetchData() {
        this.isLoading = true;

        this.cancellationToken.next();
        this.store.dispatch(new FetchAccountSubscription(this.accountId, this.cancellationToken));
    }

    private async onCompletedFetch(event: ActionCompletion<FetchAccountSubscription, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);
        if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
            await this.router.navigate(['/accounts', this.accountId]);
        }

        this.isLoading = false;
    }

    private async onCompletedDownloadOfflineLicense(event: ActionCompletion<DownloadOfflineLicense, Error>) {
        this.isDownloadingLicense = false;
    }

    public loadPackageDetailsFor(packageDetailsModal: ModalComponent, packageCode: string): void {
        this.showDetailsForPackageId = packageCode;
        packageDetailsModal.show();
    }

    public downloadOfflineLicense() {
        this.isDownloadingLicense = true;
        this.store.dispatch(new DownloadOfflineLicense(this.accountId, this.gc));
    }

    public regenerateToken() {
        if (confirm('Are you sure you want to regenerate subscription token?')) {
            this.isCreatingToken = true;
            this.accountRegenerateTokenService.regenerateToken(this.accountId).subscribe((response: any) => {
                this.subscriptionToken = response.data.subscriptionToken;
                this.isCreatingToken = false;
            });
        }
    }

    public copyMessage(object: string, text: string) {
        navigator.clipboard.writeText(text).then(r =>
            alert(`${object} copied to clipboard`)
        );
    }

    public hideToken(token: string) {
        if (token.length < 10) {
            return token;
        }

        const start = token.slice(0, 3);
        const end = token.slice(-3);
        let stars = '';
        for (let i = 0; i < token.length - 6; i++) {
            stars += '*';
        }
        return start + stars + end;
    }

    public ngOnDestroy(): void {
        this.cancellationToken.next();
        this.cancellationToken.complete();

        this.gc.next();
        this.gc.complete();
    }
}
