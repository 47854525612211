import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-stack-layout',
    templateUrl: './stack-layout.component.html',
    styleUrls: ['./stack-layout.component.scss']
})
export class StackLayoutComponent {

    @Input()
    @HostBinding('style.flexDirection')
    public direction: 'column' | 'row' = 'column';

}
