import {Action, Actions, Selector, State, StateContext} from '@ngxs/store';
import {takeUntil, tap} from 'rxjs/operators';
import {MenuRepositoryService} from '../api/repositories/menu-repository.service';
import {FetchMenu, PushMenuActiveAccountId} from './menu.actions';
import {Logout} from './auth.actions';
import {MenuModelResponse} from 'src/app/api/responses/menu-response';

interface MenuStateModel {
    activeAccountId: number | null;
    menu: MenuModelResponse;
}

@State<MenuStateModel>({
    name: 'Menu',
    defaults: {
        activeAccountId: null,
        menu: null,
    }
})
export class MenuState {
    constructor(
        private actions: Actions,
        private menuRepository: MenuRepositoryService
    ) {
    }

    @Selector()
    public static activeAccountId(state: MenuStateModel): number | null {
        return state.activeAccountId;
    }

    @Selector()
    public static menu(state: MenuStateModel): MenuModelResponse {
        return state.menu;
    }

    @Action(PushMenuActiveAccountId)
    public pushMenuActiveAccountId(ctx: StateContext<MenuStateModel>, action: PushMenuActiveAccountId) {
        ctx.patchState({
            activeAccountId: action.accountId
        });
    }

    @Action(FetchMenu)
    public fetchMenu(ctx: StateContext<MenuStateModel>, action: FetchMenu) {
        let request = this.menuRepository.items()
            .pipe(tap(response => ctx.patchState({
                menu: response.data
            })));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(Logout)
    public logout(ctx: StateContext<MenuStateModel>, action: Logout) {
        ctx.patchState({
            activeAccountId: null,
            menu: null,
        });
    }
}
