<app-stack-layout>
    <h1>Subscription users</h1>
    <p class="mt-xs">
        List of users with assigned subscription license in your organization.
        Users are grouped by license role.
    </p>
    <p class="mt-xs text-small text-secondary">
        This list is available only for account owners and admins.
    </p>

    <app-empty class="fullsize mt-lg" *ngIf="!isLoading && !hasAnyUsers">
        <app-stack-layout class="text-center">
            <h2>No results found</h2>
            <p class="mt-sm">We didn't find any licenses in your account.</p>
        </app-stack-layout>
    </app-empty>
    <div *ngIf="hasAnyUsers">
        <ng-template #usersByPackage let-group let-packageId="packageId">
            <ng-container
                *ngIf="group.licensesCount > 0 || group.users?.length > 0"
            >
                <tr>
                    <td colspan="3">
                        <h2 class="mt-xl text-no-wrap">{{ group.title }}</h2>
                    </td>
                    <td colspan="3" class="text-bottom">
                        <span class="text-no-wrap">
                            <app-badge color="info" class="left-licenses-count">
                                {{ getLicensesLeft(group) || "None" }}
                            </app-badge>
                            out of {{ group.licensesCount }} licenses available
                        </span>
                    </td>
                    <td class="table-fill-column"></td>
                </tr>
                <tr *ngIf="group.users?.length > 0">
                    <th>#</th>
                    <th>
                        <app-stack-layout class="header" direction="row">
                            <span>Email</span>
                            <app-icon
                                class="ml-xs"
                                icon="down-arrow"
                                size="14px"
                            ></app-icon>
                        </app-stack-layout>
                    </th>
                    <th>Name</th>
                    <th class="text-no-wrap">Last used</th>
                    <th></th>
                    <th class="table-fill-column"></th>
                </tr>
                <tr
                    *ngFor="
                        let user of group.users;
                        let i = index;
                        trackBy: trackByEmail
                    "
                    [@tableRow]
                    [class.license-over-limit]="i >= group.licensesCount"
                >
                    <td>{{ i + 1 }}</td>
                    <td class="text-no-wrap">
                        {{ user.email }}
                        <app-badge
                            *ngIf="user.email === userEmail"
                            color="success"
                            class="ml-xs"
                            >You</app-badge
                        >
                    </td>
                    <td class="text-no-wrap">
                        {{ user.fullName }}
                        <span *ngIf="!user.fullName" class="text-secondary"
                            >-</span
                        >
                    </td>
                    <td class="text-no-wrap">
                        {{ user.lastUsed }}
                        <span *ngIf="!user.lastUsed" class="text-secondary"
                            >-</span
                        >
                    </td>
                    <td>
                        <ng-container *ngIf="users._canDeleteUsers">
                            <span #revokeButton class="d-flex">
                                <app-button
                                    theme="default"
                                    size="small"
                                    class="on-tr-hover"
                                    [loading]="
                                        isRevokingUser[packageId] &&
                                        (isRevokingUser[packageId][
                                            user.email
                                        ] === 'pending' ||
                                            isRevokingUser[packageId][
                                                user.email
                                            ] === 'awaiting-refresh')
                                    "
                                    >Revoke
                                </app-button>
                            </span>

                            <app-modal #revokeModal [reference]="revokeButton">
                                <app-stack-layout modalHeader>
                                    <h2>Revoke license?</h2>
                                </app-stack-layout>

                                <app-stack-layout>
                                    <div class="overflow-x">
                                        Are you sure to revoke the licenses for
                                        {{ user.fullName || user.email }}?
                                    </div>
                                </app-stack-layout>

                                <div class="text-right" modalFooter>
                                    <app-button (click)="revokeModal.hide()"
                                        >Cancel</app-button
                                    >
                                    <app-button
                                        class="ml-xs"
                                        (click)="
                                            revokeUser(
                                                revokeModal,
                                                user.email,
                                                packageId
                                            )
                                        "
                                        >Revoke</app-button
                                    >
                                </div>
                            </app-modal>
                        </ng-container>
                    </td>
                    <td class="table-fill-column"></td>
                </tr>
                <tr *ngIf="users._canCreateUsers && getLicensesLeft(group) > 0">
                    <td class="text-top">{{ group.users.length + 1 }}</td>
                    <td class="text-top">
                        <app-input
                            [(ngModel)]="newEmail[packageId]"
                            name="email"
                            size="small"
                            (keyup.enter)="
                                assignUser($event.target.value, packageId)
                            "
                        ></app-input>
                        <div *ngIf="formErrors[packageId]?.email">
                            <app-error>{{
                                formErrors[packageId].email[0]
                            }}</app-error>
                        </div>
                    </td>
                    <td class="text-top" colspan="3">
                        <app-button
                            theme="default"
                            size="small"
                            [loading]="
                                isAssigningUser[packageId] === 'pending' ||
                                isAssigningUser[packageId] ===
                                    'awaiting-refresh'
                            "
                            (click)="assignUser(newEmail[packageId], packageId)"
                            >Assign
                        </app-button>

                        <a #assignInfoTooltip>
                            <app-icon
                                class="on-tr-hover ml-sm assign-info"
                                icon="info"
                                size="18px"
                            ></app-icon>
                        </a>
                        <app-tooltip [reference]="assignInfoTooltip"
                            >You can assign multiple licenses at once by typing
                            (or copy-paste) e-mail addresses separated by comma
                            or any whitespace character (i.e. new-line, space).
                        </app-tooltip>
                    </td>
                    <td class="table-fill-column"></td>
                </tr>
                <tr *ngIf="users._canCreateUsers">
                    <td colspan="5">
                        Allow everyone from following domains:
                        <div appTextAlign>
                            <div>
                                <app-input
                                    size="small"
                                    class="mr-xs"
                                    [(ngModel)]="domains[packageId]"
                                    #domain="ngModel"
                                    name="domains"
                                    (keyup.enter)="
                                        updateDomains(
                                            $event.target.value,
                                            packageId
                                        )
                                    "
                                    (keyup)="removeInvalidCharsFromDomain($event.target)"
                                    (change)="removeInvalidCharsFromDomain($event.target)"
                                    pattern="^(((?!-)[A-Za-z0-9- ]+([\\-\\.]{1}[a-z0-9 ]+)*\.[A-Za-z ]{2,6})|(?:\,)|(?:, ))*$"
                                    title="e.g. domain.com,second-domain.com,lowercase_name.com"
                                ></app-input>
                                <app-button
                                    theme="default"
                                    size="small"
                                    [loading]="
                                        isSavingDomains[packageId] ===
                                            'pending' ||
                                        isSavingDomains[packageId] ===
                                            'awaiting-refresh'
                                    "
                                    (click)="
                                        updateDomains(
                                            domains[packageId],
                                            packageId
                                        )
                                    "
                                    [disabled]="domain.errors"
                                    >Save
                                </app-button>
                                <div *ngIf="domain.errors?.pattern">
                                    <app-error>Incorrect domain name</app-error>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="domainsFormErrors[packageId]?.domains">
                            <app-error>{{
                                domainsFormErrors[packageId].domains[0]
                            }}</app-error>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </ng-template>

        <table>
            <ng-container *ngFor="let packageId of users.usersByPackage | keys">
                <ng-container
                    *ngTemplateOutlet="
                        usersByPackage;
                        context: {
                            $implicit: users.usersByPackage[packageId],
                            packageId: packageId
                        }
                    "
                ></ng-container>
            </ng-container>
        </table>

        <div class="mt-sm">
            <a
                [routerLink]="['/accounts', accountId, 'users']"
                appTextAlign="oneline"
            >
                <app-icon icon="left" size="14px" class="mr-xs"></app-icon>
                Back
            </a>
        </div>
    </div>
    <app-spinner class="fullsize" *ngIf="isLoading"></app-spinner>

    <app-page-bottom-space></app-page-bottom-space>
</app-stack-layout>
