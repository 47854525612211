import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {tap} from 'rxjs/operators';
import LoginWithGoogleResponse from 'src/app/api/responses/login-with-google.response';
import LoginWithMicrosoftResponse from 'src/app/api/responses/login-with-microsoft.response';

@Injectable({
    providedIn: 'root'
})
export class Oauth2RepositoryService {

    constructor(private http: HttpClient) {
    }

    public loginWithGoogle(): Observable<LoginWithGoogleResponse> {
        return this.http.post<LoginWithGoogleResponse>(`${environment.apiUrl}v1/login/google`, null)
            .pipe(tap(response => window.location.href = response.data.redirectUrl));
    }

    public loginWithMicrosoft(): Observable<LoginWithMicrosoftResponse> {
        return this.http.post<LoginWithMicrosoftResponse>(`${environment.apiUrl}v1/login/microsoft`, null)
            .pipe(tap(response => window.location.href = response.data.redirectUrl));
    }

}
