import {Component, DoCheck} from '@angular/core';
import {environment} from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements DoCheck {
    public ngDoCheck(): void {
        if (!environment.production) {
            // tslint:disable-next-line
            //console.info('DoCheck');
        }
    }
}
