import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CountriesRepositoryService {
    constructor(http) {
        this.http = http;
    }
    list() {
        return this.http.get(`${environment.apiUrl}v1/countries`);
    }
}
CountriesRepositoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CountriesRepositoryService_Factory() { return new CountriesRepositoryService(i0.ɵɵinject(i1.HttpClient)); }, token: CountriesRepositoryService, providedIn: "root" });
