import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class QuotesRepositoryService {
    constructor(http) {
        this.http = http;
    }
    listByAccountId(accountId) {
        return this.http.get(`${environment.apiUrl}v1/accounts/${accountId}/quotes`);
    }
    findByAccountIdAndQuoteNo(accountId, quoteNo) {
        const quoteNoNorm = quoteNo.replace('/', '-');
        return this.http.get(`${environment.apiUrl}v1/accounts/${accountId}/quotes/${quoteNoNorm}`);
    }
}
QuotesRepositoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QuotesRepositoryService_Factory() { return new QuotesRepositoryService(i0.ɵɵinject(i1.HttpClient)); }, token: QuotesRepositoryService, providedIn: "root" });
