import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import MenuGroup from './menu-group';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnChanges {

    private static DEFAULT_GROUP_NAME = '__DEFAULT__';

    @Input()
    public groups: MenuGroup[] = [];

    @Input()
    public items: MenuItem[] = [];

    public visibleGroups: MenuGroup[] = [];
    public itemsByGroup: { [groupKey: string]: MenuItem[] } = {};

    @Input()
    public theme: 'default' | 'small' = 'default';

    /**
     * Określa czy wyświetlać nazwy grup nad listą pozycji.
     * Nazwa grupy wyświetlana jest tylko wtedy gdy:
     * a) została podana podczas tworzenia obiektu MenuGroup
     * b) grupa jest wyswietlana (posiada MenuItem lub [showEmptyGroups]="true")
     */
    @Input()
    public showGroupsNames = true;

    /**
     * Wymusza wyswietlanie nazw dla pustych grup,
     * a takze separatorow miedzy pustymi grupami.
     */
    @Input()
    public showEmptyGroups = false;

    public ngOnInit(): void {
        this.filterItems();
    }

    public shouldDisplayGroupName(group: MenuGroup): boolean {
        return this.showGroupsNames // Włączone jest wyświetlanie grup
            && group.key !== MenuComponent.DEFAULT_GROUP_NAME // Grupa nie jest grupą domyślną (tworzoną automatycznie)
            && !!group.name; // Grupa posiada nazwę
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.items || changes.groups) {
            this.filterItems();
        }
    }

    /**
     * Usuwa puste grupy.
     */
    public filterItems() {
        // Pogrupowanie obiektów po kluczu grupy.
        this.itemsByGroup = {};
        for (const item of this.items) {
            const groupKey = item.groupKey || MenuComponent.DEFAULT_GROUP_NAME;
            if (this.itemsByGroup[groupKey] === undefined) {
                this.itemsByGroup[groupKey] = [];
            }

            this.itemsByGroup[groupKey].push(item);
        }

        // Przefiltrowanie niepustych grup.
        this.visibleGroups = this.defaultGroupHasItems()
            ? [{key: MenuComponent.DEFAULT_GROUP_NAME}]
            : [];

        const isGroupEmpty = (group) => this.itemsByGroup[group.key] === undefined;
        this.visibleGroups.push(...this.groups.filter(group => this.showEmptyGroups || !isGroupEmpty(group)));
    }

    private defaultGroupHasItems(): boolean {
        return this.itemsByGroup[MenuComponent.DEFAULT_GROUP_NAME] !== undefined;
    }

    public trackByKey(index: number, item: MenuGroup | MenuItem): string {
        return item.key;
    }
}
