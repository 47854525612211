<div class="box">
    <div class="box-content">
        <app-icon class="icon" icon="maintenance" size="120px"></app-icon>
        <h1 class="mt-md">Page not found</h1>
        <p class="mt-sm">The requested page was not found.</p>
        <p *ngIf="isExternalReferrer" class="mt-sm">You came here from the outside so <b>it isn't an issue with our application</b>, you can report that the link is out of date in the place where you found it.</p>
        <p *ngIf="isInternalReferrer" class="mt-sm"><b>You found a broken link in one of your applications.</b> You can <a [href]="contactHref">report this fact</a> to our support team, thanks!</p>

        <app-button-link class="mt-md" href="/" theme="primary" size="small">
            Back to homepage
        </app-button-link>
    </div>
</div>
