import { environment } from 'src/environments/environment';
import FormDataHelper from 'src/app/helpers/form-data-helper';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AccountRolesRepositoryService {
    constructor(http) {
        this.http = http;
    }
    listByAccountId(accountId) {
        return this.http.get(`${environment.apiUrl}v1/accounts/${accountId}/roles`);
    }
    assignRole(accountId, role, email) {
        return this.http.put(`${environment.apiUrl}v1/accounts/${accountId}/roles/assign`, FormDataHelper.convertToUrlEncoded({ role, email }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
    }
    revokeRole(accountId, email) {
        return this.http.put(`${environment.apiUrl}v1/accounts/${accountId}/roles/revoke`, FormDataHelper.convertToUrlEncoded({ email }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
    }
}
AccountRolesRepositoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountRolesRepositoryService_Factory() { return new AccountRolesRepositoryService(i0.ɵɵinject(i1.HttpClient)); }, token: AccountRolesRepositoryService, providedIn: "root" });
