<app-stack-layout class="page-content">
    <app-stack-layout class="header" direction="row">
        <app-tooltip [reference]="back">Back to quotes</app-tooltip>
        <a #back [routerLink]="['/accounts', accountId, 'quotes']" class="back">
            <app-icon icon="left"></app-icon>
        </a>

        <h1>Quote {{ quoteNo }}</h1>
    </app-stack-layout>

    <app-empty class="fullsize mt-lg" *ngIf="!isLoading && !quote">
        <app-stack-layout class="text-center">
            <h2>Quote not found</h2>
            <p class="mt-sm">We didn't find the {{ quoteNo }} quote.</p>
            <div class="mt-md">
                <a routerLink="/quotes">
                    &larr; Back to quotes
                </a>
            </div>
        </app-stack-layout>
    </app-empty>
    <ng-container *ngIf="quote && quote.quote_no === quoteNo">
        <div class="overflow-x mt-xl">
            <table>
                <colgroup>
                    <col style="width: 25%;">
                    <col>
                </colgroup>
                <tr>
                    <th class="text-no-wrap">Quote no:</th>
                    <td>
                        <app-document [name]="quote.quote_no"
                                      [fileUrl]="quote._quote_file_url">
                        </app-document>
                    </td>
                </tr>
                <tr>
                    <th class="text-no-wrap">Date:</th>
                    <td>
                        <app-timestamp [timestamp]="quote.requested_at"></app-timestamp>
                    </td>
                </tr>
                <tr>
                    <th class="text-no-wrap">Valid till:</th>
                    <td>
                        <app-timestamp [timestamp]="quote.expires_at"></app-timestamp>
                    </td>
                </tr>
                <tr>
                    <th class="text-no-wrap">Created by:</th>
                    <td>
                        {{ quote.contact.fullName }}
                        <div *ngIf="quote.contact.email">
                            <a href="mailto:{{ quote.contact.email }}">
                                {{ quote.contact.email }}
                            </a>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="quote.ref_no">
                    <th class="text-no-wrap">PO/ref no:</th>
                    <td>{{ quote.ref_no }}</td>
                </tr>
                <tr>
                    <th class="text-no-wrap">Payment Status:</th>
                    <td>
                        <app-badge [color]="quote.status.color">{{ quote.status.text }}</app-badge>
                    </td>
                </tr>
                <tr *ngIf="quote.rebate">
                    <th class="text-no-wrap">Discount:</th>
                    <td>{{ quote.rebate }}% {{ quote.coupon_code}}</td>
                </tr>
            </table>
        </div>

        <app-stack-layout class="mt-xl">
            <h2>Products</h2>
            <div class="overflow-x mt-sm">
                <table>
                    <tr>
                        <th>Product</th>
                        <th class="text-right">Price {{ quote.currency }}</th>
                        <th class="text-right">Qty</th>
                        <th class="text-right">Subtotal {{ quote.currency }}</th>
                    </tr>
                    <tr *ngFor="let line of quote.lines">
                        <td>
                            {{ line.name }}
                            <div *ngIf="line.validFrom && line.validTo">
                                <small appTextAlign>
                                    <span>(</span>
                                    <app-timestamp [timestamp]="line.validFrom"></app-timestamp>
                                    <span class="mx-xs">-</span>
                                    <app-timestamp [timestamp]="line.validTo"></app-timestamp>
                                    <span>)</span>
                                </small>
                            </div>
                        </td>
                        <td class="text-right">
                            {{ line.price | moneyFormat:line.currency }}
                        </td>
                        <td class="text-right">{{ line.quantity }}</td>
                        <td class="text-no-wrap text-right">
                            {{ line.amount | moneyFormat:line.currency:'-' }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <b>Grand Total</b>
                        </td>
                        <td class="text-right">
                            <b>{{ quote.amount | moneyFormat:quote.currency:'-' }}</b>
                        </td>
                    </tr>
                </table>
            </div>

            <small class="mt-sm">Prices are net of all taxes. Purchaser is responsible for paying all local taxes (e.g.
                VAT) imposed by the law of the purchaser's home country.
            </small>

            <div class="text-center mt-lg" *ngIf="quote._place_an_order_url">
                <app-button-link [href]="quote._place_an_order_url" [openInNewTab]="true" theme="primary">
                    Place an order
                    <app-icon class="ml-xs" icon="external-link" size="16px"></app-icon>
                </app-button-link>
            </div>
        </app-stack-layout>

        <div class="addresses">
            <app-stack-layout *ngIf="quote.billing_address" class="address mt-xl">
                <h2>Billing Address</h2>
                <div class="overflow-x mt-sm">
                    <div>{{ quote.billing_address.company }}</div>
                    <div>{{ quote.billing_address.address }}</div>
                    <div *ngIf="quote.billing_address.address2">{{ quote.billing_address.address2 }}</div>
                    <div>{{ quote.billing_address.city }} {{ quote.billing_address.postal_code }}</div>
                    <div>{{ quote.billing_address.state }}</div>
                    <div>{{ quote.billing_address.country }}</div>
                </div>
            </app-stack-layout>
        </div>

        <app-stack-layout *ngIf="quote.keys_sent_to.length > 0" class="mt-xl pb-xl">
            <h2>Quote document recipients</h2>
            <small class="mt-xs">Users with this emails addresses has received an email with the quote document.</small>
            <div class="overflow-x mt-sm">
                <ul>
                    <li *ngFor="let email of quote.keys_sent_to">
                        <a [attr.href]="'mailto:'+ email">{{ email }}</a>
                    </li>
                </ul>
            </div>
        </app-stack-layout>
    </ng-container>
    <app-spinner class="fullsize py-lg" *ngIf="isLoading"></app-spinner>
</app-stack-layout>
