import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ofActionCompleted, ofActionSuccessful, Select } from '@ngxs/store';
import { Subject, timer } from 'rxjs';
import { AuthState } from 'src/app/states/auth.state';
import { takeUntil } from 'rxjs/operators';
import { PushMenuActiveAccountId } from 'src/app/states/menu.actions';
import ResponseHandlingHelper from 'src/app/helpers/response-handling-helper';
import { SubscriptionsState } from 'src/app/states/subscriptions.state';
import { FetchSubscriptionUsers, } from 'src/app/states/subscriptions.actions';
import { HttpErrorCode } from 'src/app/api/http-error-code.enum';
export class SubscriptionUsersPageComponent {
    constructor(router, route, actions, store, title) {
        this.router = router;
        this.route = route;
        this.actions = actions;
        this.store = store;
        this.title = title;
        this.users = null;
        this.isLoading = true;
        /**
         * Token służący do anulowania odświeżania danych.
         */
        this.cancellationToken = new Subject();
        /**
         * Informuje o zmianie identyfikatora konta.
         *
         * Na sam koniec przed zniszczeniem komponentu wysyłana jest wartość "null".
         */
        this.accountIdChanged = new Subject();
        this.gc = new Subject();
    }
    get hasAnyUsers() {
        if (!this.users) {
            // Dane nie zostały jeszcze załadowane.
            return false;
        }
        if (this.users.accountId !== this.accountId) {
            // Dane są już załadowane (np. z cache),
            // ale dotyczą innej organizacji/konta.
            return false;
        }
        for (const packageId of Object.keys(this.users.usersByPackage)) {
            // pokaż userow jeśli liczba licencji jest > 0  <-- na tę chwilę ma pokazywać zawsze wszystkich przypisanych userow
            // if (this.users.usersByPackage[packageId].licensesCount > 0) {
            return true;
            // }
        }
        return false;
    }
    ngOnInit() {
        this.title.setTitle('Subscription users - Dataedo Account');
        this.initEvents();
    }
    initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));
        this.userEmailListener
            .pipe(takeUntil(this.gc))
            .subscribe(email => this.userEmail = email);
        this.usersListener
            .pipe(takeUntil(this.gc))
            .subscribe(users => this.onUsersChanged(users));
        this.actions
            .pipe(ofActionCompleted(FetchSubscriptionUsers))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedDataReload(event));
        this.actions
            .pipe(ofActionSuccessful(FetchSubscriptionUsers))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onSuccessfulDataReload(event));
    }
    onPathChange(params) {
        const newAccountId = parseInt(params.accountId, 10);
        if (this.accountId !== newAccountId) {
            this.accountIdChanged.next(this.accountId);
        }
        this.accountId = newAccountId;
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));
        this.scheduleFetchData();
    }
    onUsersChanged(users) {
        this.users = users;
    }
    getLicensesLeft(group) {
        return Math.max(0, group.licensesCount - group.users.length);
    }
    scheduleFetchData() {
        this.cancellationToken.next();
        timer(300)
            .pipe(takeUntil(this.cancellationToken))
            .subscribe(() => {
            this.isLoading = true;
            this.store.dispatch(new FetchSubscriptionUsers(this.accountId, this.cancellationToken));
        });
    }
    onCompletedDataReload(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Obsługa błędów serwera.
            const response = ResponseHandlingHelper.parse(event.result);
            if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
                yield this.router.navigate(['accounts', this.accountId]);
            }
            this.isLoading = false;
        });
    }
    onSuccessfulDataReload(event) {
        this.isLoading = false;
    }
    trackByEmail(index, user) {
        return user.email;
    }
    ngOnDestroy() {
        this.cancellationToken.next();
        this.cancellationToken.complete();
        this.accountIdChanged.next(null);
        this.accountIdChanged.complete();
        this.gc.next();
        this.gc.complete();
    }
}
tslib_1.__decorate([
    Select(SubscriptionsState.users)
], SubscriptionUsersPageComponent.prototype, "usersListener", void 0);
tslib_1.__decorate([
    Select(AuthState.email)
], SubscriptionUsersPageComponent.prototype, "userEmailListener", void 0);
