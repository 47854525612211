<app-stack-layout>
    <h1>My sessions</h1>

    <div class="my-lg" *ngIf="sessions">
        <table>
            <tr>
                <th>#</th>
                <th>Product</th>
                <th>Details</th>
                <th class="text-no-wrap">Created</th>
                <th class="text-no-wrap">Last used</th>
                <th class="text-no-wrap">Expires</th>
                <th></th>
                <th class="table-fill-column"></th>
            </tr>
            <tr *ngFor="let session of sessions; let i = index; trackBy: trackSessionById">
                <td class="text-top">{{ i + 1 }}</td>
                <td class="text-top">
                    <div class="text-no-wrap">
                        <b>{{ session.service }}</b>
                        <app-badge *ngIf="session.isCurrent" color="success" class="ml-xs">Current</app-badge>
                    </div>
                    <div class="text-small text-secondary mt-xs" *ngIf="session.description">
                        {{ session.description }}
                    </div>
                    <div>
                        <app-badge color="info" *ngFor="let scope of session.scopes" class="mr-xs">
                            {{ scope }}
                        </app-badge>
                    </div>
                </td>
                <td class="text-top">
                    <div class="text-no-wrap">
                        <span>{{ session.agent.service }}</span>
                    </div>
                    <div class="text-no-wrap" *ngIf="session.agent.ip">
                        <img *ngIf="session.agent.countryIconUrl"
                             src="{{ session.agent.countryIconUrl }}"
                             class="no-shrink mr-xs">
                        <span class="text-small text-secondary mr-xs">
                            {{ session.agent.ip }}
                        </span>
                    </div>
                    <div class="text-small text-secondary">
                        {{ session.agent.system }}
                    </div>
                </td>
                <td class="text-top text-no-wrap">
                    <app-timestamp *ngIf="session.createdAt" [timestamp]="session.createdAt"></app-timestamp>
                </td>
                <td class="text-top text-no-wrap">
                    <app-timestamp *ngIf="session.lastUsedAt" [timestamp]="session.lastUsedAt"></app-timestamp>
                </td>
                <td class="text-top text-no-wrap">
                    <app-timestamp *ngIf="session.expiresAt; else lifetimeSession" [timestamp]="session.expiresAt"></app-timestamp>
                    <ng-template #lifetimeSession><app-badge color="gray">Lifetime</app-badge></ng-template>
                </td>
                <td class="text-top text-no-wrap">
                    <app-button size="small"
                                [loading]="revokePending[session.id]"
                                (click)="revoke(session)">
                        End session
                    </app-button>
                </td>
                <td class="table-fill-column"></td>
            </tr>
        </table>
    </div>
    <app-spinner class="fullsize" *ngIf="isLoading"></app-spinner>
</app-stack-layout>
