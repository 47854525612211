/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./icon.component";
import * as i2 from "@angular/common/http";
var styles_IconComponent = ["[_nghost-%COMP%] { font-size: 0; }"];
var RenderType_IconComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_IconComponent, data: {} });
export { RenderType_IconComponent as RenderType_IconComponent };
export function View_IconComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_IconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-icon", [], [[4, "width", null], [4, "height", null], [4, "display", null], [4, "verticalAlign", null]], null, null, View_IconComponent_0, RenderType_IconComponent)), i0.ɵdid(1, 638976, null, 0, i1.IconComponent, [i0.ElementRef, i2.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).size; var currVal_1 = i0.ɵnov(_v, 1).size; var currVal_2 = i0.ɵnov(_v, 1).display; var currVal_3 = i0.ɵnov(_v, 1).verticalAlign; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
var IconComponentNgFactory = i0.ɵccf("app-icon", i1.IconComponent, View_IconComponent_Host_0, { icon: "icon", size: "size", display: "display", verticalAlign: "verticalAlign", theme: "theme" }, {}, []);
export { IconComponentNgFactory as IconComponentNgFactory };
