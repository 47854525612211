import {Component, OnDestroy, OnInit} from '@angular/core';
import {Actions, ofActionCompleted, Select, Store} from '@ngxs/store';
import {MenuState} from 'src/app/states/menu.state';
import {Observable, Subject} from 'rxjs';
import {MenuItemModelResponse, MenuModelResponse} from 'src/app/api/responses/menu-response';
import {takeUntil} from 'rxjs/operators';
import {FetchMenu, PushMenuActiveAccountId} from 'src/app/states/menu.actions';
import {environment} from 'src/environments/environment';
import {Title} from '@angular/platform-browser';
import IntercomHelper from '../../helpers/intercom-helper';

@Component({
    selector: 'app-accounts-page',
    templateUrl: './accounts-page.component.html',
    styleUrls: ['./accounts-page.component.scss']
})
export class AccountsPageComponent implements OnInit, OnDestroy {

    public freeTrialUrl = `${environment.websiteUrl}free-trial`;

    @Select(MenuState.menu)
    public menuListener: Observable<MenuModelResponse | null>;
    public menu: MenuModelResponse | null = null;

    public isLoading = true;

    private gc = new Subject();

    public get shouldShowPersonalLicenses(): boolean {
        if (!this.menu) {
            return false;
        }

        return this.menu.personal.trials || this.menu.personal.educationalLicenses;
    }

    public get hasAnyLicenses(): boolean {
        if (!this.menu) {
            return false;
        }

        return this.shouldShowPersonalLicenses
            || this.menu.accounts.length > 0;
    }

    constructor(
        private store: Store,
        private actions: Actions,
        private title: Title
    ) {
    }

    public ngOnInit(): void {
        this.title.setTitle('Your accounts - Dataedo Account');
        this.initEvents();
        this.fetchData();
    }

    private initEvents() {
        this.menuListener
            .pipe(takeUntil(this.gc))
            .subscribe(menu => this.menu = menu);

        this.actions
            .pipe(ofActionCompleted(FetchMenu))
            .pipe(takeUntil(this.gc))
            .subscribe(() => this.isLoading = false);
    }

    private fetchData() {
        this.isLoading = true;

        this.store.dispatch(new PushMenuActiveAccountId(undefined));
        this.store.dispatch(new FetchMenu(this.gc));
    }

    public trackById(index: number, menuItem: MenuItemModelResponse): number {
        return menuItem.accountId || -1;
    }

    public openChat(): void {
        IntercomHelper.show();
    }

    public ngOnDestroy(): void {
        this.gc.next();
        this.gc.complete();
    }
}
