import {Pipe, PipeTransform} from '@angular/core';

interface MoneyFormat {
    prefix: string;
    postfix: string;
    decimalPlaces: number;
    decimalSeparator: string;
    thousandSeparator: string;
}

@Pipe({
    name: 'moneyFormat'
})
export class MoneyFormatPipe implements PipeTransform {

    private currencies: { [currency: string]: MoneyFormat } = {
        USD: {
            prefix: '$',
            postfix: '',
            decimalPlaces: 2,
            decimalSeparator: '.',
            thousandSeparator: ',',
        },
        EUR: {
            prefix: '€',
            postfix: '',
            decimalPlaces: 2,
            decimalSeparator: ',',
            thousandSeparator: '.',
        },
        GBP: {
            prefix: '£',
            postfix: '',
            decimalPlaces: 2,
            decimalSeparator: '.',
            thousandSeparator: ',',
        },
        CAD: {
            prefix: '$',
            postfix: '',
            decimalPlaces: 2,
            decimalSeparator: '.',
            thousandSeparator: ',',
        },
        AUD: {
            prefix: '$',
            postfix: '',
            decimalPlaces: 2,
            decimalSeparator: '.',
            thousandSeparator: ',',
        },
        JPY: {
            prefix: '¥ ',
            postfix: '',
            decimalPlaces: 0,
            decimalSeparator: '.',
            thousandSeparator: ',',
        }
    };

    private formatMoney(amount: number, format: MoneyFormat) {
        const sign = amount < 0 ? '-' : '';
        const value = Math.abs(Math.trunc(amount));
        const decimal = Math.abs(amount - Math.trunc(amount)).toFixed(format.decimalPlaces).slice(2);

        const chunks = [];
        let valueStr = value.toString(10);
        while (valueStr.length > 0) {
            chunks.push(valueStr.substr(-3));
            valueStr = valueStr.substr(0, valueStr.length - 3);
        }

        return sign
            + format.prefix
            + (chunks.length > 0 ? chunks.reverse().join(format.thousandSeparator) : 0)
            + (format.decimalPlaces > 0 ? format.decimalSeparator + decimal : '')
            + format.postfix;
    }

    public transform(amount?: number, currency?: string, nullValue: string = ''): any {
        if (currency === undefined || currency === null
            || amount === undefined || amount === null) {
            return nullValue;
        }

        const format = this.currencies[currency];
        if (format === undefined) {
            console.warn(`The ${currency} currency is not supported.`);
        }

        return this.formatMoney(amount, format);
    }

}
