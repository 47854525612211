<textarea #model
          [attr.placeholder]="placeholder"
          [attr.disabled]="disabled ? 'disabled' : null"
          [style.textAlign]="textAlign"
          [class.small]="size === 'small'"
          [class.monospace]="monospace"
          [class.only-edit-mode]="previewMode === 'no'"
          [class.only-preview-mode]="previewMode === 'yes'"
          [value]="value"
          [attr.rows]="rows"
          (change)="onChange(model.value)"
          (keydown)="onChange(model.value)"
          (keyup)="onChange(model.value)"></textarea>
