import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {map} from 'rxjs/operators';
import FormDataHelper from '../../helpers/form-data-helper';
import SessionConfirmResponse from '../responses/session-confirm-response';
import SessionRegisterResponse from '../responses/session-register-response';
import ProfileResponse from '../responses/profile-response';
import SessionsResponse from '../responses/sessions-response';

@Injectable({
    providedIn: 'root',
})
export class SessionsRepositoryService {

    constructor(private http: HttpClient) {
    }

    public profile(): Observable<ProfileResponse> {
        return this.http.get<ProfileResponse>(`${environment.apiUrl}v1/profile`);
    }

    public create(email: string): Observable<any> {
        return this.http.post(
            `${environment.apiUrl}v1/sessions`,
            FormDataHelper.convertToMultipart({email, service: 'PORTAL'}),
        );
    }

    public confirm(email: string, code: string, remember: boolean): Observable<SessionConfirmResponse> {
        return this.http.post<SessionConfirmResponse>(
            `${environment.apiUrl}v1/sessions/confirm`,
            FormDataHelper.convertToMultipart({email, code, remember}),
        );
    }

    public register(email: string, code: string, firstName: string, lastName: string): Observable<SessionRegisterResponse> {
        return this.http.post<SessionRegisterResponse>(
            `${environment.apiUrl}v1/sessions/register`,
            FormDataHelper.convertToMultipart({email, code, firstName, lastName}),
        );
    }

    public list(): Observable<SessionsResponse> {
        return this.http.get<SessionsResponse>(`${environment.apiUrl}v1/sessions`);
    }

    public revoke(sessionId: number): Observable<void> {
        return this.http.delete<void>(`${environment.apiUrl}v1/sessions/${sessionId}/revoke`);
    }
}
