import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Subject} from 'rxjs';
import {ActivatedRoute, Data} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {AuthState} from '../../states/auth.state';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-guest-page',
    templateUrl: './guest-page.component.html',
    styleUrls: ['./guest-page.component.scss'],
})
export class GuestPageComponent implements OnInit, OnDestroy {

    public today = new Date();

    public websiteUrl = environment.websiteUrl;
    public privacyPolicyUrl = `${environment.websiteUrl}privacy`;

    private componentDestroyed = new Subject();
    public showWelcome = false;

    constructor(
        private store: Store,
        private title: Title,
        private route: ActivatedRoute,
    ) {
    }

    public ngOnInit(): void {
        this.title.setTitle('Dataedo Account');

        this.route.data
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(data => this.onDataChange(data));
    }

    private onDataChange(data: Data) {
        const intendedUrl = this.store.selectSnapshot(AuthState.intendedUrl);
        if (intendedUrl && intendedUrl.startsWith('/sso')) {
            this.showWelcome = false;
        } else if (data.showWelcome === true || data.showWelcome === false) {
            this.showWelcome = data.showWelcome;
        }
    }

    public ngOnDestroy(): void {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }
}
