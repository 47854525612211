<app-stack-layout>
    <app-stack-layout class="header" direction="row">
        <h1>Subscription</h1>
        <app-badge *ngIf="subscription && subscription.accountId === accountId && subscription.isExpired"
            class="ml-sm" color="gray">
            Expired subscription
        </app-badge>
    </app-stack-layout>

    <app-empty class="mt-lg mb-lg" style="flex: 0; display: block" *ngIf="!isLoading && subscription?.modules?.length === 0">
        <app-stack-layout class="text-left">
            <p class="mt-sm">We didn't find any licenses in your account.</p>
        </app-stack-layout>
    </app-empty>

    <ng-container *ngIf="subscription && subscription.accountId === accountId">
        <div class="mt-xs" *ngIf="subscription.subscription?.billingPeriodEnd">
            <p class="mt-xs">Billing period ends on:
                <app-timestamp [timestamp]="subscription.subscription.billingPeriodEnd"></app-timestamp>
            </p>
        </div>

        <div class="mt-md" *ngIf="subscription?.modules && (subscription?.modules|keys)?.length > 0">
            <table>
                <tr>
                    <th>Item</th>
                    <th class="text-right">Price</th>
                    <th class="text-right">Count</th>
                    <th class="text-right">Users</th>
                    <th class="text-right" *ngIf="subscription._showPrices">Cost</th>
                    <th class="table-fill-column"></th>
                </tr>
                <ng-container *ngFor="let period of subscription.modules|keys; let i = index">
                    <tr>
                        <td colspan="6" [class.pt-md]="i > 0"><b>{{ period }}</b></td>
                    </tr>
                    <ng-container *ngFor="let module of subscription.modules[period]">
                        <tr>
                            <td class="text-no-wrap">
                                <app-package-modules [packageCode]="showDetailsForPackageId" [header]="module.name">
                                    <a (click)="showDetailsForPackageId = module.packageCode">{{ module.name }}</a>
                                </app-package-modules>
                            </td>
                            <td class="text-no-wrap text-right">{{ module.price|moneyFormat:module.currency }}</td>
                            <td class="text-no-wrap text-right">{{ module.count }}</td>
                            <td class="text-no-wrap text-right">
                                {{ module.packageCode === 'DATAEDO_UNLIMITED' ? 'Unlimited' : module.users }}
                            </td>
                            <td class="text-no-wrap text-right" *ngIf="subscription._showPrices">
                                <ng-container *ngIf="module.price">
                                    {{ (module.price * module.count)|moneyFormat:module.currency }}
                                </ng-container>
                            </td>
                            <td class="table-fill-column"></td>
                        </tr>
                    </ng-container>
                </ng-container>
                <tr *ngIf="subscription.pricing.total > 0 && subscription.currency">
                    <td [attr.colspan]="subscription._showPrices ? 4 : 3"></td>
                    <td class="text-no-wrap text-right">
                        <h2>{{ subscription.pricing.total|moneyFormat:subscription.currency }}</h2>
                    </td>
                    <td class="table-fill-column"></td>
                </tr>
            </table>

            <div class="mt-md">
                <app-button-link *ngIf="subscription._canManageUsers" size="small"
                    class="mr-sm" [routerLink]="['/accounts', subscription.accountId, 'users']">
                    Manage users
                </app-button-link>
                <app-button-link *ngIf="subscription.manageUrl && !subscription.yourAccess.reseller" size="small"
                    class="mr-sm" [openInNewTab]="true" [href]="subscription.manageUrl">
                    Manage subscription
                </app-button-link>


            </div>
        </div>
    </ng-container>

    <ng-container>
        <app-stack-layout class="header" direction="row">
            <h1 style="margin-top: 2.5rem; margin-bottom: .5rem;">Licenses</h1>
        </app-stack-layout>

        <div class="mt-md">
            <table>
                <tbody>
                <tr>
                    <th colspan="2">Account ID:</th>
                    <td>
                        {{ accountId }}
                        <app-icon class="ml-sm border" (click)="copyMessage('Account Id', accountId.toString())" icon="copy-to-clipboard" size="24px" style="padding:2px">Copy Account ID</app-icon>
                    </td>
                    <th class="table-fill-column"></th>
                </tr>

                <tr *ngIf="subscription?.yourAccess && (subscription?.yourAccess.role === 'OWNER' || subscription?.yourAccess.role === 'ADMIN')">
                    <th colspan="2">Subscription Token:</th>
                    <td class="text-no-wrap">
                        <span id="subscriptionToken" *ngIf="subscriptionToken; else noSubscriptionToken">
                            {{ hideToken(subscriptionToken) }}
                            <app-icon class="ml-sm border" (click)="copyMessage('Subscription token', subscriptionToken)" icon="copy-to-clipboard" size="24px" style="padding:2px">Copy Token</app-icon>
                        </span>
                        <ng-template #noSubscriptionToken><i>No Token</i></ng-template>

                        <button class="ml-3 subscriptionToken-button" (click)="regenerateToken()" [disabled]="isCreatingToken">
                            <app-spinner *ngIf="isCreatingToken" style="min-height:0;vertical-align:middle"></app-spinner>
                            Re-generate Token
                        </button>
                    </td>
                    <th class="table-fill-column"></th>
                </tr>

                <tr>
                    <th>Offline License:</th>
                    <td colspan="2">
                        <app-button *ngIf="subscription?._canDownloadOfflineLicense" [loading]="isDownloadingLicense" size="small"
                            class="mr-sm" (click)="downloadOfflineLicense()">
                            Download offline license
                        </app-button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <app-spinner class="fullsize" *ngIf="isLoading"></app-spinner>
</app-stack-layout>
