import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {ActionCompletion, Actions, ofActionCompleted, ofActionSuccessful, Select, Store} from '@ngxs/store';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {PushMenuActiveAccountId} from 'src/app/states/menu.actions';
import {Observable, Subject, timer} from 'rxjs';
import {AccountRolesState} from 'src/app/states/account-roles.state';
import {AccountRolesItemModelResponse, AccountRolesModelResponse} from 'src/app/api/responses/account-roles-response';
import {AssignAccountRole, FetchAccountRoles, RevokeAccountRole} from 'src/app/states/account-roles.actions';
import ResponseHandlingHelper from 'src/app/helpers/response-handling-helper';
import {AuthState} from 'src/app/states/auth.state';
import {AccountRoleEnum} from 'src/app/api/enums/account-role.enum';
import {PushAlert} from 'src/app/states/alerts.actions';
import {animate, style, transition, trigger} from '@angular/animations';
import CompareHelper from 'src/app/helpers/compare-helper';
import {HttpErrorCode} from 'src/app/api/http-error-code.enum';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-account-roles-page',
    templateUrl: './account-roles-page.component.html',
    styleUrls: ['./account-roles-page.component.scss'],
    animations: [
        trigger('tableRow', [
            transition(':leave', [
                animate(400, style({transform: 'translateX(60px)', opacity: 0}))
            ])
        ])
    ]
})
export class AccountRolesPageComponent implements OnInit, OnDestroy {

    public accountId: number;

    @Select(AccountRolesState.roles)
    public rolesListener: Observable<AccountRolesModelResponse>;
    public roles: AccountRolesModelResponse = null;

    @Select(AuthState.email)
    public userEmailListener: Observable<string>;
    public userEmail: string;

    public isLoading = true;

    /**
     * Token służący do anulowania pobierania danych.
     */
    private cancellationToken = new Subject();
    private gc = new Subject();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private actions: Actions,
        private store: Store,
        private title: Title
    ) {
    }

    public ngOnInit() {
        this.title.setTitle('Administration - Dataedo Account');
        this.initEvents();
    }

    private initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));

        this.userEmailListener
            .pipe(takeUntil(this.gc))
            .subscribe(email => this.userEmail = email);

        this.rolesListener
            .pipe(takeUntil(this.gc))
            .subscribe(roles => this.roles = roles);

        this.actions
            .pipe(ofActionCompleted(FetchAccountRoles))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedDataReload(event));
    }

    private onPathChange(params: Params) {
        this.accountId = parseInt(params.accountId, 10);
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));

        this.scheduleFetchData();
    }

    private scheduleFetchData() {
        this.cancellationToken.next();

        timer(300)
            .pipe(takeUntil(this.cancellationToken))
            .subscribe(() => {
                this.isLoading = true;
                this.store.dispatch(new FetchAccountRoles(this.accountId, this.cancellationToken));
            });
    }

    private async onCompletedDataReload(event: ActionCompletion<FetchAccountRoles, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);
        if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
            await this.router.navigate(['accounts', this.accountId]);
        }

        this.isLoading = false;
    }

    public trackById(index: number, role: AccountRolesItemModelResponse): number {
        return role.id;
    }

    public ngOnDestroy(): void {
        this.cancellationToken.next();
        this.cancellationToken.complete();

        this.gc.next();
        this.gc.complete();
    }
}
