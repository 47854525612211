/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./copy-to-clipboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-clipboard";
import * as i3 from "../../elements/icon/icon.component.ngfactory";
import * as i4 from "../../elements/icon/icon.component";
import * as i5 from "@angular/common/http";
import * as i6 from "../../elements/tooltip/tooltip.component.ngfactory";
import * as i7 from "../../elements/tooltip/tooltip.component";
import * as i8 from "./copy-to-clipboard.component";
import * as i9 from "@ngxs/store";
var styles_CopyToClipboardComponent = [i0.styles];
var RenderType_CopyToClipboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CopyToClipboardComponent, data: {} });
export { RenderType_CopyToClipboardComponent as RenderType_CopyToClipboardComponent };
export function View_CopyToClipboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["reference", 1]], null, 5, "a", [["class", "element"], ["tabindex", "0"]], null, [[null, "keydown.enter"], [null, "cbOnSuccess"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = ($event.target.click() !== false);
        ad = (pd_1 && ad);
    } if (("cbOnSuccess" === en)) {
        var pd_2 = (_co.copied($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i2.ClipboardDirective, [i2.ClipboardService], { cbContent: [0, "cbContent"] }, { cbOnSuccess: "cbOnSuccess" }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "code", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-icon", [["class", "ml-sm"], ["icon", "copy-to-clipboard"], ["size", "16px"]], [[4, "width", null], [4, "height", null], [4, "display", null], [4, "verticalAlign", null]], null, null, i3.View_IconComponent_0, i3.RenderType_IconComponent)), i1.ɵdid(5, 638976, null, 0, i4.IconComponent, [i1.ElementRef, i5.HttpClient], { icon: [0, "icon"], size: [1, "size"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "app-tooltip", [["placement", "bottom"]], null, null, null, i6.View_TooltipComponent_0, i6.RenderType_TooltipComponent)), i1.ɵdid(7, 245760, null, 0, i7.TooltipComponent, [], { placement: [0, "placement"], reference: [1, "reference"] }, null), (_l()(), i1.ɵted(-1, 0, [" Copy to clipboard\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); var currVal_6 = "copy-to-clipboard"; var currVal_7 = "16px"; _ck(_v, 5, 0, currVal_6, currVal_7); var currVal_8 = "bottom"; var currVal_9 = i1.ɵnov(_v, 0); _ck(_v, 7, 0, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.text; _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 5).size; var currVal_3 = i1.ɵnov(_v, 5).size; var currVal_4 = i1.ɵnov(_v, 5).display; var currVal_5 = i1.ɵnov(_v, 5).verticalAlign; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_CopyToClipboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-copy-to-clipboard", [], null, null, null, View_CopyToClipboardComponent_0, RenderType_CopyToClipboardComponent)), i1.ɵdid(1, 49152, null, 0, i8.CopyToClipboardComponent, [i9.Store], null, null)], null, null); }
var CopyToClipboardComponentNgFactory = i1.ɵccf("app-copy-to-clipboard", i8.CopyToClipboardComponent, View_CopyToClipboardComponent_Host_0, { text: "text" }, {}, []);
export { CopyToClipboardComponentNgFactory as CopyToClipboardComponentNgFactory };
