/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accounts-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../elements/empty/empty.component.ngfactory";
import * as i3 from "../../elements/empty/empty.component";
import * as i4 from "../../elements/stack-layout/stack-layout.component.ngfactory";
import * as i5 from "../../elements/stack-layout/stack-layout.component";
import * as i6 from "../../elements/icon/icon.component.ngfactory";
import * as i7 from "../../elements/icon/icon.component";
import * as i8 from "@angular/common/http";
import * as i9 from "../../elements/button/button.component.ngfactory";
import * as i10 from "../../elements/button/button.component";
import * as i11 from "../../elements/button-link/button-link.component.ngfactory";
import * as i12 from "../../elements/button-link/button-link.component";
import * as i13 from "@angular/router";
import * as i14 from "@angular/common";
import * as i15 from "../../elements/badge/badge.component.ngfactory";
import * as i16 from "../../elements/badge/badge.component";
import * as i17 from "../../elements/spinner/spinner.component.ngfactory";
import * as i18 from "../../elements/spinner/spinner.component";
import * as i19 from "./accounts-page.component";
import * as i20 from "@ngxs/store";
import * as i21 from "@angular/platform-browser";
var styles_AccountsPageComponent = [i0.styles];
var RenderType_AccountsPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountsPageComponent, data: {} });
export { RenderType_AccountsPageComponent as RenderType_AccountsPageComponent };
function View_AccountsPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "app-empty", [["class", "fullsize"]], null, null, null, i2.View_EmptyComponent_0, i2.RenderType_EmptyComponent)), i1.ɵdid(1, 49152, null, 0, i3.EmptyComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 16, "app-stack-layout", [["class", "text-center"]], [[4, "flexDirection", null]], null, null, i4.View_StackLayoutComponent_0, i4.RenderType_StackLayoutComponent)), i1.ɵdid(3, 49152, null, 0, i5.StackLayoutComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "app-icon", [["class", "empty-icon"], ["icon", "sad"], ["size", "120px"]], [[4, "width", null], [4, "height", null], [4, "display", null], [4, "verticalAlign", null]], null, null, i6.View_IconComponent_0, i6.RenderType_IconComponent)), i1.ɵdid(5, 638976, null, 0, i7.IconComponent, [i1.ElementRef, i8.HttpClient], { icon: [0, "icon"], size: [1, "size"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "h2", [["class", "mt-md"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No licenses found"])), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "p", [["class", "mt-sm"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No licenses found assigned to your email or domain. No account has assigned access to this email."])), (_l()(), i1.ɵeld(10, 0, null, 0, 8, "div", [["class", "mt-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "app-button", [["class", "mr-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openChat() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ButtonComponent_0, i9.RenderType_ButtonComponent)), i1.ɵdid(12, 49152, null, 0, i10.ButtonComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, [" Contact support "])), (_l()(), i1.ɵeld(14, 0, null, null, 4, "app-button-link", [], null, null, null, i11.View_ButtonLinkComponent_0, i11.RenderType_ButtonLinkComponent)), i1.ɵdid(15, 49152, null, 0, i12.ButtonLinkComponent, [i13.Router], { href: [0, "href"], openInNewTab: [1, "openInNewTab"] }, null), (_l()(), i1.ɵted(-1, 0, [" Get free trial "])), (_l()(), i1.ɵeld(17, 0, null, 0, 1, "app-icon", [["class", "ml-xs"], ["icon", "external-link"], ["size", "16px"]], [[4, "width", null], [4, "height", null], [4, "display", null], [4, "verticalAlign", null]], null, null, i6.View_IconComponent_0, i6.RenderType_IconComponent)), i1.ɵdid(18, 638976, null, 0, i7.IconComponent, [i1.ElementRef, i8.HttpClient], { icon: [0, "icon"], size: [1, "size"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = "sad"; var currVal_6 = "120px"; _ck(_v, 5, 0, currVal_5, currVal_6); var currVal_7 = _co.freeTrialUrl; var currVal_8 = true; _ck(_v, 15, 0, currVal_7, currVal_8); var currVal_13 = "external-link"; var currVal_14 = "16px"; _ck(_v, 18, 0, currVal_13, currVal_14); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).direction; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 5).size; var currVal_2 = i1.ɵnov(_v, 5).size; var currVal_3 = i1.ɵnov(_v, 5).display; var currVal_4 = i1.ɵnov(_v, 5).verticalAlign; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_9 = i1.ɵnov(_v, 18).size; var currVal_10 = i1.ɵnov(_v, 18).size; var currVal_11 = i1.ɵnov(_v, 18).display; var currVal_12 = i1.ɵnov(_v, 18).verticalAlign; _ck(_v, 17, 0, currVal_9, currVal_10, currVal_11, currVal_12); }); }
function View_AccountsPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "td", [], [[4, "width", "px"]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["routerLink", "/trials"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i13.RouterLinkWithHref, [i13.Router, i13.ActivatedRoute, i14.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_3 = "/trials"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = 400; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).target; var currVal_2 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_4 = _co.menu.personal.accountName; _ck(_v, 4, 0, currVal_4); }); }
function View_AccountsPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "td", [], [[4, "width", "px"]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i13.RouterLinkWithHref, [i13.Router, i13.ActivatedRoute, i14.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-badge", [["class", "ml-sm"], ["color", "#c2c2c2"]], null, null, null, i15.View_BadgeComponent_0, i15.RenderType_BadgeComponent)), i1.ɵdid(6, 638976, null, 0, i16.BadgeComponent, [], { color: [0, "color"], outline: [1, "outline"] }, null), (_l()(), i1.ɵted(7, 0, ["", ""]))], function (_ck, _v) { var currVal_3 = i1.ɵinlineInterpolate(1, "/accounts/", _v.context.$implicit.accountId, ""); _ck(_v, 3, 0, currVal_3); var currVal_5 = "#c2c2c2"; var currVal_6 = true; _ck(_v, 6, 0, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = 400; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).target; var currVal_2 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_4 = _v.context.$implicit.accountName; _ck(_v, 4, 0, currVal_4); var currVal_7 = _v.context.$implicit.accountId; _ck(_v, 7, 0, currVal_7); }); }
function View_AccountsPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your accounts"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "mt-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Accounts which you're a member are listed below:"])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "mt-lg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "table", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountsPageComponent_3)), i1.ɵdid(8, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountsPageComponent_4)), i1.ɵdid(10, 278528, null, 0, i14.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "mt-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["href", "https://dataedo.com/docs/customer-account"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Can't see your subscription? Learn more"])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "mt-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "app-button", [["class", "mr-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openChat() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ButtonComponent_0, i9.RenderType_ButtonComponent)), i1.ɵdid(16, 49152, null, 0, i10.ButtonComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, [" Contact support "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shouldShowPersonalLicenses; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.menu.accounts; var currVal_2 = _co.trackById; _ck(_v, 10, 0, currVal_1, currVal_2); }, null); }
function View_AccountsPageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner", [["class", "fullsize"]], [[2, "small", null]], null, null, i17.View_SpinnerComponent_0, i17.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i18.SpinnerComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).fitToSize; _ck(_v, 0, 0, currVal_0); }); }
export function View_AccountsPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "app-stack-layout", [], [[4, "flexDirection", null]], null, null, i4.View_StackLayoutComponent_0, i4.RenderType_StackLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i5.StackLayoutComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AccountsPageComponent_1)), i1.ɵdid(3, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AccountsPageComponent_2)), i1.ɵdid(5, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AccountsPageComponent_5)), i1.ɵdid(7, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 0, "div", [["class", "pt-md"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (!_co.isLoading && !_co.hasAnyLicenses); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.hasAnyLicenses; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.isLoading; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).direction; _ck(_v, 0, 0, currVal_0); }); }
export function View_AccountsPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-accounts-page", [], null, null, null, View_AccountsPageComponent_0, RenderType_AccountsPageComponent)), i1.ɵdid(1, 245760, null, 0, i19.AccountsPageComponent, [i20.Store, i20.Actions, i21.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountsPageComponentNgFactory = i1.ɵccf("app-accounts-page", i19.AccountsPageComponent, View_AccountsPageComponent_Host_0, {}, {}, []);
export { AccountsPageComponentNgFactory as AccountsPageComponentNgFactory };
