import {Subject} from 'rxjs';
import {AccountRoleEnum} from 'src/app/api/enums/account-role.enum';

export class FetchAccountRoles {
    public static readonly type = '[AccountRoles] FetchAccountRoles';

    constructor(public accountId: number, public cancellationToken?: Subject<any>) {
    }
}

export class AssignAccountRole {
    public static readonly type = '[AccountRoles] AssignAccountRole';

    constructor(public accountId: number, public role: AccountRoleEnum, public email: string, public cancellationToken?: Subject<any>) {
    }
}

export class RevokeAccountRole {
    public static readonly type = '[AccountRoles] RevokeAccountRole';

    constructor(public accountId: number, public email: string, public cancellationToken?: Subject<any>) {
    }
}
