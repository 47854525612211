import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ofActionCompleted, Select } from '@ngxs/store';
import { MenuState } from 'src/app/states/menu.state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FetchMenu, PushMenuActiveAccountId } from 'src/app/states/menu.actions';
import { environment } from 'src/environments/environment';
import IntercomHelper from '../../helpers/intercom-helper';
export class AccountsPageComponent {
    constructor(store, actions, title) {
        this.store = store;
        this.actions = actions;
        this.title = title;
        this.freeTrialUrl = `${environment.websiteUrl}free-trial`;
        this.menu = null;
        this.isLoading = true;
        this.gc = new Subject();
    }
    get shouldShowPersonalLicenses() {
        if (!this.menu) {
            return false;
        }
        return this.menu.personal.trials || this.menu.personal.educationalLicenses;
    }
    get hasAnyLicenses() {
        if (!this.menu) {
            return false;
        }
        return this.shouldShowPersonalLicenses
            || this.menu.accounts.length > 0;
    }
    ngOnInit() {
        this.title.setTitle('Your accounts - Dataedo Account');
        this.initEvents();
        this.fetchData();
    }
    initEvents() {
        this.menuListener
            .pipe(takeUntil(this.gc))
            .subscribe(menu => this.menu = menu);
        this.actions
            .pipe(ofActionCompleted(FetchMenu))
            .pipe(takeUntil(this.gc))
            .subscribe(() => this.isLoading = false);
    }
    fetchData() {
        this.isLoading = true;
        this.store.dispatch(new PushMenuActiveAccountId(undefined));
        this.store.dispatch(new FetchMenu(this.gc));
    }
    trackById(index, menuItem) {
        return menuItem.accountId || -1;
    }
    openChat() {
        IntercomHelper.show();
    }
    ngOnDestroy() {
        this.gc.next();
        this.gc.complete();
    }
}
tslib_1.__decorate([
    Select(MenuState.menu)
], AccountsPageComponent.prototype, "menuListener", void 0);
