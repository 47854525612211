import {Subject, timer} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

export interface IntercomData {
    email: string;
    user_id?: number;
}

export default class IntercomHelper {
    public static update(data: IntercomData) {
        // @ts-ignore
        window.intercomSettings = {
            // @ts-ignore
            app_id: window.intercomSettings.app_id,
            email: data.email,
            user_id: data.user_id
        };

        // @ts-ignore
        Intercom('update');
    }

    public static show() {
        // @ts-ignore
        Intercom('show');
    }

    public static reboot() {
        // @ts-ignore
        Intercom('shutdown');
        this.boot();
    }

    private static boot() {
        // @ts-ignore
        window.intercomSettings = {
            // @ts-ignore
            app_id: window.intercomSettings.app_id
        };

        // @ts-ignore
        Intercom('boot');
    }
}
