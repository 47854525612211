import BaseFormComponent from '../BaseFormComponent';
export class CheckboxComponent extends BaseFormComponent {
    constructor() {
        super(...arguments);
        this.disabled = false;
    }
    writeValue(obj) {
        this.value = [true, 1, 'true', '1'].indexOf(obj) >= 0;
        this.onChangeCallback(this.value);
    }
}
