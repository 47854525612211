import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import PackageModulesResponse from '../responses/package-modules-response';
import {HttpClient} from '@angular/common/http';
import OrdersResponse from '../responses/orders-response';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PackagesRepositoryService {

    constructor(private http: HttpClient) {
    }

    public getModules(packageCode: string): Observable<PackageModulesResponse> {
        return this.http.get<PackageModulesResponse>(`${environment.apiUrl}v1/packages/${packageCode}/modules`);
    }
}
