import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ofActionCompleted, ofActionSuccessful } from '@ngxs/store';
import { PushAlert } from '../../states/alerts.actions';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import { HttpErrorCode } from '../../api/http-error-code.enum';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SessionRegisterRequest } from 'src/app/states/auth.actions';
export class LoginRegisterPageComponent {
    constructor(store, router, actions, route, formBuilder, title) {
        this.store = store;
        this.router = router;
        this.actions = actions;
        this.route = route;
        this.formBuilder = formBuilder;
        this.title = title;
        /**
         * Określa czy aktualnie trwa próba zalogowania.
         */
        this.isLoading = false;
        this.formErrors = {};
        this.gc = new Subject();
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.title.setTitle('Register - Dataedo Account');
            this.initForm();
            this.initEvents();
            if (!this.form.value.email || !this.form.value.code) {
                yield this.redirectToHome();
            }
        });
    }
    initEvents() {
        this.actions.pipe(ofActionSuccessful(SessionRegisterRequest))
            .pipe(takeUntil(this.gc))
            .subscribe(action => this.onSuccessfulProfileChange(action));
        this.actions.pipe(ofActionCompleted(SessionRegisterRequest))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedProfileChange(event));
    }
    initForm() {
        this.form = this.formBuilder.group({
            email: this.route.snapshot.queryParams.email || '',
            code: this.route.snapshot.queryParams.code || '',
            firstName: '',
            lastName: '',
        });
    }
    redirectToHome() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Użytkownik wszedł bezpośrednio pod adres bez danych
            // potrzebnych do poprawnego wysłania formularza.
            const message = 'Operation available only with link sent via email.';
            this.store.dispatch(new PushAlert(message, { type: 'warning' }));
            yield this.router.navigateByUrl('/', {
                replaceUrl: true,
            });
        });
    }
    onSubmit(formData) {
        this.isLoading = true;
        this.formErrors = {};
        this.store.dispatch(new SessionRegisterRequest(formData.email, formData.code, formData.firstName, formData.lastName, this.gc));
    }
    onSuccessfulProfileChange(action) {
        // Wyświetlenie komunikatu o poprawnej rejestracji.
        const message = 'Your account has been successfully updated.';
        this.store.dispatch(new PushAlert(message, { type: 'success' }));
        this.router.navigateByUrl('/', { replaceUrl: true });
    }
    onCompletedProfileChange(event) {
        const response = ResponseHandlingHelper.parse(event.result);
        if (response.isError && response.error.status !== HttpErrorCode.Conflict) {
            // Wyświetlenie błędów formularza.
            this.formErrors = response.error.form;
            this.isLoading = false;
        }
        if (response.isError && response.error.status === HttpErrorCode.Conflict) {
            // Wyświetlenie komunikatu o błędzie podczas rejestracji.
            this.store.dispatch(new PushAlert(response.error.message, { type: 'error' }));
            // Przekierowanie do strony do wpisania kodu uwierzytelniającego.
            return this.router.navigate(['/'], { replaceUrl: true });
        }
    }
    ngOnDestroy() {
        this.gc.next();
        this.gc.complete();
    }
}
