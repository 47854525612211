import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-button-link',
    templateUrl: './button-link.component.html',
    styleUrls: ['./button-link.component.scss']
})
export class ButtonLinkComponent {

    @Input()
    public href = '';

    @Input()
    public route: any[];

    @Input()
    public tabindex = null;

    @Input()
    public disabled = false;

    @Input()
    public loading = false;

    @Input()
    public openInNewTab = false;

    @Input()
    public size: 'small' | 'default' = 'default';

    @Input()
    public theme: 'default' | 'primary' = 'default';

    /**
     * Name of the icon to show with button content.
     *
     * @see IconComponent
     */
    @Input()
    public icon?: string;

    constructor(private router: Router) {
    }

    public navigate(): boolean {
        if (this.route) {
            this.router.navigate(this.route);
            return false;
        }

        return true;
    }
}
