/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-menu-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../elements/stack-layout/stack-layout.component.ngfactory";
import * as i3 from "../../elements/stack-layout/stack-layout.component";
import * as i4 from "../../components/navbar/navbar.component.ngfactory";
import * as i5 from "../../components/navbar/navbar.component";
import * as i6 from "@ngxs/store";
import * as i7 from "../../helpers/directives/tabbed-links.directive";
import * as i8 from "../../elements/icon/icon.component.ngfactory";
import * as i9 from "../../elements/icon/icon.component";
import * as i10 from "@angular/common/http";
import * as i11 from "../../components/licenses-menu/licenses-menu.component.ngfactory";
import * as i12 from "../../components/licenses-menu/licenses-menu.component";
import * as i13 from "@angular/router";
import * as i14 from "./account-menu-page.component";
var styles_AccountMenuPageComponent = [i0.styles];
var RenderType_AccountMenuPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountMenuPageComponent, data: { "animation": [{ type: 7, name: "menu", definitions: [{ type: 0, name: "open", styles: { type: 6, styles: { left: 0 }, offset: null }, options: undefined }, { type: 0, name: "closed", styles: { type: 6, styles: { left: "-260px", display: "none" }, offset: null }, options: undefined }, { type: 1, expr: "open => closed", animation: [{ type: 4, styles: null, timings: "0.2s" }], options: null }, { type: 1, expr: "closed => open", animation: [{ type: 6, styles: { display: "block" }, offset: null }, { type: 4, styles: null, timings: "0.2s" }], options: null }], options: {} }, { type: 7, name: "overlay", definitions: [{ type: 0, name: "open", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "closed", styles: { type: 6, styles: { opacity: 0, display: "none" }, offset: null }, options: undefined }, { type: 1, expr: "open => closed", animation: [{ type: 4, styles: null, timings: "0.2s" }], options: null }, { type: 1, expr: "closed => open", animation: [{ type: 6, styles: { display: "block" }, offset: null }, { type: 4, styles: null, timings: "0.2s" }], options: null }], options: {} }] } });
export { RenderType_AccountMenuPageComponent as RenderType_AccountMenuPageComponent };
export function View_AccountMenuPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "app-stack-layout", [], [[4, "flexDirection", null]], null, null, i2.View_StackLayoutComponent_0, i2.RenderType_StackLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i3.StackLayoutComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "app-navbar", [["class", "navbar"]], null, null, null, i4.View_NavbarComponent_0, i4.RenderType_NavbarComponent)), i1.ɵdid(3, 245760, null, 0, i5.NavbarComponent, [i6.Store], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "a", [["class", "navbar-menu-toggle"], ["data-menu-toggle-btn", ""]], [[8, "tabIndex", 0], [1, "role", 0]], [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown.enter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onEnterKey($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.toggleMenu() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i7.TabbedLinksDirective, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-icon", [["icon", "menu"], ["size", "20px"]], [[4, "width", null], [4, "height", null], [4, "display", null], [4, "verticalAlign", null]], null, null, i8.View_IconComponent_0, i8.RenderType_IconComponent)), i1.ɵdid(7, 638976, null, 0, i9.IconComponent, [i1.ElementRef, i10.HttpClient], { icon: [0, "icon"], size: [1, "size"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 7, "app-stack-layout", [["direction", "row"]], [[4, "flexDirection", null]], null, null, i2.View_StackLayoutComponent_0, i2.RenderType_StackLayoutComponent)), i1.ɵdid(9, 49152, null, 0, i3.StackLayoutComponent, [], { direction: [0, "direction"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "app-licenses-menu", [["class", "menu"]], [[4, "width", null], [24, "@menu", 0]], null, null, i11.View_LicensesMenuComponent_0, i11.RenderType_LicensesMenuComponent)), i1.ɵdid(11, 245760, null, 0, i12.LicensesMenuComponent, [i6.Store, i6.Actions], null, null), (_l()(), i1.ɵeld(12, 0, null, 0, 3, "main", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "content-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(15, 212992, null, 0, i13.RouterOutlet, [i13.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 0, "div", [["class", "overlay"]], [[24, "@overlay", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { _ck(_v, 3, 0); var currVal_7 = "menu"; var currVal_8 = "20px"; _ck(_v, 7, 0, currVal_7, currVal_8); var currVal_10 = "row"; _ck(_v, 9, 0, currVal_10); _ck(_v, 11, 0); _ck(_v, 15, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).direction; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 5).tabindex; var currVal_2 = i1.ɵnov(_v, 5).role; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v, 7).size; var currVal_4 = i1.ɵnov(_v, 7).size; var currVal_5 = i1.ɵnov(_v, 7).display; var currVal_6 = i1.ɵnov(_v, 7).verticalAlign; _ck(_v, 6, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = i1.ɵnov(_v, 9).direction; _ck(_v, 8, 0, currVal_9); var currVal_11 = _co.menuWidth; var currVal_12 = (_co.menuOpened ? "open" : "closed"); _ck(_v, 10, 0, currVal_11, currVal_12); var currVal_13 = (_co.menuOpened ? "open" : "closed"); _ck(_v, 16, 0, currVal_13); }); }
export function View_AccountMenuPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-menu-page", [], null, null, null, View_AccountMenuPageComponent_0, RenderType_AccountMenuPageComponent)), i1.ɵdid(1, 245760, null, 0, i14.AccountMenuPageComponent, [i6.Store, i6.Actions, i13.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountMenuPageComponentNgFactory = i1.ɵccf("app-account-menu-page", i14.AccountMenuPageComponent, View_AccountMenuPageComponent_Host_0, {}, {}, []);
export { AccountMenuPageComponentNgFactory as AccountMenuPageComponentNgFactory };
