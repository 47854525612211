/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../spinner/spinner.component.ngfactory";
import * as i3 from "../spinner/spinner.component";
import * as i4 from "../icon/icon.component.ngfactory";
import * as i5 from "../icon/icon.component";
import * as i6 from "@angular/common/http";
import * as i7 from "@angular/common";
import * as i8 from "./button.component";
var styles_ButtonComponent = [i0.styles];
var RenderType_ButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
function View_ButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner", [["class", "loader"]], [[2, "small", null]], null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).fitToSize; _ck(_v, 0, 0, currVal_0); }); }
function View_ButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icon", [], [[4, "visibility", null], [4, "width", null], [4, "height", null], [4, "display", null], [4, "verticalAlign", null]], null, null, i4.View_IconComponent_0, i4.RenderType_IconComponent)), i1.ɵdid(1, 638976, null, 0, i5.IconComponent, [i1.ElementRef, i6.HttpClient], { icon: [0, "icon"], size: [1, "size"], theme: [2, "theme"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.icon; var currVal_6 = ((_co.size === "small") ? "14px" : "18px"); var currVal_7 = _co.iconTheme; _ck(_v, 1, 0, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loading ? "hidden" : "visible"); var currVal_1 = i1.ɵnov(_v, 1).size; var currVal_2 = i1.ɵnov(_v, 1).size; var currVal_3 = i1.ɵnov(_v, 1).display; var currVal_4 = i1.ɵnov(_v, 1).verticalAlign; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_ButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "button", [["class", "button"]], [[2, "is-loading", null], [1, "tabindex", 0], [2, "has-icon", null], [8, "disabled", 0]], null, null, null, null)), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(3, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonComponent_1)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonComponent_2)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_4 = "button"; var currVal_5 = _ck(_v, 3, 0, _co.size, _co.theme); _ck(_v, 2, 0, currVal_4, currVal_5); var currVal_6 = _co.loading; _ck(_v, 5, 0, currVal_6); var currVal_7 = _co.icon; _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; var currVal_1 = _co.tabindex; var currVal_2 = !!_co.icon; var currVal_3 = (_co.disabled || _co.loading); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_ButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button", [], null, null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i1.ɵdid(1, 49152, null, 0, i8.ButtonComponent, [], null, null)], null, null); }
var ButtonComponentNgFactory = i1.ɵccf("app-button", i8.ButtonComponent, View_ButtonComponent_Host_0, { tabindex: "tabindex", disabled: "disabled", loading: "loading", size: "size", theme: "theme", icon: "icon", iconTheme: "iconTheme" }, {}, ["*"]);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
