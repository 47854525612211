import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { takeUntil, tap } from 'rxjs/operators';
import { FetchQuote, FetchQuotes } from './quotes.actions';
import { Logout } from './auth.actions';
let QuotesState = class QuotesState {
    constructor(actions, quotesRepository) {
        this.actions = actions;
        this.quotesRepository = quotesRepository;
    }
    static quote(state) {
        return state.quote;
    }
    static quotes(state) {
        return state.quotes;
    }
    fetchQuotes(ctx, action) {
        let request = this.quotesRepository.listByAccountId(action.accountId)
            .pipe(tap(response => ctx.patchState({
            quotes: response.data.items
        })));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    fetchQuote(ctx, action) {
        let request = this.quotesRepository.findByAccountIdAndQuoteNo(action.accountId, action.quoteNo)
            .pipe(tap(response => ctx.patchState({
            quote: response.data
        })));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    logout(ctx, action) {
        ctx.patchState({
            quote: null,
            quotes: [],
        });
    }
};
tslib_1.__decorate([
    Action(FetchQuotes)
], QuotesState.prototype, "fetchQuotes", null);
tslib_1.__decorate([
    Action(FetchQuote)
], QuotesState.prototype, "fetchQuote", null);
tslib_1.__decorate([
    Action(Logout)
], QuotesState.prototype, "logout", null);
tslib_1.__decorate([
    Selector()
], QuotesState, "quote", null);
tslib_1.__decorate([
    Selector()
], QuotesState, "quotes", null);
QuotesState = tslib_1.__decorate([
    State({
        name: 'Quotes',
        defaults: {
            quote: null,
            quotes: [],
        }
    })
], QuotesState);
export { QuotesState };
