import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { takeUntil, tap } from 'rxjs/operators';
import { DownloadOfflineTrialLicense, FetchTrials } from './trials.actions';
import { Logout } from './auth.actions';
import ResponseHandlingHelper from '../helpers/response-handling-helper';
let TrialsState = class TrialsState {
    constructor(actions, trialsRepository) {
        this.actions = actions;
        this.trialsRepository = trialsRepository;
    }
    static trials(state) {
        return state.trials;
    }
    fetchTrials(ctx, action) {
        let request = this.trialsRepository.list()
            .pipe(tap(response => ctx.patchState({
            trials: response.data.items,
        })));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    downloadOfflineTrialLicense(ctx, action) {
        let request = this.trialsRepository.offlineLicense(action.trialId)
            .pipe(tap(response => {
            ResponseHandlingHelper.downloadFile(response, 'license_file.dkey');
        }));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    logout(ctx, action) {
        ctx.patchState({
            trials: [],
        });
    }
};
tslib_1.__decorate([
    Action(FetchTrials)
], TrialsState.prototype, "fetchTrials", null);
tslib_1.__decorate([
    Action(DownloadOfflineTrialLicense)
], TrialsState.prototype, "downloadOfflineTrialLicense", null);
tslib_1.__decorate([
    Action(Logout)
], TrialsState.prototype, "logout", null);
tslib_1.__decorate([
    Selector()
], TrialsState, "trials", null);
TrialsState = tslib_1.__decorate([
    State({
        name: 'Trials',
        defaults: {
            trials: [],
        },
    })
], TrialsState);
export { TrialsState };
