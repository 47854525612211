import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {ActionCompletion, Actions, ofActionCompleted, ofActionSuccessful, Select, Store} from '@ngxs/store';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {PushMenuActiveAccountId} from 'src/app/states/menu.actions';
import {Observable, Subject, timer} from 'rxjs';
import {AccountRolesState} from 'src/app/states/account-roles.state';
import {AccountRolesItemModelResponse, AccountRolesModelResponse} from 'src/app/api/responses/account-roles-response';
import {AssignAccountRole, FetchAccountRoles, RevokeAccountRole} from 'src/app/states/account-roles.actions';
import ResponseHandlingHelper from 'src/app/helpers/response-handling-helper';
import {AuthState} from 'src/app/states/auth.state';
import {AccountRoleEnum} from 'src/app/api/enums/account-role.enum';
import {PushAlert} from 'src/app/states/alerts.actions';
import {animate, style, transition, trigger} from '@angular/animations';
import CompareHelper from 'src/app/helpers/compare-helper';
import {HttpErrorCode} from 'src/app/api/http-error-code.enum';
import {Title} from '@angular/platform-browser';
import {ModalComponent} from '../../elements/modal/modal.component';

@Component({
    selector: 'app-account-roles-edit-page',
    templateUrl: './account-roles-edit-page.component.html',
    styleUrls: ['./account-roles-edit-page.component.scss'],
    animations: [
        trigger('tableRow', [
            transition(':leave', [
                animate(400, style({transform: 'translateX(60px)', opacity: 0}))
            ])
        ])
    ]
})
export class AccountRolesEditPageComponent implements OnInit, OnDestroy {

    public accountId: number;

    public formErrors: { [email: string]: { [key: string]: string[] } } = {};

    @Select(AccountRolesState.roles)
    public rolesListener: Observable<AccountRolesModelResponse>;
    public roles: AccountRolesModelResponse = null;

    @Select(AuthState.email)
    public userEmailListener: Observable<string>;
    public userEmail: string;

    public isLoading = true;
    public newRole: AccountRoleEnum|null = null;
    public newEmail: string = '';
    public isRevokingRole: { [email: string]: 'no' | 'pending' | 'awaiting-refresh' } = {};
    public isAssigningRole: { [email: string]: 'no' | 'pending' | 'awaiting-refresh' } = {};

    public availableRolesOptions = [
        {value: AccountRoleEnum.owner, text: 'Owner'},
        {value: AccountRoleEnum.admin, text: 'Admin'},
        {value: AccountRoleEnum.billing, text: 'Billing'},
    ];
    /**
     * Token służący do anulowania pobierania danych.
     */
    private cancellationToken = new Subject();
    private gc = new Subject();

    /**
     * Informuje o zmianie identyfikatora konta.
     *
     * Na sam koniec przed zniszczeniem komponentu wysyłana jest wartość "null".
     */
    private accountIdChanged = new Subject<number | null>();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private actions: Actions,
        private store: Store,
        private title: Title,
    ) {
    }

    public ngOnInit() {
        this.title.setTitle('Administration - Dataedo Account');
        this.initEvents();
    }

    private initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));

        this.userEmailListener
            .pipe(takeUntil(this.gc))
            .subscribe(email => this.userEmail = email);

        this.rolesListener
            .pipe(takeUntil(this.gc))
            .subscribe(roles => this.roles = roles);

        this.actions
            .pipe(ofActionCompleted(FetchAccountRoles))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedDataReload(event));

        this.actions
            .pipe(ofActionSuccessful(FetchAccountRoles))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onSuccessfulDataReload(event));

        this.actions
            .pipe(ofActionCompleted(AssignAccountRole))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedRoleAssign(event));

        this.actions
            .pipe(ofActionCompleted(RevokeAccountRole))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedRoleRevoke(event));
    }

    private onPathChange(params: Params) {
        const newAccountId = parseInt(params.accountId, 10);
        if (this.accountId !== newAccountId) {
            this.accountIdChanged.next(this.accountId);
            this.isAssigningRole = {};
            this.isRevokingRole = {};
        }

        this.accountId = newAccountId;
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));

        this.scheduleFetchData();
    }

    private scheduleFetchData() {
        this.cancellationToken.next();

        timer(300)
            .pipe(takeUntil(this.cancellationToken))
            .subscribe(() => {
                this.isLoading = true;
                this.store.dispatch(new FetchAccountRoles(this.accountId, this.cancellationToken));
            });
    }

    private async onCompletedDataReload(event: ActionCompletion<FetchAccountRoles, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);
        if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
            await this.router.navigate(['accounts', this.accountId]);
        }

        this.isLoading = false;
    }

    private onSuccessfulDataReload(event: ActionCompletion<FetchAccountRoles, Error>) {
        // Wyłączenie loaderów dla dodawania nowych ról.
        let anyRolesAssigned = false;
        for (const email of Object.keys(this.isAssigningRole)) {
            if (this.isAssigningRole[email] === 'awaiting-refresh') {
                delete (this.isAssigningRole[email]);
                anyRolesAssigned = true;
            }
        }

        // Wyłączenie loaderów dla usuwania aktualnej roli.
        let anyRolesRevoked = false;
        for (const email of Object.keys(this.isRevokingRole)) {
            if (this.isRevokingRole[email] === 'awaiting-refresh') {
                delete (this.isRevokingRole[email]);
                anyRolesRevoked = true;
            }
        }

        if (anyRolesAssigned && anyRolesRevoked) {
            this.store.dispatch(new PushAlert('Roles have been successfully updated.', {type: 'success'}));
        } else if (anyRolesAssigned) {
            this.store.dispatch(new PushAlert('Roles have been successfully assigned.', {type: 'success'}));
        } else if (anyRolesRevoked) {
            this.store.dispatch(new PushAlert('Roles have been successfully revoked.', {type: 'success'}));
        }

        this.newEmail = '';
        this.newRole = null;
        this.isLoading = false;
    }

    public trackById(index: number, role: AccountRolesItemModelResponse): number {
        return role.id;
    }

    public assignRole(email: string, role: AccountRoleEnum) {
        if (this.isAssigningRole[email] === 'pending' || this.isAssigningRole[email] === 'awaiting-refresh') {
            // Nie można wykonac operacji poniewaz dla tej roli jest juz wykonywana.
            return;
        }

        if (CompareHelper.areMailsSame(email, this.userEmail)) {
            if (!confirm('Are you sure you want to change your role? This operation cannot be undone.')) {
                // User not give consent to revoke his role.
                return;
            }
        }

        this.formErrors[email] = {};
        this.isAssigningRole[email] = 'pending';
        this.store.dispatch(new AssignAccountRole(this.accountId, role, email, this.accountIdChanged));
    }

    private onCompletedRoleAssign(event: ActionCompletion<AssignAccountRole, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);
        if (response.isError && response.error.status === HttpErrorCode.UnprocessableEntity) {
            this.formErrors[event.action.email] = response.error.form;
            this.isAssigningRole[event.action.email] = 'no';
        }

        if (this.isAssigningRole[event.action.email] === 'pending') {
            this.isAssigningRole[event.action.email] = 'awaiting-refresh';
            this.scheduleFetchData();
        }
    }

    public revokeRole(modal: ModalComponent, email: string) {
        if (this.isRevokingRole[email] === 'pending' || this.isRevokingRole[email] === 'awaiting-refresh') {
            // Nie można wykonac operacji poniewaz dla tej roli i tego adresu jest juz wykonywana.
            return;
        }

        modal.hide();
        this.isRevokingRole[email] = 'pending';
        this.store.dispatch(new RevokeAccountRole(this.accountId, email, this.accountIdChanged));
    }

    private onCompletedRoleRevoke(event: ActionCompletion<RevokeAccountRole, Error>) {
        if (this.isRevokingRole[event.action.email] === 'pending') {
            this.isRevokingRole[event.action.email] = 'awaiting-refresh';
        }

        this.scheduleFetchData();
    }

    public ngOnDestroy(): void {
        this.cancellationToken.next();
        this.cancellationToken.complete();

        this.accountIdChanged.next(null);
        this.accountIdChanged.complete();

        this.gc.next();
        this.gc.complete();
    }
}
