import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { AuthTokenRenewal, ConfirmLoginRequest, ConfirmOAuth2, FetchProfile, IntendedUrl, LoginRequest, LoginWithGoogle, LoginWithMicrosoft, Logout, SessionRegisterRequest, } from './auth.actions';
import { takeUntil, tap } from 'rxjs/operators';
import IntercomHelper from '../helpers/intercom-helper';
let AuthState = class AuthState {
    constructor(actions, oauth2Repository, sessionsRepository) {
        this.actions = actions;
        this.oauth2Repository = oauth2Repository;
        this.sessionsRepository = sessionsRepository;
    }
    static email(state) {
        return state.email;
    }
    static profile(state) {
        return state.profile;
    }
    static intendedUrl(state) {
        return state.intendedUrl;
    }
    static token(state) {
        return state.token;
    }
    static isAuthenticated(state) {
        // Nie sprawdzamy czy token jest ważny (pole expiresAt),
        // ponieważ jeśli nie jest ważny to API zwróci 401 i nastąpi
        // automatyczne i pełne wylogowanie za pomocą interceptora HTTP.
        // Pole expiresAt może być wykorzystane do poinformowania użytkownika
        // o tym, że jego sesja zaraz wygaśnie jeśli nie wykona żadnej akcji.
        return !!state.token;
    }
    loginRequest(ctx, action) {
        let request = this.sessionsRepository.create(action.email)
            .pipe(tap(_ => ctx.patchState({ email: action.email })));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    loginWithGoogle(ctx, action) {
        let request = this.oauth2Repository.loginWithGoogle();
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    loginWithMicrosoft(ctx, action) {
        let request = this.oauth2Repository.loginWithMicrosoft();
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    confirmLoginRequest(ctx, action) {
        let request = this.sessionsRepository
            .confirm(action.email, action.code, action.remember)
            .pipe(tap(response => {
            ctx.patchState({
                email: action.email,
                token: response.data.token,
                expiresAt: response.data.expires_at,
            });
            IntercomHelper.update({
                email: action.email,
                user_id: response.data.user_id,
            });
        }));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    confirmOAuth2(ctx, action) {
        ctx.patchState({
            email: action.email,
            token: action.token,
            expiresAt: action.expiresAt,
        });
        IntercomHelper.update({
            email: action.email,
            user_id: action.userId,
        });
    }
    registerRequest(ctx, action) {
        let request = this.sessionsRepository
            .register(action.email, action.code, action.firstName, action.lastName)
            .pipe(tap(response => ctx.patchState({
            email: action.email,
            token: response.data.token,
            expiresAt: response.data.expires_at,
        })));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    fetchProfile(ctx, action) {
        let request = this.sessionsRepository
            .profile()
            .pipe(tap(response => ctx.patchState({ profile: response })));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    authTokenRenewal(ctx, action) {
        ctx.patchState({ expiresAt: action.expiresAt });
    }
    intendedUrl(ctx, action) {
        ctx.patchState({ intendedUrl: action.url });
    }
    logout(ctx, action) {
        IntercomHelper.reboot();
        localStorage.clear();
        ctx.patchState({
            email: '',
            profile: null,
            token: null,
            expiresAt: null,
            intendedUrl: ctx.getState().intendedUrl,
        });
    }
};
tslib_1.__decorate([
    Action(LoginRequest)
], AuthState.prototype, "loginRequest", null);
tslib_1.__decorate([
    Action(LoginWithGoogle)
], AuthState.prototype, "loginWithGoogle", null);
tslib_1.__decorate([
    Action(LoginWithMicrosoft)
], AuthState.prototype, "loginWithMicrosoft", null);
tslib_1.__decorate([
    Action(ConfirmLoginRequest)
], AuthState.prototype, "confirmLoginRequest", null);
tslib_1.__decorate([
    Action(ConfirmOAuth2)
], AuthState.prototype, "confirmOAuth2", null);
tslib_1.__decorate([
    Action(SessionRegisterRequest)
], AuthState.prototype, "registerRequest", null);
tslib_1.__decorate([
    Action(FetchProfile)
], AuthState.prototype, "fetchProfile", null);
tslib_1.__decorate([
    Action(AuthTokenRenewal)
], AuthState.prototype, "authTokenRenewal", null);
tslib_1.__decorate([
    Action(IntendedUrl)
], AuthState.prototype, "intendedUrl", null);
tslib_1.__decorate([
    Action(Logout)
], AuthState.prototype, "logout", null);
tslib_1.__decorate([
    Selector()
], AuthState, "email", null);
tslib_1.__decorate([
    Selector()
], AuthState, "profile", null);
tslib_1.__decorate([
    Selector()
], AuthState, "intendedUrl", null);
tslib_1.__decorate([
    Selector()
], AuthState, "token", null);
tslib_1.__decorate([
    Selector()
], AuthState, "isAuthenticated", null);
AuthState = tslib_1.__decorate([
    State({
        name: 'Auth',
        defaults: {
            email: '',
            profile: null,
            token: null,
            expiresAt: null,
            intendedUrl: null,
        },
    })
], AuthState);
export { AuthState };
