/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./label.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./label.component";
var styles_LabelComponent = [i0.styles];
var RenderType_LabelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LabelComponent, data: {} });
export { RenderType_LabelComponent as RenderType_LabelComponent };
export function View_LabelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "label", [], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, [":\n"]))], null, null); }
export function View_LabelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-label", [], null, null, null, View_LabelComponent_0, RenderType_LabelComponent)), i1.ɵdid(1, 49152, null, 0, i2.LabelComponent, [], null, null)], null, null); }
var LabelComponentNgFactory = i1.ɵccf("app-label", i2.LabelComponent, View_LabelComponent_Host_0, {}, {}, ["*"]);
export { LabelComponentNgFactory as LabelComponentNgFactory };
