/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stack-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./stack-layout.component";
var styles_StackLayoutComponent = [i0.styles];
var RenderType_StackLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StackLayoutComponent, data: {} });
export { RenderType_StackLayoutComponent as RenderType_StackLayoutComponent };
export function View_StackLayoutComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_StackLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-stack-layout", [], [[4, "flexDirection", null]], null, null, View_StackLayoutComponent_0, RenderType_StackLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i2.StackLayoutComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).direction; _ck(_v, 0, 0, currVal_0); }); }
var StackLayoutComponentNgFactory = i1.ɵccf("app-stack-layout", i2.StackLayoutComponent, View_StackLayoutComponent_Host_0, { direction: "direction" }, {}, ["*"]);
export { StackLayoutComponentNgFactory as StackLayoutComponentNgFactory };
