import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {AuthState} from '../../states/auth.state';
import {PushAlert} from '../../states/alerts.actions';
import {AuthTokenRenewal, Logout} from '../../states/auth.actions';
import {HttpErrorCode} from 'src/app/api/http-error-code.enum';

// Type HttpRequest<any> and HttpEvent<any> generated by Angular CLI.
// As a result, the following rule apply:
// tslint:disable:no-any

@Injectable()
export class NotFoundInterceptor implements HttpInterceptor {

    private message = 'The resource has not been found.';

    constructor(
        private store: Store,
        private router: Router
    ) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === HttpErrorCode.NotFound) {
                this.handleError(error.error.message || this.message);
            }

            return throwError(error);
        }));
    }

    private handleError(message: string) {
        this.store.dispatch(new PushAlert(message, {type: 'warning'}));
    }
}
