import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {createPopper} from '@popperjs/core/lib/popper-lite.js';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow.js';
import flip from '@popperjs/core/lib/modifiers/flip.js';
import arrow from '@popperjs/core/lib/modifiers/arrow.js';
import {Instance} from '@popperjs/core';

@Component({
    selector: 'app-popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit, OnDestroy {
    /**
     * Describes the preferred placement of the tooltip.
     */
    @Input()
    public placement: 'auto'
        | 'auto-start'
        | 'auto-end'
        | 'top'
        | 'top-start'
        | 'top-end'
        | 'bottom'
        | 'bottom-start'
        | 'bottom-end'
        | 'right'
        | 'right-start'
        | 'right-end'
        | 'left'
        | 'left-start'
        | 'left-end' = 'auto';

    /**
     * Describes the positioning strategy to use. By default, it is absolute,
     * which in the simplest cases does not require repositioning of the popper.
     *
     * If your reference element is in a fixed container, use the fixed strategy.
     */
    @Input()
    public strategy: 'absolute' | 'fixed' = 'absolute';

    /**
     * Preferred tooltip popover width.
     */
    @Input()
    public width: 'auto' | string = 'auto';

    /**
     * Element for which we will display the tooltip.
     */
    @Input()
    public reference: HTMLElement;

    /**
     * Control whether tooltip is visible or not.
     */
    public visible = false;

    /**
     * Popper.js instance.
     *
     * @see https://popper.js.org/
     */
    private instance: Instance;

    /**
     * Pointer to element which stores tooltip popover content.
     */
    @ViewChild('container', {static: true})
    private container: ElementRef;

    private toggleHandler = () => this.toggle();
    private toggleKeyboardHandler = (event: KeyboardEvent) => this.onKey(event);

    public ngOnInit(): void {
        this.instance = createPopper(this.reference, this.container.nativeElement, {
            placement: this.placement,
            modifiers: [preventOverflow, flip, arrow],
        });

        this.bindNativeEvents();
    }

    private bindNativeEvents() {
        this.reference.addEventListener('click', this.toggleHandler);
        this.reference.addEventListener('keydown', this.toggleKeyboardHandler);
    }

    private unbindNativeEvents() {
        this.reference.removeEventListener('click', this.toggleHandler);
        this.reference.removeEventListener('keydown', this.toggleKeyboardHandler);
    }

    @HostListener('document:click', ['$event'])
    public onDocumentClick(event: MouseEvent) {
        if (!this.visible) {
            // Element niewidoczny, ignoruj wywołanie.
            return;
        }

        let node = event.target as HTMLElement;
        while (node) {
            if (node === this.reference || node === this.container.nativeElement) {
                return;
            }

            node = node.parentNode as HTMLElement;
        }

        // Neither reference nor container was clicked.
        this.hide();
    }

    public onKey(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.toggle();
        }
    }

    public toggle() {
        if (this.visible) {
            this.hide();
        } else {
            this.show();
        }
    }

    public show() {
        this.visible = true;
        this.instance.update();
    }

    public hide() {
        this.visible = false;
    }

    public ngOnDestroy(): void {
        this.unbindNativeEvents();
        this.instance.destroy();
    }
}

