import {Subject} from 'rxjs';

export class FetchTrials {
    public static readonly type = '[Trials] FetchTrials';

    constructor(public cancellationToken?: Subject<any>) {
    }

}

export class DownloadOfflineTrialLicense {
    public static readonly type = '[Trials] DownloadOfflineTrialLicense';

    constructor(public trialId: number, public cancellationToken?: Subject<any>) {
    }
}
