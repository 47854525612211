import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ofActionCompleted, ofActionSuccessful, Select } from '@ngxs/store';
import { PushMenuActiveAccountId } from 'src/app/states/menu.actions';
import { Subject, timer } from 'rxjs';
import { AccountRolesState } from 'src/app/states/account-roles.state';
import { AssignAccountRole, FetchAccountRoles, RevokeAccountRole } from 'src/app/states/account-roles.actions';
import ResponseHandlingHelper from 'src/app/helpers/response-handling-helper';
import { AuthState } from 'src/app/states/auth.state';
import { AccountRoleEnum } from 'src/app/api/enums/account-role.enum';
import { PushAlert } from 'src/app/states/alerts.actions';
import CompareHelper from 'src/app/helpers/compare-helper';
import { HttpErrorCode } from 'src/app/api/http-error-code.enum';
export class AccountRolesEditPageComponent {
    constructor(router, route, actions, store, title) {
        this.router = router;
        this.route = route;
        this.actions = actions;
        this.store = store;
        this.title = title;
        this.formErrors = {};
        this.roles = null;
        this.isLoading = true;
        this.newRole = null;
        this.newEmail = '';
        this.isRevokingRole = {};
        this.isAssigningRole = {};
        this.availableRolesOptions = [
            { value: AccountRoleEnum.owner, text: 'Owner' },
            { value: AccountRoleEnum.admin, text: 'Admin' },
            { value: AccountRoleEnum.billing, text: 'Billing' },
        ];
        /**
         * Token służący do anulowania pobierania danych.
         */
        this.cancellationToken = new Subject();
        this.gc = new Subject();
        /**
         * Informuje o zmianie identyfikatora konta.
         *
         * Na sam koniec przed zniszczeniem komponentu wysyłana jest wartość "null".
         */
        this.accountIdChanged = new Subject();
    }
    ngOnInit() {
        this.title.setTitle('Administration - Dataedo Account');
        this.initEvents();
    }
    initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));
        this.userEmailListener
            .pipe(takeUntil(this.gc))
            .subscribe(email => this.userEmail = email);
        this.rolesListener
            .pipe(takeUntil(this.gc))
            .subscribe(roles => this.roles = roles);
        this.actions
            .pipe(ofActionCompleted(FetchAccountRoles))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedDataReload(event));
        this.actions
            .pipe(ofActionSuccessful(FetchAccountRoles))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onSuccessfulDataReload(event));
        this.actions
            .pipe(ofActionCompleted(AssignAccountRole))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedRoleAssign(event));
        this.actions
            .pipe(ofActionCompleted(RevokeAccountRole))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedRoleRevoke(event));
    }
    onPathChange(params) {
        const newAccountId = parseInt(params.accountId, 10);
        if (this.accountId !== newAccountId) {
            this.accountIdChanged.next(this.accountId);
            this.isAssigningRole = {};
            this.isRevokingRole = {};
        }
        this.accountId = newAccountId;
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));
        this.scheduleFetchData();
    }
    scheduleFetchData() {
        this.cancellationToken.next();
        timer(300)
            .pipe(takeUntil(this.cancellationToken))
            .subscribe(() => {
            this.isLoading = true;
            this.store.dispatch(new FetchAccountRoles(this.accountId, this.cancellationToken));
        });
    }
    onCompletedDataReload(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = ResponseHandlingHelper.parse(event.result);
            if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
                yield this.router.navigate(['accounts', this.accountId]);
            }
            this.isLoading = false;
        });
    }
    onSuccessfulDataReload(event) {
        // Wyłączenie loaderów dla dodawania nowych ról.
        let anyRolesAssigned = false;
        for (const email of Object.keys(this.isAssigningRole)) {
            if (this.isAssigningRole[email] === 'awaiting-refresh') {
                delete (this.isAssigningRole[email]);
                anyRolesAssigned = true;
            }
        }
        // Wyłączenie loaderów dla usuwania aktualnej roli.
        let anyRolesRevoked = false;
        for (const email of Object.keys(this.isRevokingRole)) {
            if (this.isRevokingRole[email] === 'awaiting-refresh') {
                delete (this.isRevokingRole[email]);
                anyRolesRevoked = true;
            }
        }
        if (anyRolesAssigned && anyRolesRevoked) {
            this.store.dispatch(new PushAlert('Roles have been successfully updated.', { type: 'success' }));
        }
        else if (anyRolesAssigned) {
            this.store.dispatch(new PushAlert('Roles have been successfully assigned.', { type: 'success' }));
        }
        else if (anyRolesRevoked) {
            this.store.dispatch(new PushAlert('Roles have been successfully revoked.', { type: 'success' }));
        }
        this.newEmail = '';
        this.newRole = null;
        this.isLoading = false;
    }
    trackById(index, role) {
        return role.id;
    }
    assignRole(email, role) {
        if (this.isAssigningRole[email] === 'pending' || this.isAssigningRole[email] === 'awaiting-refresh') {
            // Nie można wykonac operacji poniewaz dla tej roli jest juz wykonywana.
            return;
        }
        if (CompareHelper.areMailsSame(email, this.userEmail)) {
            if (!confirm('Are you sure you want to change your role? This operation cannot be undone.')) {
                // User not give consent to revoke his role.
                return;
            }
        }
        this.formErrors[email] = {};
        this.isAssigningRole[email] = 'pending';
        this.store.dispatch(new AssignAccountRole(this.accountId, role, email, this.accountIdChanged));
    }
    onCompletedRoleAssign(event) {
        const response = ResponseHandlingHelper.parse(event.result);
        if (response.isError && response.error.status === HttpErrorCode.UnprocessableEntity) {
            this.formErrors[event.action.email] = response.error.form;
            this.isAssigningRole[event.action.email] = 'no';
        }
        if (this.isAssigningRole[event.action.email] === 'pending') {
            this.isAssigningRole[event.action.email] = 'awaiting-refresh';
            this.scheduleFetchData();
        }
    }
    revokeRole(modal, email) {
        if (this.isRevokingRole[email] === 'pending' || this.isRevokingRole[email] === 'awaiting-refresh') {
            // Nie można wykonac operacji poniewaz dla tej roli i tego adresu jest juz wykonywana.
            return;
        }
        modal.hide();
        this.isRevokingRole[email] = 'pending';
        this.store.dispatch(new RevokeAccountRole(this.accountId, email, this.accountIdChanged));
    }
    onCompletedRoleRevoke(event) {
        if (this.isRevokingRole[event.action.email] === 'pending') {
            this.isRevokingRole[event.action.email] = 'awaiting-refresh';
        }
        this.scheduleFetchData();
    }
    ngOnDestroy() {
        this.cancellationToken.next();
        this.cancellationToken.complete();
        this.accountIdChanged.next(null);
        this.accountIdChanged.complete();
        this.gc.next();
        this.gc.complete();
    }
}
tslib_1.__decorate([
    Select(AccountRolesState.roles)
], AccountRolesEditPageComponent.prototype, "rolesListener", void 0);
tslib_1.__decorate([
    Select(AuthState.email)
], AccountRolesEditPageComponent.prototype, "userEmailListener", void 0);
