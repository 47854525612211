/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popover.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./popover.component";
var styles_PopoverComponent = [i0.styles];
var RenderType_PopoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopoverComponent, data: {} });
export { RenderType_PopoverComponent as RenderType_PopoverComponent };
export function View_PopoverComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 2, "div", [["class", "popper"]], [[4, "visibility", null], [4, "width", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["data-popper-arrow", ""]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible ? "visible" : "hidden"); var currVal_1 = _co.width; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_PopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popover", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PopoverComponent_0, RenderType_PopoverComponent)), i1.ɵdid(1, 245760, null, 0, i2.PopoverComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopoverComponentNgFactory = i1.ɵccf("app-popover", i2.PopoverComponent, View_PopoverComponent_Host_0, { placement: "placement", strategy: "strategy", width: "width", reference: "reference" }, {}, ["*"]);
export { PopoverComponentNgFactory as PopoverComponentNgFactory };
