import {Action, Actions, Selector, State, StateContext} from '@ngxs/store';
import {takeUntil, tap} from 'rxjs/operators';
import {FetchOrders} from './orders.actions';
import {Logout} from './auth.actions';
import {AccountSubscriptionModelResponse} from 'src/app/api/responses/account-subscription.response';
import {AccountsRepositoryService} from 'src/app/api/repositories/accounts-repository.service';
import {FetchAccountBilling, FetchAccountSubscription} from 'src/app/states/accounts.actions';
import {AccountBillingModelResponse} from 'src/app/api/responses/account-billing-response';
import {CountriesModelResponse} from 'src/app/api/responses/countries-response';
import {CountriesRepositoryService} from 'src/app/api/repositories/countries-repository.service';
import {FetchCountries} from 'src/app/states/countries.actions';

interface CountriesStateModel {
    glossary?: CountriesModelResponse[];
}

@State<CountriesStateModel>({
    name: 'Countries',
    defaults: {
        glossary: [],
    }
})
export class CountriesState {
    constructor(
        private actions: Actions,
        private countriesRepository: CountriesRepositoryService
    ) {
    }

    @Selector()
    public static glossary(state: CountriesStateModel): CountriesModelResponse[] {
        return state.glossary;
    }

    @Action(FetchCountries)
    public fetchCountries(ctx: StateContext<CountriesStateModel>, action: FetchCountries) {
        let request = this.countriesRepository.list()
            .pipe(tap(response => ctx.patchState({
                glossary: response.data
            })));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }
}
