import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ActionCompletion, Actions, ofActionCompleted, Select, Store} from '@ngxs/store';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import {FetchEducationalLicenses} from '../../states/educational-licenses.actions';
import {EducationalLicensesState} from '../../states/educational-licenses.state';
import {EducationalLicensesModelResponse} from '../../api/responses/educational-licenses-response';
import {PushMenuActiveAccountId} from 'src/app/states/menu.actions';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-educational-licenses-page',
  templateUrl: './educational-licenses-page.component.html',
  styleUrls: ['./educational-licenses-page.component.scss']
})
export class EducationalLicensesPageComponent implements OnInit, OnDestroy {

    public educationalLicenseUrl = `${environment.websiteUrl}education-licenses`;

    @Select(EducationalLicensesState.licenses)
    public licensesListener: Observable<EducationalLicensesModelResponse[]>;

    public licenses: EducationalLicensesModelResponse[] = [];

    /**
     * Określa czy aktualnie trwa pobieranie danych.
     */
    public isLoading = false;

    private gc = new Subject();

    /**
     * Token służący do anulowania pobierania danych.
     */
    private cancellationToken = new Subject();

    constructor(
        private store: Store,
        private title: Title,
        private actions: Actions
    ) {
    }

    public ngOnInit() {
        this.title.setTitle('Educational licenses - Dataedo Account');
        this.initEvents();
        this.fetchData();
    }

    private fetchData() {
        this.isLoading = true;

        this.store.dispatch(new PushMenuActiveAccountId(null));
        this.store.dispatch(new FetchEducationalLicenses(this.cancellationToken));
    }

    private initEvents() {
        this.licensesListener
            .pipe(takeUntil(this.gc))
            .subscribe(licenses => this.licenses = licenses);

        this.actions
            .pipe(ofActionCompleted(FetchEducationalLicenses))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchLicenses(event));
    }

    private onCompletedFetchLicenses(event: ActionCompletion<FetchEducationalLicenses, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);

        this.isLoading = false;
    }

    public trackLicenseById(index: number, license: EducationalLicensesModelResponse): number {
        return license.id;
    }

    public ngOnDestroy(): void {
        this.cancellationToken.next();
        this.cancellationToken.complete();

        this.gc.next();
        this.gc.complete();
    }

}
