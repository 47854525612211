import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import TrialsResponse from '../responses/trials-response';
import FormDataHelper from '../../helpers/form-data-helper';
import ConvertLegacyLicenseResponse from '../responses/convert-legacy-license.response';

@Injectable({
    providedIn: 'root'
})
export class LicensesRepositoryService {
    constructor(private http: HttpClient) {
    }

    public convertLicense(email: string, key: string): Observable<ConvertLegacyLicenseResponse> {
        return this.http.post<ConvertLegacyLicenseResponse>(
            `${environment.apiUrl}v1/licenses/convert-legacy`,
            FormDataHelper.convertToMultipart({email, key})
        );
    }
}
