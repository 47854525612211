<app-stack-layout class="page-content">
    <app-stack-layout class="header" direction="row">
        <app-tooltip [reference]="back">Back to orders</app-tooltip>
        <a #back [routerLink]="['/accounts', accountId, 'orders']" class="back">
            <app-icon icon="left"></app-icon>
        </a>

        <h1>Order {{ orderNo }}</h1>
    </app-stack-layout>

    <app-empty class="fullsize mt-lg" *ngIf="!isLoading && !order">
        <app-stack-layout class="text-center">
            <h2>Order not found</h2>
            <p class="mt-sm">We didn't find the {{ orderNo }} order.</p>
            <div class="mt-md">
                <a routerLink="/orders">
                    &larr; Back to orders
                </a>
            </div>
        </app-stack-layout>
    </app-empty>
    <ng-container *ngIf="order && order.order_no === orderNo">
        <div class="overflow-x mt-xl">
            <table>
                <colgroup>
                    <col style="width: 25%;">
                    <col>
                </colgroup>
                <tr>
                    <th class="text-no-wrap">Order no:</th>
                    <td>{{ orderNo }}</td>
                </tr>
                <tr>
                    <th class="text-no-wrap">Order date:</th>
                    <td>
                        <app-timestamp [timestamp]="order.ordered_at"></app-timestamp>
                    </td>
                </tr>
                <tr *ngIf="order.subscriptionStart || order.subscriptionEnd">
                    <th class="text-no-wrap">Period:</th>
                    <td class="text-no-wrap">
                        <span *ngIf="order.subscriptionStart">
                            <app-timestamp [timestamp]="order.subscriptionStart"></app-timestamp>
                        </span>
                        <span *ngIf="order.subscriptionEnd">
                            - <app-timestamp [timestamp]="order.subscriptionEnd"></app-timestamp>
                        </span>
                    </td>
                </tr>
                <tr>
                    <th class="text-no-wrap">Contact:</th>
                    <td>
                        {{ order.contact.fullName }}
                        <div *ngIf="order.contact.email">
                            <a href="mailto:{{ order.contact.email }}">
                                {{ order.contact.email }}
                            </a>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="order.ref_no">
                    <th class="text-no-wrap">PO/ref no:</th>
                    <td>{{ order.ref_no }}</td>
                </tr>
                <tr>
                    <th class="text-no-wrap">Payment Status:</th>
                    <td>
                        <app-badge [color]="order.status.color">{{ order.status.text }}</app-badge>
                    </td>
                </tr>
                <tr>
                    <th class="text-no-wrap">Subscr. Status:</th>
                    <td>
                        <app-badge color="success" *ngIf="order.subscriptionActive">Active</app-badge>
                        <app-badge color="lightgray" *ngIf="!order.subscriptionActive">Inactive</app-badge>
                    </td>
                </tr>
                <tr *ngIf="order.payment_method">
                    <th class="text-no-wrap">Payment method:</th>
                    <td>{{ order.payment_method }}</td>
                </tr>
                <tr *ngIf="order.rebate">
                    <th class="text-no-wrap">Discount:</th>
                    <td>{{ order.rebate }}% {{ order.coupon_code}}</td>
                </tr>
                <tr *ngIf="order.amount_due">
                    <th class="text-no-wrap">Amount due:</th>
                    <td>{{ order.amount_due | moneyFormat:order.currency }}</td>
                </tr>
                <tr *ngIf="order.invoice_no">
                    <th class="text-no-wrap">Invoice:</th>
                    <td>
                        <app-document [name]="order.invoice_no"
                                      [fileUrl]="order._invoice_file_url">
                        </app-document>
                    </td>
                </tr>
            </table>
        </div>

        <app-stack-layout class="mt-xl">
            <h2>Products</h2>
            <div class="overflow-x mt-sm">
                <table>
                    <tr>
                        <th>Product</th>
                        <th>Period</th>
                        <th></th>
                        <th class="text-right" *ngIf="order._show_prices">Price {{ order.currency }}</th>
                        <th class="text-right">Qty</th>
                        <th class="text-right" *ngIf="order._show_prices">Subtotal {{ order.currency }}</th>
                    </tr>
                    <tr *ngFor="let line of order.lines">
                        <td>
                            {{ line.name }}
                            <span #expired *ngIf="line.expires_at && line.expires_at < now">
                                <app-badge color="error">Expired</app-badge>
                                <app-tooltip [reference]="expired">
                                    <app-timestamp [timestamp]="line.expires_at"></app-timestamp>
                                </app-tooltip>
                            </span>
                        </td>
                        <td class="text-no-wrap">
                            <span *ngIf="line.subscriptionStart || order.subscriptionStart">
                                <app-timestamp [timestamp]="line.subscriptionStart || order.subscriptionStart"></app-timestamp>
                            </span>
                            <span *ngIf="line.subscriptionEnd || order.subscriptionEnd">
                                - <app-timestamp [timestamp]="line.subscriptionEnd || order.subscriptionEnd"></app-timestamp>
                            </span>
                        </td>
                        <td>
                            <app-license-key *ngIf="line.license_key" [licenseKey]="line.license_key"></app-license-key>
                        </td>
                        <td class="text-right" *ngIf="order._show_prices">
                            {{ line.price | moneyFormat:line.currency }}
                        </td>
                        <td class="text-right">{{ line.quantity }}</td>
                        <td class="text-right" *ngIf="order._show_prices">
                            {{ line.amount | moneyFormat:line.currency:'-' }}
                        </td>
                    </tr>
                    <tr *ngIf="order._show_prices">
                        <td colspan="5">
                            <b>Grand Total</b>
                        </td>
                        <td class="text-right">
                            <b>{{ order.amount | moneyFormat:order.currency:'-' }}</b>
                        </td>
                    </tr>
                </table>
            </div>

            <small class="mt-sm">Prices are net of all taxes. Purchaser is responsible for paying all local taxes (e.g.
                VAT) imposed by the law of the purchaser's home country.
            </small>

            <div *ngIf="order._pay_with_paypal" class="mt-lg">
                <div>
                    <a [href]="order._pay_with_paypal" target="_blank">
                        Pay with PayPal
                        <app-icon icon="external-link" size="16px"></app-icon>
                    </a>
                </div>

                <div class="mt-xs">
                    <a #payWithBankTransfer tabindex="0">Pay with bank transfer</a>

                    <app-modal #payWithBankTransferModal [reference]="payWithBankTransfer" width="600px">
                        <app-stack-layout modalHeader>
                            <h2>Pay with bank transfer</h2>
                            <small class="mt-xs">Delivery can take up to 5 days.</small>
                        </app-stack-layout>

                        <app-stack-layout>
                            <div class="overflow-x">
                                <table>
                                    <colgroup>
                                        <col style="width: 25%;">
                                        <col>
                                    </colgroup>
                                    <tr>
                                        <th class="text-no-wrap text-top">Acc no:</th>
                                        <td>
                                            <app-copy-to-clipboard text="PL 13 1140 2004 0000 3912 0282 4035">
                                            </app-copy-to-clipboard>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="text-no-wrap text-top">BIC/SWIFT:</th>
                                        <td>BREXPLPWMBK</td>
                                    </tr>
                                    <tr>
                                        <th class="text-no-wrap text-top">Sort code:</th>
                                        <td>11402004</td>
                                    </tr>
                                    <tr>
                                        <th class="text-no-wrap text-top">Bank:</th>
                                        <td>
                                            <div>mBank S.A. formerly Bre Bank S.A.</div>
                                            <div>(Retail Banking)</div>
                                            <div>Lodz</div>
                                            <div>Skrytka Pocztowa 2108</div>
                                            <div>90-959 Lodz 2</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="text-no-wrap text-top">Recipient:</th>
                                        <td>
                                            <div><b>Dataedo Sp. z o.o.</b></div>
                                            <div>Wały Piastowskie 1/1104</div>
                                            <div>80-855, Gdańsk</div>
                                            <div>Poland, EU</div>
                                            <div>VAT: PL 583 31 63 875</div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </app-stack-layout>

                        <div class="text-right" modalFooter>
                            <app-button (click)="payWithBankTransferModal.hide()">Close</app-button>
                        </div>
                    </app-modal>
                </div>
            </div>
        </app-stack-layout>

        <div class="addresses"  *ngIf="order.billing_address || order.license_holder_address">
            <app-stack-layout *ngIf="order.billing_address" class="address mt-xl">
                <h2>Billing Address</h2>
                <div class="overflow-x mt-sm">
                    <div>{{ order.billing_address.company }}</div>
                    <div>{{ order.billing_address.address }}</div>
                    <div *ngIf="order.billing_address.address2">{{ order.billing_address.address2 }}</div>
                    <div>{{ order.billing_address.city }} {{ order.billing_address.postal_code }}</div>
                    <div>{{ order.billing_address.state }}</div>
                    <div>{{ order.billing_address.country }}</div>
                </div>
            </app-stack-layout>

            <app-stack-layout *ngIf="order.license_holder_address" class="address mt-xl">
                <h2>License Holder</h2>
                <div class="overflow-x mt-sm">
                    <div>{{ order.license_holder_address.company }}</div>
                    <div>{{ order.license_holder_address.address }}</div>
                    <div *ngIf="order.license_holder_address.address2">{{ order.license_holder_address.address2 }}</div>
                    <div>{{ order.license_holder_address.city }} {{ order.billing_address.postal_code }}</div>
                    <div>{{ order.license_holder_address.state }}</div>
                    <div>{{ order.license_holder_address.country }}</div>
                </div>
            </app-stack-layout>
        </div>

        <app-page-bottom-space></app-page-bottom-space>
    </ng-container>
    <app-spinner *ngIf="isLoading" class="fullsize py-lg"></app-spinner>
</app-stack-layout>
