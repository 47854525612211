<app-stack-layout>
    <h1>Billing & Payment</h1>

    <ng-container *ngIf="billing && billing.accountId === accountId">
        <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" class="mt-xl">
            <table>
                <tr>
                    <th class="text-top">Company:</th>
                    <td class="text-no-wrap text-top">
                        <app-input name="company" formControlName="company" size="small"></app-input>
                        <div *ngIf="formErrors.company">
                            <app-error>{{ formErrors.company[0] }}</app-error>
                        </div>
                    </td>
                    <td class="table-fill-column"></td>
                </tr>
                <tr>
                    <th class="text-top">Address:</th>
                    <td class="text-top">
                        <div class="text-no-wrap">
                            <app-input name="address" formControlName="address" size="small"></app-input>
                            <div *ngIf="formErrors.address">
                                <app-error>{{ formErrors.address[0] }}</app-error>
                            </div>
                        </div>

                        <div class="text-no-wrap mt-sm">
                            <app-input name="address2" formControlName="address2" size="small"></app-input>
                            <div *ngIf="formErrors.address2">
                                <app-error>{{ formErrors.address2[0] }}</app-error>
                            </div>
                        </div>
                    </td>
                    <td class="table-fill-column"></td>
                </tr>
                <tr>
                    <th class="text-top">Postal code:</th>
                    <td class="text-no-wrap text-top">
                        <app-input name="postalCode" formControlName="postalCode" size="small"></app-input>
                        <div *ngIf="formErrors.postalCode">
                            <app-error>{{ formErrors.postalCode[0] }}</app-error>
                        </div>
                    </td>
                    <td class="table-fill-column"></td>
                </tr>
                <tr>
                    <th class="text-top">City:</th>
                    <td class="text-no-wrap text-top">
                        <app-input name="city" formControlName="city" size="small"></app-input>
                        <div *ngIf="formErrors.city">
                            <app-error>{{ formErrors.city[0] }}</app-error>
                        </div>
                    </td>
                    <td class="table-fill-column"></td>
                </tr>
                <tr>
                    <th class="text-top">State:</th>
                    <td class="text-no-wrap text-top">
                        <app-input name="state" formControlName="state" size="small"></app-input>
                        <div *ngIf="formErrors.state">
                            <app-error>{{ formErrors.state[0] }}</app-error>
                        </div>
                    </td>
                    <td class="table-fill-column"></td>
                </tr>
                <tr>
                    <th class="text-top">Country:</th>
                    <td class="text-no-wrap text-top">
                        <app-select name="country" formControlName="country" size="small"
                                    [hasEmptyValue]="true"
                                    [options]="countriesOptions"
                                    [loading]="countriesOptions?.length === 0 && isLoadingCountries"></app-select>
                        <div *ngIf="formErrors.country">
                            <app-error>{{ formErrors.country[0] }}</app-error>
                        </div>
                    </td>
                    <td class="table-fill-column"></td>
                </tr>
                <tr>
                    <th class="text-top">VAT ID:</th>
                    <td class="text-no-wrap text-top">
                        <app-input name="vatID" formControlName="vatID" size="small"></app-input>
                        <div *ngIf="formErrors.vatID">
                            <app-error>{{ formErrors.vatID[0] }}</app-error>
                        </div>
                    </td>
                    <td class="table-fill-column"></td>
                </tr>
                <tr>
                    <th></th>
                    <td>
                        <app-button theme="primary" class="mr-sm"
                                    [loading]="isFormProcessing"
                                    (click)="onSubmit(form.value)">
                            Save changes
                        </app-button>
                        <app-button-link [route]="['/accounts', accountId, 'billing']">
                            Cancel
                        </app-button-link>
                    </td>
                    <td class="table-fill-column"></td>
                </tr>
            </table>
        </form>

        <app-page-bottom-space></app-page-bottom-space>
    </ng-container>
    <app-spinner class="fullsize" *ngIf="isLoadingBilling"></app-spinner>
</app-stack-layout>
