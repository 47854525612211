import { environment } from 'src/environments/environment';
import FormDataHelper from '../../helpers/form-data-helper';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SessionsRepositoryService {
    constructor(http) {
        this.http = http;
    }
    profile() {
        return this.http.get(`${environment.apiUrl}v1/profile`);
    }
    create(email) {
        return this.http.post(`${environment.apiUrl}v1/sessions`, FormDataHelper.convertToMultipart({ email, service: 'PORTAL' }));
    }
    confirm(email, code, remember) {
        return this.http.post(`${environment.apiUrl}v1/sessions/confirm`, FormDataHelper.convertToMultipart({ email, code, remember }));
    }
    register(email, code, firstName, lastName) {
        return this.http.post(`${environment.apiUrl}v1/sessions/register`, FormDataHelper.convertToMultipart({ email, code, firstName, lastName }));
    }
    list() {
        return this.http.get(`${environment.apiUrl}v1/sessions`);
    }
    revoke(sessionId) {
        return this.http.delete(`${environment.apiUrl}v1/sessions/${sessionId}/revoke`);
    }
}
SessionsRepositoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionsRepositoryService_Factory() { return new SessionsRepositoryService(i0.ɵɵinject(i1.HttpClient)); }, token: SessionsRepositoryService, providedIn: "root" });
