import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { Logout } from './auth.actions';
import { FetchSessions, RevokeSession } from './sessions.actions';
let SessionsState = class SessionsState {
    constructor(actions, sessionsRepository) {
        this.actions = actions;
        this.sessionsRepository = sessionsRepository;
    }
    static sessions(state) {
        return state.sessions;
    }
    fetchSessions(ctx, action) {
        let request = this.sessionsRepository.list()
            .pipe(tap(response => ctx.patchState({
            sessions: response.data.sessions
        })));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    revokeSession(ctx, action) {
        return this.sessionsRepository.revoke(action.sessionId)
            .pipe(mergeMap(() => ctx.dispatch(new FetchSessions(action.cancellationToken))));
    }
    logout(ctx, action) {
        ctx.patchState({
            sessions: [],
        });
    }
};
tslib_1.__decorate([
    Action(FetchSessions)
], SessionsState.prototype, "fetchSessions", null);
tslib_1.__decorate([
    Action(RevokeSession)
], SessionsState.prototype, "revokeSession", null);
tslib_1.__decorate([
    Action(Logout)
], SessionsState.prototype, "logout", null);
tslib_1.__decorate([
    Selector()
], SessionsState, "sessions", null);
SessionsState = tslib_1.__decorate([
    State({
        name: 'Sessions',
        defaults: {
            sessions: [],
        }
    })
], SessionsState);
export { SessionsState };
