import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

    @Input()
    public tabindex = null;

    @Input()
    public disabled = false;

    @Input()
    public loading = false;

    @Input()
    public size: 'small' | 'default' = 'default';

    @Input()
    public theme: 'default' | 'primary' = 'default';

    /**
     * Name of the icon to show with button content.
     *
     * @see IconComponent
     */
    @Input()
    public icon?: string;

    /**
     * Icon theme passed to the icon component.
     *
     * @see IconComponent
     */
    @Input()
    public iconTheme?: 'simple-small'|'color' = 'simple-small';

}
