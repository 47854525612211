<header>
    <a class="logo" [href]="websiteUrl" target="_blank">
        <img src="assets/dataedo.svg" height="24">
    </a>
</header>

<div class="content">
    <div class="banner" *ngIf="showWelcome">
        <h1>Welcome to Dataedo Account</h1>

        <p class="mt-md">
            Dataedo Account is a panel where you can access your purchases and invoices, and manage your subscriptions and users.
        </p>
    </div>

    <div class="box">
        <div class="box-content" style="width: 325px;">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<footer class="text-small">
    &copy; {{ today|date:'yyyy' }} Dataedo sp. z o.o., <a [href]="privacyPolicyUrl" target="_blank">Privacy Policy</a>
</footer>
