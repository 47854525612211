/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logout-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../elements/stack-layout/stack-layout.component.ngfactory";
import * as i3 from "../../elements/stack-layout/stack-layout.component";
import * as i4 from "../../elements/spinner/spinner.component.ngfactory";
import * as i5 from "../../elements/spinner/spinner.component";
import * as i6 from "./logout-page.component";
import * as i7 from "@ngxs/store";
import * as i8 from "@angular/router";
import * as i9 from "@angular/platform-browser";
var styles_LogoutPageComponent = [i0.styles];
var RenderType_LogoutPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoutPageComponent, data: {} });
export { RenderType_LogoutPageComponent as RenderType_LogoutPageComponent };
export function View_LogoutPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "app-stack-layout", [], [[4, "flexDirection", null]], null, null, i2.View_StackLayoutComponent_0, i2.RenderType_StackLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i3.StackLayoutComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Logging out..."])), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "p", [["class", "mt-sm"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We're securing your local data, please wait a few seconds."])), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "app-spinner", [["class", "mt-xl"]], [[2, "small", null]], null, null, i4.View_SpinnerComponent_0, i4.RenderType_SpinnerComponent)), i1.ɵdid(7, 49152, null, 0, i5.SpinnerComponent, [], null, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "small", [["class", "mt-xl"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You will be redirected to login page once completed."]))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).direction; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 7).fitToSize; _ck(_v, 6, 0, currVal_1); }); }
export function View_LogoutPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logout-page", [], null, null, null, View_LogoutPageComponent_0, RenderType_LogoutPageComponent)), i1.ɵdid(1, 245760, null, 0, i6.LogoutPageComponent, [i7.Store, i7.Actions, i8.Router, i9.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutPageComponentNgFactory = i1.ɵccf("app-logout-page", i6.LogoutPageComponent, View_LogoutPageComponent_Host_0, {}, {}, []);
export { LogoutPageComponentNgFactory as LogoutPageComponentNgFactory };
