import {Action, Actions, Selector, State, StateContext} from '@ngxs/store';
import {takeUntil, tap} from 'rxjs/operators';
import {TrialsRepositoryService} from '../api/repositories/trials-repository.service';
import {DownloadOfflineTrialLicense, FetchTrials} from './trials.actions';
import {TrialsModelResponse} from '../api/responses/trials-response';
import {Logout} from './auth.actions';
import ResponseHandlingHelper from '../helpers/response-handling-helper';

interface TrialsStateModel {
    trials: TrialsModelResponse[];
}

@State<TrialsStateModel>({
    name: 'Trials',
    defaults: {
        trials: [],
    },
})
export class TrialsState {
    constructor(
        private actions: Actions,
        private trialsRepository: TrialsRepositoryService,
    ) {
    }

    @Selector()
    public static trials(state: TrialsStateModel): TrialsModelResponse[] {
        return state.trials;
    }

    @Action(FetchTrials)
    public fetchTrials(ctx: StateContext<TrialsStateModel>, action: FetchTrials) {
        let request = this.trialsRepository.list()
            .pipe(tap(response => ctx.patchState({
                trials: response.data.items,
            })));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(DownloadOfflineTrialLicense)
    public downloadOfflineTrialLicense(ctx: StateContext<TrialsStateModel>, action: DownloadOfflineTrialLicense) {
        let request = this.trialsRepository.offlineLicense(action.trialId)
            .pipe(tap(response => {
                ResponseHandlingHelper.downloadFile(response, 'license_file.dkey');
            }));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(Logout)
    public logout(ctx: StateContext<TrialsStateModel>, action: Logout) {
        ctx.patchState({
            trials: [],
        });
    }
}
