import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActionCompletion, Actions, ofActionCompleted, ofActionSuccessful, Select, Store} from '@ngxs/store';
import {Observable, Subject, timer} from 'rxjs';
import {AuthState} from 'src/app/states/auth.state';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {PushMenuActiveAccountId} from 'src/app/states/menu.actions';
import ResponseHandlingHelper from 'src/app/helpers/response-handling-helper';
import {SubscriptionsState} from 'src/app/states/subscriptions.state';
import {
    SubscriptionUsersGroupModelResponse,
    SubscriptionUsersGroupUserModelResponse,
    SubscriptionUsersModelResponse,
} from 'src/app/api/responses/subscription-users-response';
import {
    FetchSubscriptionUsers,
    SubscriptionUsersAssignLicenses,
    SubscriptionUsersRevokeLicenses,
    SubscriptionUsersSetDomains,
} from 'src/app/states/subscriptions.actions';
import {PushAlert} from 'src/app/states/alerts.actions';
import {animate, style, transition, trigger} from '@angular/animations';
import {HttpErrorCode} from 'src/app/api/http-error-code.enum';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-subscription-users-page',
    templateUrl: './subscription-users-page.component.html',
    styleUrls: ['./subscription-users-page.component.scss'],
    animations: [
        trigger('tableRow', [
            transition(':leave', [
                animate(600, style({transform: 'translateX(30px)', opacity: 0})),
            ]),
        ]),
    ],
})
export class SubscriptionUsersPageComponent implements OnInit, OnDestroy {

    public accountId: number;

    @Select(SubscriptionsState.users)
    public usersListener: Observable<SubscriptionUsersModelResponse>;
    public users: SubscriptionUsersModelResponse = null;

    public get hasAnyUsers(): boolean {
        if (!this.users) {
            // Dane nie zostały jeszcze załadowane.
            return false;
        }

        if (this.users.accountId !== this.accountId) {
            // Dane są już załadowane (np. z cache),
            // ale dotyczą innej organizacji/konta.
            return false;
        }

        for (const packageId of Object.keys(this.users.usersByPackage)) {
            // pokaż userow jeśli liczba licencji jest > 0  <-- na tę chwilę ma pokazywać zawsze wszystkich przypisanych userow
            // if (this.users.usersByPackage[packageId].licensesCount > 0) {
                return true;
            // }
        }

        return false;
    }

    @Select(AuthState.email)
    public userEmailListener: Observable<string>;
    public userEmail: string;

    public isLoading = true;

    /**
     * Token służący do anulowania odświeżania danych.
     */
    private cancellationToken = new Subject();

    /**
     * Informuje o zmianie identyfikatora konta.
     *
     * Na sam koniec przed zniszczeniem komponentu wysyłana jest wartość "null".
     */
    private accountIdChanged = new Subject<number | null>();

    private gc = new Subject();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private actions: Actions,
        private store: Store,
        private title: Title,
    ) {
    }

    public ngOnInit() {
        this.title.setTitle('Subscription users - Dataedo Account');
        this.initEvents();
    }

    private initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));

        this.userEmailListener
            .pipe(takeUntil(this.gc))
            .subscribe(email => this.userEmail = email);

        this.usersListener
            .pipe(takeUntil(this.gc))
            .subscribe(users => this.onUsersChanged(users));

        this.actions
            .pipe(ofActionCompleted(FetchSubscriptionUsers))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedDataReload(event));

        this.actions
            .pipe(ofActionSuccessful(FetchSubscriptionUsers))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onSuccessfulDataReload(event));
    }

    private onPathChange(params: Params) {
        const newAccountId = parseInt(params.accountId, 10);
        if (this.accountId !== newAccountId) {
            this.accountIdChanged.next(this.accountId);
        }

        this.accountId = newAccountId;
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));

        this.scheduleFetchData();
    }

    private onUsersChanged(users: SubscriptionUsersModelResponse): void {
        this.users = users;
    }

    public getLicensesLeft(group: SubscriptionUsersGroupModelResponse) {
        return Math.max(0, group.licensesCount - group.users.length);
    }

    private scheduleFetchData() {
        this.cancellationToken.next();

        timer(300)
            .pipe(takeUntil(this.cancellationToken))
            .subscribe(() => {
                this.isLoading = true;
                this.store.dispatch(new FetchSubscriptionUsers(this.accountId, this.cancellationToken));
            });
    }

    private async onCompletedDataReload(event: ActionCompletion<FetchSubscriptionUsers, Error>) {
        // Obsługa błędów serwera.
        const response = ResponseHandlingHelper.parse(event.result);
        if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
            await this.router.navigate(['accounts', this.accountId]);
        }

        this.isLoading = false;
    }

    private onSuccessfulDataReload(event: FetchSubscriptionUsers) {
        this.isLoading = false;
    }

    public trackByEmail(index: number, user: SubscriptionUsersGroupUserModelResponse): string {
        return user.email;
    }

    public ngOnDestroy(): void {
        this.cancellationToken.next();
        this.cancellationToken.complete();

        this.accountIdChanged.next(null);
        this.accountIdChanged.complete();

        this.gc.next();
        this.gc.complete();
    }
}
