import {Subject} from 'rxjs';

export class FetchQuotes {
    public static readonly type = '[Quotes] FetchQuotes';

    constructor(public accountId: number, public cancellationToken?: Subject<any>) {
    }

}

export class FetchQuote {
    public static readonly type = '[Quotes] FetchQuote';

    constructor(public accountId: number, public quoteNo: string, public cancellationToken?: Subject<any>) {
    }

}
