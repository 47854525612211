import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import BaseFormComponent from '../BaseFormComponent';

@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: forwardRef(() => TextareaComponent),
    }],
})
export class TextareaComponent extends BaseFormComponent {

    @Input()
    public placeholder: string = null;

    @Input()
    public textAlign: 'left' | 'center' | 'right' = 'left';

    @Input()
    public size: 'small' | 'normal' = 'normal';

    @Input()
    public previewMode: 'yes' | 'no' | 'auto' = 'no';

    @Input()
    public disabled = false;

    @Input()
    public monospace = false;

    @Input()
    public rows = 4;

}
