<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
    <app-stack-layout *ngIf="success">
        <h2>Convert legacy key</h2>
        <app-stack-layout direction="row" class="mt-sm">
            <app-icon theme="color" icon="checked" class="no-shrink no-grow mr-sm"></app-icon>
            <p class="text-small">
                <ng-container *ngIf="success.accountName && success.productName; else defaultMessage">
                    <b>{{ success.productName }}</b> license for <b>{{ success.accountName }}</b> was assigned to <b>{{ success.email }}</b>.
                </ng-container>
                <ng-template #defaultMessage>
                    {{ success.message }}
                </ng-template>
            </p>
        </app-stack-layout>
        <app-stack-layout direction="row" class="mt-sm" *ngIf="success.limitExceeded">
            <app-icon theme="color" icon="error" class="no-shrink no-grow mr-sm"></app-icon>
            <p class="text-small">
                All {{ success.maxUsers }} license(s) in a key were already assigned to other users. You will not be able to use the product. Please log into <a [href]="appUrl">Dataedo Account</a> or <a [href]="supportUrl">contact support</a> for more details.
            </p>
        </app-stack-layout>

        <app-label class="mt-md">Key</app-label>
        <app-textarea formControlName="key" textAlign="center" size="small"  [monospace]="true" [rows]="8" class="key"></app-textarea>

        <div class="mt-sm">
            <a class="text-small" href="/?email={{ form.get('email').value }}">Log into Dataedo Account</a>
        </div>
    </app-stack-layout>

    <app-stack-layout *ngIf="!success">
        <h2>Convert legacy key</h2>
        <p class="mt-sm">Paste your legacy (pre Dataedo 10) license key to convert to online license.</p>

        <app-label class="mt-md">E-mail</app-label>
        <app-input name="email" formControlName="email"></app-input>
        <div *ngIf="formErrors.email">
            <app-error>{{ formErrors.email[0] }}</app-error>
        </div>

        <app-label class="mt-md">Key</app-label>
        <app-textarea formControlName="key" textAlign="center" size="small" [monospace]="true" [rows]="8" class="key" (focusout)="formatKey()"></app-textarea>
        <div *ngIf="formErrors.key">
            <app-error>{{ formErrors.key[0] }}</app-error>
        </div>

        <app-button [loading]="processing" theme="primary" class="mt-sm">Assign key</app-button>
        <div class="mt-sm">
            <a class="text-small" href="/?email={{ form.get('email').value }}">Log into Dataedo Account</a>
        </div>
    </app-stack-layout>
</form>
