/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./modal.component";
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
function View_ModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "modal-header"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
function View_ModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "modal-footer"]], null, null, null, null, null)), i1.ɵncd(null, 2)], null, null); }
function View_ModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["modal", 1]], null, 6, "div", [["class", "modal-box"]], [[4, "width", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "modal-content"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showHeader; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.showFooter; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; _ck(_v, 1, 0, currVal_0); }); }
export function View_ModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { modal: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵdid(1, 770048, null, 0, i3.ModalComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalComponentNgFactory = i1.ɵccf("app-modal", i3.ModalComponent, View_ModalComponent_Host_0, { reference: "reference", width: "width", cancellable: "cancellable", showHeader: "showHeader", showFooter: "showFooter" }, { opened: "opened" }, ["[modalHeader]", "*", "[modalFooter]"]);
export { ModalComponentNgFactory as ModalComponentNgFactory };
