import { environment } from '../../../environments/environment';
import FormDataHelper from 'src/app/helpers/form-data-helper';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AccountsRepositoryService {
    constructor(http) {
        this.http = http;
    }
    list() {
        return this.http.get(`${environment.apiUrl}v1/accounts`);
    }
    summaryByAccountId(accountId) {
        return this.http.get(`${environment.apiUrl}v1/accounts/${accountId}`);
    }
    subscriptionByAccountId(accountId) {
        return this.http.get(`${environment.apiUrl}v1/accounts/${accountId}/subscription`);
    }
    billingByAccountId(accountId) {
        return this.http.get(`${environment.apiUrl}v1/accounts/${accountId}/billing`);
    }
    updateBillingByAccountId(accountId, data) {
        return this.http.put(`${environment.apiUrl}v1/accounts/${accountId}/billing`, FormDataHelper.convertToUrlEncoded(data), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
    }
    offlineLicense(accountId) {
        return this.http.get(`${environment.apiUrl}v1/accounts/${accountId}/offline-license`, { responseType: 'text' });
    }
}
AccountsRepositoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountsRepositoryService_Factory() { return new AccountsRepositoryService(i0.ɵɵinject(i1.HttpClient)); }, token: AccountsRepositoryService, providedIn: "root" });
