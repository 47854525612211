/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-troubleshooting.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../elements/stack-layout/stack-layout.component.ngfactory";
import * as i3 from "../../elements/stack-layout/stack-layout.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "../../elements/button-link/button-link.component.ngfactory";
import * as i7 from "../../elements/button-link/button-link.component";
import * as i8 from "./login-troubleshooting.component";
import * as i9 from "@angular/platform-browser";
var styles_LoginTroubleshootingComponent = [i0.styles];
var RenderType_LoginTroubleshootingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginTroubleshootingComponent, data: {} });
export { RenderType_LoginTroubleshootingComponent as RenderType_LoginTroubleshootingComponent };
export function View_LoginTroubleshootingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "app-stack-layout", [], [[4, "flexDirection", null]], null, null, i2.View_StackLayoutComponent_0, i2.RenderType_StackLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i3.StackLayoutComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your code not arrived?"])), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "p", [["class", "mt-md"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please follow below instructions:"])), (_l()(), i1.ɵeld(6, 0, null, 0, 14, "ol", [["class", "mt-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Check \"spam\" folder"])), (_l()(), i1.ɵted(-1, null, [","])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Wait a minute"])), (_l()(), i1.ɵted(-1, null, [", some network issues may delay sending email,"])), (_l()(), i1.ɵeld(15, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Generate another code"])), (_l()(), i1.ɵted(-1, null, [" and check email address correctness."])), (_l()(), i1.ɵeld(21, 0, null, 0, 2, "app-button-link", [["class", "mt-md"], ["href", "/login"]], null, null, null, i6.View_ButtonLinkComponent_0, i6.RenderType_ButtonLinkComponent)), i1.ɵdid(22, 49152, null, 0, i7.ButtonLinkComponent, [i4.Router], { href: [0, "href"] }, null), (_l()(), i1.ɵted(-1, 0, [" I've got a code "])), (_l()(), i1.ɵeld(24, 0, null, 0, 4, "small", [["class", "mt-lg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We hope that above steps fixed your issue, if for some reason problem still persist then please "])), (_l()(), i1.ɵeld(26, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["contact our support team"])), (_l()(), i1.ɵted(-1, null, [". "]))], function (_ck, _v) { var currVal_3 = "/"; _ck(_v, 17, 0, currVal_3); var currVal_4 = "/login"; _ck(_v, 22, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).direction; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 17).target; var currVal_2 = i1.ɵnov(_v, 17).href; _ck(_v, 16, 0, currVal_1, currVal_2); var currVal_5 = _co.contactHref; _ck(_v, 26, 0, currVal_5); }); }
export function View_LoginTroubleshootingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-troubleshooting", [], null, null, null, View_LoginTroubleshootingComponent_0, RenderType_LoginTroubleshootingComponent)), i1.ɵdid(1, 114688, null, 0, i8.LoginTroubleshootingComponent, [i9.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginTroubleshootingComponentNgFactory = i1.ɵccf("app-login-troubleshooting", i8.LoginTroubleshootingComponent, View_LoginTroubleshootingComponent_Host_0, {}, {}, []);
export { LoginTroubleshootingComponentNgFactory as LoginTroubleshootingComponentNgFactory };
