import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { takeUntil, tap } from 'rxjs/operators';
import { FetchSubscriptionUsers, SubscriptionUsersAssignLicenses, SubscriptionUsersRevokeLicenses, SubscriptionUsersSetDomains, } from './subscriptions.actions';
import { Logout } from './auth.actions';
let SubscriptionsState = class SubscriptionsState {
    constructor(actions, subscriptionsRepository, subscriptionUsersRepository) {
        this.actions = actions;
        this.subscriptionsRepository = subscriptionsRepository;
        this.subscriptionUsersRepository = subscriptionUsersRepository;
    }
    static users(state) {
        return state.users;
    }
    fetchSubscriptionUsers(ctx, action) {
        let request = this.subscriptionUsersRepository.listByAccountId(action.accountId)
            .pipe(tap(response => ctx.patchState({
            users: response.data
        })));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    subscriptionUsersAssignLicenses(ctx, action) {
        let request = this.subscriptionUsersRepository.assignLicenses(action.accountId, action.packageId, action.emails);
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    subscriptionUsersRevokeLicenses(ctx, action) {
        let request = this.subscriptionUsersRepository.revokeLicenses(action.accountId, action.packageId, action.emails);
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    subscriptionUsersSetDomains(ctx, action) {
        let request = this.subscriptionUsersRepository.setDomains(action.accountId, action.packageId, action.domains);
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    logout(ctx, action) {
        ctx.patchState({
            users: null,
        });
    }
};
tslib_1.__decorate([
    Action(FetchSubscriptionUsers)
], SubscriptionsState.prototype, "fetchSubscriptionUsers", null);
tslib_1.__decorate([
    Action(SubscriptionUsersAssignLicenses)
], SubscriptionsState.prototype, "subscriptionUsersAssignLicenses", null);
tslib_1.__decorate([
    Action(SubscriptionUsersRevokeLicenses)
], SubscriptionsState.prototype, "subscriptionUsersRevokeLicenses", null);
tslib_1.__decorate([
    Action(SubscriptionUsersSetDomains)
], SubscriptionsState.prototype, "subscriptionUsersSetDomains", null);
tslib_1.__decorate([
    Action(Logout)
], SubscriptionsState.prototype, "logout", null);
tslib_1.__decorate([
    Selector()
], SubscriptionsState, "users", null);
SubscriptionsState = tslib_1.__decorate([
    State({
        name: 'Subscriptions',
        defaults: {
            users: null,
        }
    })
], SubscriptionsState);
export { SubscriptionsState };
