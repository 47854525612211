import {Action, Actions, Selector, State, StateContext} from '@ngxs/store';
import {takeUntil, tap} from 'rxjs/operators';
import {FetchPerpetualLicenses} from './perpetual-licenses.actions';
import {PerpetualLicensesRepositoryService} from '../api/repositories/perpetual-licenses-repository.service';
import {PerpetualLicensesModelResponse} from '../api/responses/perpetual-licenses-response';
import {Logout} from './auth.actions';

interface PerpetualLicensesStateModel {
    licenses: PerpetualLicensesModelResponse[];
}

@State<PerpetualLicensesStateModel>({
    name: 'PerpetualLicenses',
    defaults: {
        licenses: [],
    }
})
export class PerpetualLicensesState {
    constructor(
        private actions: Actions,
        private perpetualLicensesRepository: PerpetualLicensesRepositoryService
    ) {
    }

    @Selector()
    public static licenses(state: PerpetualLicensesStateModel): PerpetualLicensesModelResponse[] {
        return state.licenses;
    }

    @Action(FetchPerpetualLicenses)
    public fetchLicenses(ctx: StateContext<PerpetualLicensesStateModel>, action: FetchPerpetualLicenses) {
        let request = this.perpetualLicensesRepository.listByAccountId(action.accountId)
            .pipe(tap(response => ctx.patchState({
                licenses: response.data.items
            })));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(Logout)
    public logout(ctx: StateContext<PerpetualLicensesStateModel>, action: Logout) {
        ctx.patchState({
            licenses: [],
        });
    }
}
