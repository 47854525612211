import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Actions, Store} from '@ngxs/store';
import {Title} from '@angular/platform-browser';
import {finalize, takeUntil} from 'rxjs/operators';
import {LicensesRepositoryService} from '../../api/repositories/licenses-repository.service';
import {HttpErrorResponse} from '@angular/common/http';
import ConvertLegacyLicenseResponse from '../../api/responses/convert-legacy-license.response';
import {PushAlert} from '../../states/alerts.actions';
import {environment} from '../../../environments/environment';

interface FormData {
    email: string;
    key: string;
}

@Component({
    selector: 'app-convert-legacy-license-page',
    templateUrl: './convert-legacy-license-page.component.html',
    styleUrls: ['./convert-legacy-license-page.component.scss'],
})
export class ConvertLegacyLicensePageComponent implements OnInit, OnDestroy {

    public readonly appUrl: string = environment.appUrl;
    public readonly supportUrl: string = `mailto:${environment.supportMailAddress}`;

    public form: FormGroup;
    public formErrors: { [key: string]: string[] } = {};
    public processing = false;

    public success: ConvertLegacyLicenseResponse|null = null;

    private componentDestroyed = new Subject();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private store: Store,
        private title: Title,
        private formBuilder: FormBuilder,
        private licensesRepository: LicensesRepositoryService,
    ) {
    }

    public ngOnInit() {
        this.title.setTitle('Convert Legacy Key - Dataedo Account');
        this.initForm();
    }

    private initForm() {
        const email = this.route.snapshot.queryParams.email || '';
        const key = this.route.snapshot.queryParams.key || '';

        this.form = this.formBuilder.group({email, key} as FormData);
        this.formatKey();
    }

    public onSubmit(formData: FormData) {
        this.processing = true;
        this.formErrors = {};

        this.licensesRepository
            .convertLicense(formData.email, formData.key)
            .pipe(finalize(() => this.processing = false))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe({
                next: response => this.onConvertSuccess(response),
                error: error => this.onConvertError(error),
            });
    }

    private onConvertSuccess(response: ConvertLegacyLicenseResponse) {
        this.success = response;
        this.form.get('key').disable();

        this.store.dispatch(new PushAlert(response.message, {type: 'success'}));
    }

    private onConvertError(error: Error) {
        if (error instanceof HttpErrorResponse && error.status <= 0) {
            this.formErrors = {key: ['Please check network connection.']};
            return;
        }

        if (error instanceof HttpErrorResponse && error.status === 422) {
            this.formErrors = error.error.errors;
            return;
        }

        this.formErrors = {key: ['Something goes wrong, please contact with support.']};
    }

    public formatKey() {
        const key = this.form.get('key').value;
        const formatted = key
            .replace(/\s+/g, '')
            .match(/.{0,32}/g)
            .join('\r\n')
            .trim();

        this.form.get('key').setValue(formatted);
    }

    public ngOnDestroy(): void {
        this.componentDestroyed.next();
    }
}
