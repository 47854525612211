import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {AccountRoleEnum} from 'src/app/api/enums/account-role.enum';
import FormDataHelper from 'src/app/helpers/form-data-helper';
import AccountRoleAssignResponse from 'src/app/api/responses/account-role-assign.response';

@Injectable({
    providedIn: 'root'
})
export class AccountRegenerateTokenService {

    constructor(private http: HttpClient) {}

    public regenerateToken(accountId: number): Observable<string> {
        return this.http.post<string>(
            `${environment.apiUrl}v1/accounts/${accountId}/regenerate-api-token`,
            FormDataHelper.convertToUrlEncoded({accountId}),
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
        );
    }
}
