/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alerts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./alerts.component";
import * as i4 from "@ngxs/store";
var styles_AlertsComponent = [i0.styles];
var RenderType_AlertsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertsComponent, data: { "animation": [{ type: 7, name: "alert", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { marginTop: 5, opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { marginTop: 0, opacity: 1 }, offset: null }, timings: "0.2s ease-out" }], options: null }, { type: 1, expr: "true <=> false", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { transform: "rotate(0deg)", offset: 0 }, offset: null }, { type: 6, styles: { transform: "rotate(2deg)", offset: 0.2 }, offset: null }, { type: 6, styles: { transform: "rotate(-3deg)", offset: 0.35 }, offset: null }, { type: 6, styles: { transform: "rotate(2deg)", offset: 0.5 }, offset: null }, { type: 6, styles: { transform: "rotate(-1deg)", offset: 0.7 }, offset: null }, { type: 6, styles: { transform: "rotate(0deg)", offset: 1 }, offset: null }] }, timings: "0.8s" }], options: null }, { type: 1, expr: ":leave", animation: { type: 4, styles: { type: 6, styles: { marginTop: -5, opacity: 0 }, offset: null }, timings: "0.15s ease-out" }, options: null }], options: {} }] } });
export { RenderType_AlertsComponent as RenderType_AlertsComponent };
function View_AlertsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alert.message; _ck(_v, 0, 0, currVal_0); }); }
function View_AlertsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alert.message; _ck(_v, 1, 0, currVal_0); }); }
function View_AlertsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "space"]], [[24, "@alert", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeCurrent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(4, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 4, 0, "alert", _co.alert.options.type); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.alert.options.html; _ck(_v, 6, 0, currVal_2); var currVal_3 = !_co.alert.options.html; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.evenAlert; _ck(_v, 0, 0, currVal_0); }); }
export function View_AlertsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alert; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AlertsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alerts", [], null, null, null, View_AlertsComponent_0, RenderType_AlertsComponent)), i1.ɵdid(1, 245760, null, 0, i3.AlertsComponent, [i4.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertsComponentNgFactory = i1.ɵccf("app-alerts", i3.AlertsComponent, View_AlertsComponent_Host_0, {}, {}, []);
export { AlertsComponentNgFactory as AlertsComponentNgFactory };
