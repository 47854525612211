import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ofActionCompleted, Select } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import { QuotesState } from '../../states/quotes.state';
import { FetchQuote } from '../../states/quotes.actions';
import { PushMenuActiveAccountId } from 'src/app/states/menu.actions';
import { HttpErrorCode } from 'src/app/api/http-error-code.enum';
export class QuotePageComponent {
    constructor(store, actions, router, route, title) {
        this.store = store;
        this.actions = actions;
        this.router = router;
        this.route = route;
        this.title = title;
        this.now = Date.now() / 1000;
        this.quote = null;
        this.gc = new Subject();
        /**
         * Określa czy aktualnie trwa pobieranie danych.
         */
        this.isLoading = false;
        /**
         * Token służący do anulowania pobierania danych.
         */
        this.cancellationToken = new Subject();
    }
    ngOnInit() {
        this.title.setTitle('Quote - Dataedo Account');
        this.initEvents();
    }
    initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));
        this.quoteListener
            .pipe(takeUntil(this.gc))
            .subscribe(quote => this.quote = quote);
        this.actions
            .pipe(ofActionCompleted(FetchQuote))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchQuote(event));
    }
    onPathChange(params) {
        this.accountId = parseInt(params.accountId, 10);
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));
        this.quoteNo = params.quoteNo.replace('-', '/');
        this.fetchData();
    }
    fetchData() {
        this.isLoading = true;
        this.cancellationToken.next();
        this.store.dispatch(new FetchQuote(this.accountId, this.quoteNo, this.cancellationToken));
    }
    onCompletedFetchQuote(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = ResponseHandlingHelper.parse(event.result);
            if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
                yield this.router.navigate(['/accounts', this.accountId]);
            }
            this.isLoading = false;
        });
    }
    ngOnDestroy() {
        this.cancellationToken.next();
        this.cancellationToken.complete();
        this.gc.next();
        this.gc.complete();
    }
}
tslib_1.__decorate([
    Select(QuotesState.quote)
], QuotePageComponent.prototype, "quoteListener", void 0);
