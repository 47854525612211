import {Component, Input, OnInit} from '@angular/core';
import {IClipboardResponse} from 'ngx-clipboard';
import {PushAlert} from '../../states/alerts.actions';
import {Store} from '@ngxs/store';

@Component({
    selector: 'app-license-key',
    templateUrl: './license-key.component.html',
    styleUrls: ['./license-key.component.scss']
})
export class LicenseKeyComponent {

    @Input()
    public licenseKey: string;

    constructor(private store: Store) {
    }

    public licenseKeyCopied(event: IClipboardResponse) {
        this.store.dispatch(new PushAlert('Copied to clipboard.'));
    }

}
