import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActionCompletion, Actions, ofActionCompleted, Store} from '@ngxs/store';
import {Router} from '@angular/router';
import {Logout} from '../../states/auth.actions';
import {PushAlert} from '../../states/alerts.actions';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-logout-page',
    templateUrl: './logout-page.component.html',
    styleUrls: ['./logout-page.component.scss']
})
export class LogoutPageComponent implements OnInit, OnDestroy {

    private gc = new Subject();

    constructor(
        private store: Store,
        private actions: Actions,
        private router: Router,
        private title: Title
    ) {
    }

    public ngOnInit() {
        this.title.setTitle('Logging out...');

        this.actions.pipe(ofActionCompleted(Logout))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedLogout(event));

        this.store.dispatch(new Logout());
    }

    private onCompletedLogout(event: ActionCompletion<Logout, Error>) {
        const message = 'You has been successfully logged out.';
        this.store.dispatch(new PushAlert(message, {type: 'success'}));

        this.router.navigate(['/'], {
            replaceUrl: true,
        });
    }

    public ngOnDestroy(): void {
        this.gc.next();
        this.gc.complete();
    }

}
