import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { takeUntil, tap } from 'rxjs/operators';
import { Logout } from './auth.actions';
import { AssignAccountRole, FetchAccountRoles, RevokeAccountRole } from 'src/app/states/account-roles.actions';
let AccountRolesState = class AccountRolesState {
    constructor(actions, accountRolesRepository) {
        this.actions = actions;
        this.accountRolesRepository = accountRolesRepository;
    }
    static roles(state) {
        return state.roles;
    }
    fetchOrders(ctx, action) {
        let request = this.accountRolesRepository.listByAccountId(action.accountId)
            .pipe(tap(response => ctx.patchState({
            roles: response.data
        })));
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    assignAccountRole(ctx, action) {
        let request = this.accountRolesRepository.assignRole(action.accountId, action.role, action.email);
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    revokeAccountRole(ctx, action) {
        let request = this.accountRolesRepository.revokeRole(action.accountId, action.email);
        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }
        return request;
    }
    logout(ctx, action) {
        ctx.patchState({
            roles: null,
        });
    }
};
tslib_1.__decorate([
    Action(FetchAccountRoles)
], AccountRolesState.prototype, "fetchOrders", null);
tslib_1.__decorate([
    Action(AssignAccountRole)
], AccountRolesState.prototype, "assignAccountRole", null);
tslib_1.__decorate([
    Action(RevokeAccountRole)
], AccountRolesState.prototype, "revokeAccountRole", null);
tslib_1.__decorate([
    Action(Logout)
], AccountRolesState.prototype, "logout", null);
tslib_1.__decorate([
    Selector()
], AccountRolesState, "roles", null);
AccountRolesState = tslib_1.__decorate([
    State({
        name: 'AccountRoles',
        defaults: {
            roles: null,
        }
    })
], AccountRolesState);
export { AccountRolesState };
