import BaseFormComponent from '../BaseFormComponent';
export class InputComponent extends BaseFormComponent {
    constructor() {
        super(...arguments);
        this.type = 'text';
        this.name = null;
        this.placeholder = null;
        this.textAlign = 'left';
        this.size = 'normal';
        this.previewMode = 'no';
        this.disabled = false;
    }
}
