<app-stack-layout>
    <h1>Subscription users</h1>
    <p class="mt-xs">List of users with assigned subscription license in your organization. Users are grouped by license
        role.</p>
    <p class="mt-xs text-small text-secondary">This list is available only for account owners and admins.</p>

    <app-empty class="fullsize mt-lg" *ngIf="!isLoading && !hasAnyUsers">
        <app-stack-layout class="text-center">
            <h2>No results found</h2>
            <p class="mt-sm">We didn't find any licenses in your account.</p>
        </app-stack-layout>
    </app-empty>
    <div *ngIf="hasAnyUsers">
        <ng-template #usersByPackage let-group let-packageId="packageId">
            <ng-container *ngIf="group.licensesCount > 0 || group.users?.length > 0">
                <tr>
                    <td colspan="3">
                        <h2 class="mt-xl text-no-wrap">{{ group.title }}</h2>
                    </td>
                    <td colspan="3" class="text-bottom">
                        <span class="text-no-wrap">
                            <app-badge color="info" class="left-licenses-count">
                                {{ getLicensesLeft(group) || 'None' }}
                            </app-badge>
                            out of {{ group.licensesCount }} licenses available
                        </span>
                    </td>
                    <td class="table-fill-column"></td>
                </tr>
                <tr *ngIf="group.users?.length > 0">
                    <th>#</th>
                    <th>
                        <app-stack-layout class="header" direction="row">
                            <span>Email</span>
                            <app-icon class="ml-xs" icon="down-arrow" size="14px"></app-icon>
                        </app-stack-layout>
                    </th>
                    <th>Name</th>
                    <th class="text-no-wrap">Last used</th>
                    <th class="table-fill-column"></th>
                </tr>
                <tr *ngFor="let user of group.users; let i = index; trackBy: trackByEmail" [@tableRow]
                    [class.license-over-limit]="i >= group.licensesCount">
                    <td>{{ i + 1 }}</td>
                    <td class="text-no-wrap">
                        {{ user.email }}
                        <app-badge *ngIf="user.email === userEmail" color="success" class="ml-xs">You</app-badge>
                    </td>
                    <td class="text-no-wrap">
                        {{ user.fullName }}
                        <span *ngIf="!user.fullName" class="text-secondary">-</span>
                    </td>
                    <td class="text-no-wrap">
                        {{ user.lastUsed }}
                        <span *ngIf="!user.lastUsed" class="text-secondary">-</span>
                    </td>
                    <td class="table-fill-column"></td>
                </tr>
                <tr *ngIf="group.domains">
                    <td colspan="5">
                        Allow everyone from following domains: <b>{{ group.domains }}</b>
                    </td>
                </tr>
            </ng-container>
        </ng-template>

        <table>
            <ng-container *ngFor="let packageId of users.usersByPackage|keys">
                <ng-container
                    *ngTemplateOutlet="usersByPackage; context: {$implicit: users.usersByPackage[packageId], packageId: packageId}"></ng-container>
            </ng-container>
        </table>

        <div class="mt-lg" *ngIf="users._canCreateUsers || users._canDeleteUsers">
            <app-button-link [route]="['/accounts', accountId, 'users', 'edit']" icon="edit" size="small">Edit</app-button-link>
        </div>
    </div>
    <app-spinner class="fullsize" *ngIf="isLoading"></app-spinner>

    <app-page-bottom-space></app-page-bottom-space>
</app-stack-layout>
