import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {createPopper} from '@popperjs/core/lib/popper-lite.js';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow.js';
import flip from '@popperjs/core/lib/modifiers/flip.js';
import arrow from '@popperjs/core/lib/modifiers/arrow.js';
import {Instance} from '@popperjs/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    animations: [
        trigger('popper', [
            state('visible', style({
                visibility: 'visible',
                marginTop: 0,
                opacity: 1,
            })),
            state('hidden', style({
                visibility: 'hidden',
                marginTop: -4,
                opacity: 0,
            })),
            transition('hidden => visible', [
                style({visibility: 'visible'}),
                animate('0.2s 0.3s')
            ]),
            transition('visible => hidden', [
                animate('0.1s')
            ])
        ])
    ]
})
export class TooltipComponent implements OnInit, OnDestroy {
    /**
     * Describes the preferred placement of the tooltip.
     */
    @Input()
    public placement: 'auto'
        | 'auto-start'
        | 'auto-end'
        | 'top'
        | 'top-start'
        | 'top-end'
        | 'bottom'
        | 'bottom-start'
        | 'bottom-end'
        | 'right'
        | 'right-start'
        | 'right-end'
        | 'left'
        | 'left-start'
        | 'left-end' = 'auto';

    /**
     * Describes the positioning strategy to use. By default, it is absolute,
     * which in the simplest cases does not require repositioning of the popper.
     *
     * If your reference element is in a fixed container, use the fixed strategy.
     */
    @Input()
    public strategy: 'absolute' | 'fixed' = 'absolute';

    /**
     * Preferred tooltip popover width.
     */
    @Input()
    public width: 'auto' | string = 'auto';

    /**
     * Element for which we will display the tooltip.
     */
    @Input()
    public reference: HTMLElement;

    /**
     * Control whether tooltip is visible or not.
     */
    public visible = false;

    /**
     * Popper.js instance.
     *
     * @see https://popper.js.org/
     */
    private instance: Instance;

    /**
     * Pointer to element which stores tooltip popover content.
     */
    @ViewChild('container', {static: true})
    private container: ElementRef;

    private showHandler = () => this.show();
    private hideHandler = () => this.hide();

    public ngOnInit(): void {
        this.instance = createPopper(this.reference, this.container.nativeElement, {
            placement: this.placement,
            modifiers: [preventOverflow, flip, arrow],
        });

        this.bindNativeEvents();
    }

    private bindNativeEvents() {
        this.reference.addEventListener('focus', this.showHandler);
        this.reference.addEventListener('blur', this.hideHandler);
        this.reference.addEventListener('mouseenter', this.showHandler);
        this.reference.addEventListener('mouseleave', this.hideHandler);
    }

    private unbindNativeEvents() {
        this.reference.removeEventListener('focus', this.showHandler);
        this.reference.removeEventListener('blur', this.hideHandler);
        this.reference.removeEventListener('mouseenter', this.showHandler);
        this.reference.removeEventListener('mouseleave', this.hideHandler);
    }

    public show() {
        this.visible = true;
        this.instance.update();
    }

    public hide() {
        this.visible = false;
    }

    public ngOnDestroy(): void {
        this.unbindNativeEvents();
        this.instance.destroy();
    }
}
