/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logged-in-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../elements/spinner/spinner.component.ngfactory";
import * as i3 from "../../elements/spinner/spinner.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "./logged-in-page.component";
import * as i7 from "../../api/repositories/sessions-repository.service";
var styles_LoggedInPageComponent = [i0.styles];
var RenderType_LoggedInPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoggedInPageComponent, data: {} });
export { RenderType_LoggedInPageComponent as RenderType_LoggedInPageComponent };
function View_LoggedInPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner", [["class", "loader"]], [[2, "small", null]], null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).fitToSize; _ck(_v, 0, 0, currVal_0); }); }
export function View_LoggedInPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoggedInPageComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); }, null); }
export function View_LoggedInPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logged-in-page", [], null, null, null, View_LoggedInPageComponent_0, RenderType_LoggedInPageComponent)), i1.ɵdid(1, 245760, null, 0, i6.LoggedInPageComponent, [i7.SessionsRepositoryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoggedInPageComponentNgFactory = i1.ɵccf("app-logged-in-page", i6.LoggedInPageComponent, View_LoggedInPageComponent_Host_0, {}, {}, []);
export { LoggedInPageComponentNgFactory as LoggedInPageComponentNgFactory };
