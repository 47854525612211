<div class="text-center" *ngIf="!appRules">
    <app-spinner></app-spinner>
    <div class="mt-md text-secondary">Application verification...</div>
</div>

<div *ngIf="appRules && !isLoggingIn">
    <div class="text-center">
        <h2>
            <app-icon *ngIf="appRules.data.trusted" theme="color" icon="verified-account" size="24px"></app-icon>
            {{ appRules.data.name || 'Self-Hosted App' }}
        </h2>
        <small class="app-name mt-xs" *ngIf="!appRules.data.trusted">{{ returnUrl }}</small>
        <small class="app-name mt-xs" *ngIf="appRules.data.trusted">Trusted Dataedo App</small>
    </div>

    <div class="scopes mt-md">
        <p class="text-small">Would like to:</p>
        <hr>

        <div *ngFor="let scope of scopes" class="my-md">
            <b>{{ scopesInfo[scope].title }}</b>
            <div><small>{{ scopesInfo[scope].description }}</small></div>
        </div>

        <div *ngIf="scopes?.length === 0" class="my-md">
            <b [class.risky-scope]="!appRules.data.trusted">Access all data</b>
            <div><small>Full access to Dataedo Account.</small></div>
        </div>

        <hr>
    </div>

    <div class="text-secondary text-small mt-md" *ngIf="!appRules.data.trusted">
        <b>This application is not hosted on Dataedo servers</b>, continue only if you trust above application and domain.
    </div>

    <div class="mt-md text-center">
        <app-button class="mx-xs my-xs" (click)="cancel()">Cancel</app-button>
        <app-button class="mx-xs my-xs" (click)="signIn()" theme="primary">Allow</app-button>
    </div>
</div>

<div class="text-center" *ngIf="isLoggingIn">
    <app-spinner></app-spinner>
    <div class="mt-md text-secondary">Logging in...</div>
</div>
