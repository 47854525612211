import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ofActionCompleted, Select } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import { QuotesState } from '../../states/quotes.state';
import { FetchQuotes } from '../../states/quotes.actions';
import { PushMenuActiveAccountId } from 'src/app/states/menu.actions';
import { HttpErrorCode } from 'src/app/api/http-error-code.enum';
export class QuotesPageComponent {
    constructor(store, actions, router, route, title) {
        this.store = store;
        this.actions = actions;
        this.router = router;
        this.route = route;
        this.title = title;
        this.quotes = [];
        /**
         * Określa czy aktualnie trwa pobieranie danych.
         */
        this.isLoading = false;
        this.gc = new Subject();
        /**
         * Token służący do anulowania pobierania danych.
         */
        this.cancellationToken = new Subject();
    }
    ngOnInit() {
        this.title.setTitle('Quotes - Dataedo Account');
        this.initEvents();
    }
    fetchData() {
        this.cancellationToken.next();
        this.isLoading = true;
        this.store.dispatch(new FetchQuotes(this.accountId, this.cancellationToken));
    }
    initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));
        this.quotesListener
            .pipe(takeUntil(this.gc))
            .subscribe(quotes => this.quotes = quotes);
        this.actions
            .pipe(ofActionCompleted(FetchQuotes))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchQuotes(event));
    }
    onPathChange(params) {
        this.accountId = parseInt(params.accountId, 10);
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));
        this.fetchData();
    }
    onCompletedFetchQuotes(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = ResponseHandlingHelper.parse(event.result);
            if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
                yield this.router.navigate(['/accounts', this.accountId]);
            }
            this.isLoading = false;
        });
    }
    trackById(index, quote) {
        return quote.id;
    }
    ngOnDestroy() {
        this.cancellationToken.next();
        this.cancellationToken.complete();
        this.gc.next();
        this.gc.complete();
    }
}
tslib_1.__decorate([
    Select(QuotesState.quotes)
], QuotesPageComponent.prototype, "quotesListener", void 0);
