import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {PushAlert} from '../../states/alerts.actions';
import {FetchMenu} from 'src/app/states/menu.actions';
import {HttpErrorCode} from 'src/app/api/http-error-code.enum';

// Type HttpRequest<any> and HttpEvent<any> generated by Angular CLI.
// As a result, the following rule apply:
// tslint:disable:no-any

@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {

    constructor(private store: Store) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === HttpErrorCode.Forbidden) {
                this.handleError(error.error.message);
            }

            return throwError(error);
        }));
    }

    private handleError(message: string) {
        this.store.dispatch(new FetchMenu());
        this.store.dispatch(new PushAlert(message, {type: 'warning'}));
    }

}
