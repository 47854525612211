<app-stack-layout>
    <h1>Trials</h1>

    <app-empty class="fullsize mt-lg" *ngIf="!isLoading && trials.length === 0">
        <app-stack-layout class="text-center">
            <h2>No trial licenses</h2>
            <p class="mt-sm">Trial is completely free and time limited license<br>that you can take without providing your credit card number.</p>
            <div class="mt-md">
                <app-button-link [href]="freeTrialUrl" [openInNewTab]="true" theme="primary">
                    Get free trial
                    <app-icon class="ml-xs" icon="external-link" size="16px"></app-icon>
                </app-button-link>
            </div>
        </app-stack-layout>
    </app-empty>
    <div class="mt-lg" *ngIf="trials.length > 0">
        <table>
            <tr>
                <th>#</th>
                <th>
                    <app-stack-layout class="header" direction="row">
                        <span>Started on</span>
                        <app-icon class="ml-xs" icon="down-arrow" size="14px"></app-icon>
                    </app-stack-layout>
                </th>
                <th>Expires on</th>
                <th>Status</th>
                <th>Product</th>
                <th></th>
                <th class="table-fill-column"></th>
            </tr>
            <tr *ngFor="let trial of trials; let i = index; trackBy: trackTrialById">
                <td>{{ i + 1 }}</td>
                <td>
                    <app-timestamp [timestamp]="trial.started_at"></app-timestamp>
                </td>
                <td>
                    <app-timestamp [timestamp]="trial.expires_at"></app-timestamp>
                </td>
                <td class="text-no-wrap">
                    <app-badge [color]="trial.status.color">{{ trial.status.text }}</app-badge>
                </td>
                <td class="text-no-wrap">
                    {{ trial.package.name }}
                </td>
                <td class="text-no-wrap">
<!--                    <app-button-link *ngIf="trial._canChangePackage" size="small" [openInNewTab]="true" class="mr-sm"-->
<!--                                     href="{{ changePackageUrl }}?email={{ trial.email|urlencode }}&token={{ trial.token|urlencode }}">-->
<!--                        Change-->
<!--                        <app-icon class="f-no-shrink ml-xs" icon="external-link" size="16px"></app-icon>-->
<!--                    </app-button-link>-->
                    <ng-container *ngIf="trial._canDownloadOfflineLicense">
                        <a #downloadOfflineTrial class="d-inline-flex text-secondary text-no-wrap pxy-xs mr-sm text-middle"
                           (click)="downloadOfflineLicense(trial)">
                            <app-icon icon="download" size="18px"></app-icon>
                        </a>

                        <app-tooltip [reference]="downloadOfflineTrial">
                            Download offline license
                        </app-tooltip>
                    </ng-container>
                </td>
                <td class="table-fill-column"></td>
            </tr>
        </table>
    </div>
    <app-spinner class="fullsize" *ngIf="isLoading"></app-spinner>
</app-stack-layout>
