import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[appTextAlign]'
})
export class TextAlignDirective implements OnInit {

    @Input('appTextAlign')
    public textAlign: 'multiline' | 'oneline' = 'multiline';

    constructor(private el: ElementRef) {
    }

    public ngOnInit(): void {
        this.el.nativeElement.style.display = 'inline-flex';
        this.el.nativeElement.style.alignItems = 'center';
        this.el.nativeElement.style.flexWrap = this.textAlign === 'multiline' ? 'wrap' : 'no-wrap';
    }

}
