import {Subject} from 'rxjs';

export class FetchMenu {
    public static readonly type = '[Menu] FetchMenu';

    constructor(public cancellationToken?: Subject<any>) {
    }
}

export class PushMenuActiveAccountId {
    public static readonly type = ' [Menu] PushMenuActiveAccountId';

    /**
     * Number - organization/account id
     * Null - personal account
     * Undefined - no account selected
     */
    constructor(public accountId: number | null | undefined) {
    }
}
