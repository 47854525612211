import {Component} from '@angular/core';

@Component({
    selector: 'app-page-bottom-space',
    templateUrl: './page-bottom-space.component.html',
    styleUrls: ['./page-bottom-space.component.scss']
})
export class PageBottomSpaceComponent {

}
