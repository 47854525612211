import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActionCompletion, Actions, ofActionCompleted, Select, Store} from '@ngxs/store';
import {AccountsState} from 'src/app/states/accounts.state';
import {Observable, Subject} from 'rxjs';
import {AccountBillingModelResponse} from 'src/app/api/responses/account-billing-response';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {FetchAccountBilling} from 'src/app/states/accounts.actions';
import {PushMenuActiveAccountId} from 'src/app/states/menu.actions';
import ResponseHandlingHelper from 'src/app/helpers/response-handling-helper';
import {HttpErrorCode} from 'src/app/api/http-error-code.enum';
import {FormBuilder} from '@angular/forms';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-account-billing-page',
    templateUrl: './account-billing-page.component.html',
    styleUrls: ['./account-billing-page.component.scss']
})
export class AccountBillingPageComponent implements OnInit, OnDestroy {

    public accountId: number;

    @Select(AccountsState.billing)
    public billingListener: Observable<AccountBillingModelResponse | null>;
    public billing: AccountBillingModelResponse | null = null;

    private gc = new Subject();

    /**
     * Określa czy aktualnie trwa pobieranie danych.
     */
    public isLoadingBilling = false;

    /**
     * Token służący do anulowania pobierania danych.
     */
    private cancellationToken = new Subject();

    constructor(
        private store: Store,
        private actions: Actions,
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private title: Title,
    ) {
    }

    public ngOnInit() {
        this.title.setTitle('Billing & Payment - Dataedo Account');
        this.initEvents();
    }

    private initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));

        this.billingListener
            .pipe(takeUntil(this.gc))
            .subscribe(billing => this.billing = billing);

        this.actions
            .pipe(ofActionCompleted(FetchAccountBilling))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchBilling(event));
    }

    private onPathChange(params: Params) {
        this.accountId = parseInt(params.accountId, 10);
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));

        this.fetchData();
    }

    private fetchData() {
        this.isLoadingBilling = true;

        this.cancellationToken.next();
        this.store.dispatch(new FetchAccountBilling(this.accountId, this.cancellationToken));
    }

    private async onCompletedFetchBilling(event: ActionCompletion<FetchAccountBilling, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);
        if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
            await this.router.navigate(['/accounts', this.accountId]);
        }

        this.isLoadingBilling = false;
    }

    public ngOnDestroy(): void {
        this.cancellationToken.next();
        this.cancellationToken.complete();

        this.gc.next();
        this.gc.complete();
    }
}
