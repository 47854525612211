import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Alert, AlertsState} from '../../states/alerts.state';
import {Observable, Subject, timer} from 'rxjs';
import {AuthState} from '../../states/auth.state';
import {delay, takeUntil, tap} from 'rxjs/operators';
import {PopAlert, PushAlert} from '../../states/alerts.actions';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.scss'],
    animations: [
        trigger('alert', [
            transition(':enter', [
                style({marginTop: 5, opacity: 0}),
                animate('0.2s ease-out', style({marginTop: 0, opacity: 1}))
            ]),
            transition('true <=> false', [
                animate('0.8s', keyframes([
                    style({ transform: 'rotate(0deg)', offset: 0}),
                    style({ transform: 'rotate(2deg)', offset: 0.2}),
                    style({ transform: 'rotate(-3deg)', offset: 0.35}),
                    style({ transform: 'rotate(2deg)', offset: 0.5}),
                    style({ transform: 'rotate(-1deg)', offset: 0.7}),
                    style({ transform: 'rotate(0deg)', offset: 1.0}),
                ]))
            ]),
            transition(':leave',
                animate('0.15s ease-out', style({marginTop: -5, opacity: 0}))
            )
        ])
    ]
})
export class AlertsComponent implements OnInit, OnDestroy {

    /**
     * Selektor pobierajacy pierwsze powiadomienie w kolejce
     * po dodaniu lub usunieciu ktoregos z powiadomien.
     */
    @Select(AlertsState.topAlert)
    public topAlert: Observable<Alert | null>;

    /**
     * Aktualnie wyswietlany alert.
     */
    public alert: Alert | null = null;

    /**
     * Czy powiadomienie jest parzyste.
     *
     * Zmieniane przy pojawieniu się nowego powiadomienia
     * w celu animowania przejścia między powiadomieniami.
     */
    public evenAlert = false;

    private cancellationToken = new Subject();

    private gc = new Subject();

    constructor(private store: Store) {
    }

    public ngOnInit() {
        this.topAlert
            .pipe(takeUntil(this.gc))
            .pipe(tap(_ => this.cancellationToken.next()))
            .subscribe(alert => alert && this.onAlert(alert));
    }

    private onAlert(alert: Alert) {
        this.alert = alert;
        this.evenAlert = !this.evenAlert;

        timer(alert.options.duration)
            .pipe(takeUntil(this.cancellationToken))
            .subscribe(_ => this.hideCurrent());
    }

    private hideCurrent() {
        this.alert = null;
        this.store.dispatch(new PopAlert());
    }

    public closeCurrent() {
        if (this.alert && this.alert.options.cancellable) {
            this.cancellationToken.next();
            this.hideCurrent();
        }
    }

    public ngOnDestroy(): void {
        this.gc.next();
        this.gc.complete();
    }
}
