/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tooltip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tooltip.component";
var styles_TooltipComponent = [i0.styles];
var RenderType_TooltipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipComponent, data: { "animation": [{ type: 7, name: "popper", definitions: [{ type: 0, name: "visible", styles: { type: 6, styles: { visibility: "visible", marginTop: 0, opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "hidden", styles: { type: 6, styles: { visibility: "hidden", marginTop: -4, opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "hidden => visible", animation: [{ type: 6, styles: { visibility: "visible" }, offset: null }, { type: 4, styles: null, timings: "0.2s 0.3s" }], options: null }, { type: 1, expr: "visible => hidden", animation: [{ type: 4, styles: null, timings: "0.1s" }], options: null }], options: {} }] } });
export { RenderType_TooltipComponent as RenderType_TooltipComponent };
export function View_TooltipComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 2, "div", [["class", "popper"]], [[24, "@popper", 0], [4, "width", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["data-popper-arrow", ""]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible ? "visible" : "hidden"); var currVal_1 = _co.width; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_TooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tooltip", [], null, null, null, View_TooltipComponent_0, RenderType_TooltipComponent)), i1.ɵdid(1, 245760, null, 0, i2.TooltipComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TooltipComponentNgFactory = i1.ɵccf("app-tooltip", i2.TooltipComponent, View_TooltipComponent_Host_0, { placement: "placement", strategy: "strategy", width: "width", reference: "reference" }, {}, ["*"]);
export { TooltipComponentNgFactory as TooltipComponentNgFactory };
