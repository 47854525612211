import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

    @Input()
    @HostBinding('class.small')
    public fitToSize = false;

}
