import { environment } from 'src/environments/environment';
import FormDataHelper from 'src/app/helpers/form-data-helper';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SubscriptionUsersRepositoryService {
    constructor(http) {
        this.http = http;
    }
    listByAccountId(accountId) {
        return this.http.get(`${environment.apiUrl}v1/accounts/${accountId}/users`);
    }
    assignLicenses(accountId, packageId, emails) {
        return this.http.put(`${environment.apiUrl}v1/accounts/${accountId}/users/assign`, FormDataHelper.convertToUrlEncoded({ packageId, emails }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
    }
    revokeLicenses(accountId, packageId, emails) {
        return this.http.put(`${environment.apiUrl}v1/accounts/${accountId}/users/revoke`, FormDataHelper.convertToUrlEncoded({ packageId, emails }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
    }
    setDomains(accountId, packageId, domains) {
        return this.http.put(`${environment.apiUrl}v1/accounts/${accountId}/domains`, FormDataHelper.convertToUrlEncoded({ packageId, domains }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
    }
}
SubscriptionUsersRepositoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubscriptionUsersRepositoryService_Factory() { return new SubscriptionUsersRepositoryService(i0.ɵɵinject(i1.HttpClient)); }, token: SubscriptionUsersRepositoryService, providedIn: "root" });
