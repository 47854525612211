import {Action, Actions, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {takeUntil, tap} from 'rxjs/operators';
import {FetchTrials} from './trials.actions';
import {FetchOrders} from './orders.actions';
import {OrdersRepositoryService} from '../api/repositories/orders-repository.service';
import {QuotesRepositoryService} from '../api/repositories/quotes-repository.service';
import {FetchQuote, FetchQuotes} from './quotes.actions';
import {QuotesModelResponse} from '../api/responses/quotes-response';
import {QuoteModelResponse} from '../api/responses/quote-response';
import {OrderModelResponse} from '../api/responses/order-response';
import {Logout} from './auth.actions';

interface QuotesStateModel {
    quote: QuoteModelResponse|null;
    quotes: QuotesModelResponse[];
}

@State<QuotesStateModel>({
    name: 'Quotes',
    defaults: {
        quote: null,
        quotes: [],
    }
})
export class QuotesState {
    constructor(
        private actions: Actions,
        private quotesRepository: QuotesRepositoryService
    ) {
    }

    @Selector()
    public static quote(state: QuotesStateModel): QuoteModelResponse|null {
        return state.quote;
    }

    @Selector()
    public static quotes(state: QuotesStateModel): QuotesModelResponse[] {
        return state.quotes;
    }

    @Action(FetchQuotes)
    public fetchQuotes(ctx: StateContext<QuotesStateModel>, action: FetchQuotes) {
        let request = this.quotesRepository.listByAccountId(action.accountId)
            .pipe(tap(response => ctx.patchState({
                quotes: response.data.items
            })));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(FetchQuote)
    public fetchQuote(ctx: StateContext<QuotesStateModel>, action: FetchQuote) {
        let request = this.quotesRepository.findByAccountIdAndQuoteNo(action.accountId, action.quoteNo)
            .pipe(tap(response => ctx.patchState({
                quote: response.data
            })));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(Logout)
    public logout(ctx: StateContext<QuotesStateModel>, action: Logout) {
        ctx.patchState({
            quote: null,
            quotes: [],
        });
    }
}
