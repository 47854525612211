<span #toggle class="toggle-link">
    <ng-content></ng-content>
</span>

<app-modal #modal [reference]="toggle" [cancellable]="true"
           (opened)="loadPackageDetails()"
           [showFooter]="false" [showHeader]="false">
    <div>
        <h1 *ngIf="header" class="mb-md">{{ header }}</h1>

        <app-spinner style="display: flex; min-width: 140px;" *ngIf="loading"></app-spinner>

        <div *ngIf="modules" class="pr-md">
            <div *ngFor="let group of modules|keys; let i = index" [class.mt-md]="i > 0">
                <b>{{ group }}</b>
                <div *ngFor="let module of modules[group]" class="text-small text-secondary">
                    {{ module }}
                </div>
            </div>
        </div>

        <div class="text-right mt-md">
            <app-button size="small" (click)="modal.hide()">Close</app-button>
        </div>
    </div>
</app-modal>
