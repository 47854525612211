import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit, OnChanges {

    @Input()
    public color: string|'info'|'error'|'warning'|'success' = 'info';

    @Input()
    public outline = false;

    private colorPalette = {
        info: '#61a6f4',
        error: '#d2504d',
        warning: '#fcca3d',
        success: '#6dc289',
    };

    public ngOnInit(): void {
        this.mapPaletteColor();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.color) {
            this.mapPaletteColor();
        }
    }

    private mapPaletteColor() {
        if (this.colorPalette[this.color] !== undefined) {
            this.color = this.colorPalette[this.color];
        }
    }
}
