import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Subject} from 'rxjs';
import {Actions, Store} from '@ngxs/store';
import {NavigationStart, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {animate, state, style, transition, trigger} from '@angular/animations';

const DEFAULT_MENU_WIDTH = 260;

@Component({
    selector: 'app-account-menu-page',
    templateUrl: './account-menu-page.component.html',
    styleUrls: ['./account-menu-page.component.scss'],
    animations: [
        trigger('menu', [
            state('open', style({
                left: 0,
            })),
            state('closed', style({
                left: `-${DEFAULT_MENU_WIDTH}px`,
                display: 'none',
            })),
            transition('open => closed', [
                animate('0.2s')
            ]),
            transition('closed => open', [
                style({display: 'block'}),
                animate('0.2s')
            ]),
        ]),
        trigger('overlay', [
            state('open', style({
                opacity: 1,
            })),
            state('closed', style({
                opacity: 0,
                display: 'none',
            })),
            transition('open => closed', [
                animate('0.2s')
            ]),
            transition('closed => open', [
                style({display: 'block'}),
                animate('0.2s')
            ]),
        ])
    ]
})
export class AccountMenuPageComponent implements OnInit, OnDestroy {

    public websiteUrl = environment.websiteUrl;

    public menuWidth = `${DEFAULT_MENU_WIDTH}px`;
    public menuOpened = false;

    private gc = new Subject();

    constructor(
        private store: Store,
        private actions: Actions,
        private router: Router
    ) {
    }

    public ngOnInit(): void {
        this.initEvents();
    }

    private initEvents() {
        this.router.events
            .pipe(filter(e => e instanceof NavigationStart))
            .pipe(takeUntil(this.gc))
            .subscribe(_ => this.closeMenu());
    }

    public toggleMenu() {
        this.menuOpened = !this.menuOpened;
    }

    public closeMenu() {
        this.menuOpened = false;
    }

    public ngOnDestroy(): void {
        this.gc.next();
        this.gc.complete();
    }
}
