import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {AuthState} from '../../states/auth.state';
import {PushAlert} from '../../states/alerts.actions';
import {IntendedUrl} from '../../states/auth.actions';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    private message = 'Please sign in to access this resource.';
    private loginFormUrl = '/';

    constructor(
        private store: Store,
        private router: Router
    ) {
    }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const loggedIn = this.store.selectSnapshot(AuthState.isAuthenticated);
        if (loggedIn) {
            return true;
        }

        // Store the attempted URL for redirecting.
        this.store.dispatch(new IntendedUrl(state.url));

        // Navigate to the login page.
        this.store.dispatch(new PushAlert(this.message, {type: 'warning'}));
        this.router.navigate([this.loginFormUrl]);

        return false;
    }
}
