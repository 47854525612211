import {Action, Actions, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {takeUntil, tap} from 'rxjs/operators';
import {FetchTrials} from './trials.actions';
import {FetchOrder, FetchOrders} from './orders.actions';
import {OrdersRepositoryService} from '../api/repositories/orders-repository.service';
import {OrdersModelResponse} from '../api/responses/orders-response';
import {OrderModelResponse} from '../api/responses/order-response';
import {Logout} from './auth.actions';
import {AssignAccountRole, FetchAccountRoles, RevokeAccountRole} from 'src/app/states/account-roles.actions';
import {AccountRolesRepositoryService} from 'src/app/api/repositories/account-roles-repository.service';
import {AccountRolesModelResponse} from 'src/app/api/responses/account-roles-response';
import {SubscriptionUsersAssignLicenses, SubscriptionUsersRevokeLicenses} from 'src/app/states/subscriptions.actions';

interface AccountRolesStateModel {
    roles: AccountRolesModelResponse;
}

@State<AccountRolesStateModel>({
    name: 'AccountRoles',
    defaults: {
        roles: null,
    }
})
export class AccountRolesState {
    constructor(
        private actions: Actions,
        private accountRolesRepository: AccountRolesRepositoryService
    ) {
    }

    @Selector()
    public static roles(state: AccountRolesStateModel): AccountRolesModelResponse {
        return state.roles;
    }

    @Action(FetchAccountRoles)
    public fetchOrders(ctx: StateContext<AccountRolesStateModel>, action: FetchOrders) {
        let request = this.accountRolesRepository.listByAccountId(action.accountId)
            .pipe(tap(response => ctx.patchState({
                roles: response.data
            })));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(AssignAccountRole)
    public assignAccountRole(ctx: StateContext<AccountRolesStateModel>, action: AssignAccountRole) {
        let request = this.accountRolesRepository.assignRole(action.accountId, action.role, action.email);

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(RevokeAccountRole)
    public revokeAccountRole(ctx: StateContext<AccountRolesStateModel>, action: RevokeAccountRole) {
        let request = this.accountRolesRepository.revokeRole(action.accountId, action.email);

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(Logout)
    public logout(ctx: StateContext<AccountRolesStateModel>, action: Logout) {
        ctx.patchState({
            roles: null,
        });
    }
}
