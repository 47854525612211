/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button-link.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../spinner/spinner.component.ngfactory";
import * as i3 from "../spinner/spinner.component";
import * as i4 from "../icon/icon.component.ngfactory";
import * as i5 from "../icon/icon.component";
import * as i6 from "@angular/common/http";
import * as i7 from "@angular/common";
import * as i8 from "../../helpers/directives/tabbed-links.directive";
import * as i9 from "./button-link.component";
import * as i10 from "@angular/router";
var styles_ButtonLinkComponent = [i0.styles];
var RenderType_ButtonLinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonLinkComponent, data: {} });
export { RenderType_ButtonLinkComponent as RenderType_ButtonLinkComponent };
function View_ButtonLinkComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner", [["class", "loader"]], [[2, "small", null]], null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).fitToSize; _ck(_v, 0, 0, currVal_0); }); }
function View_ButtonLinkComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icon", [["size", "18px"]], [[4, "width", null], [4, "height", null], [4, "display", null], [4, "verticalAlign", null]], null, null, i4.View_IconComponent_0, i4.RenderType_IconComponent)), i1.ɵdid(1, 638976, null, 0, i5.IconComponent, [i1.ElementRef, i6.HttpClient], { icon: [0, "icon"], size: [1, "size"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.icon; var currVal_5 = "18px"; _ck(_v, 1, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).size; var currVal_1 = i1.ɵnov(_v, 1).size; var currVal_2 = i1.ɵnov(_v, 1).display; var currVal_3 = i1.ɵnov(_v, 1).verticalAlign; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_ButtonLinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "a", [["class", "button"]], [[2, "is-loading", null], [1, "tabindex", 0], [2, "has-icon", null], [2, "disabled", null], [1, "target", 0], [1, "href", 4], [8, "tabIndex", 0], [1, "role", 0]], [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown.enter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onEnterKey($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.navigate() !== false);
        ad = (pd_1 && ad);
    } if (("keydown.enter" === en)) {
        var pd_2 = (_co.navigate() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(3, 2), i1.ɵdid(4, 16384, null, 0, i8.TabbedLinksDirective, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonLinkComponent_1)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonLinkComponent_2)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_8 = "button"; var currVal_9 = _ck(_v, 3, 0, _co.size, _co.theme); _ck(_v, 2, 0, currVal_8, currVal_9); var currVal_10 = _co.loading; _ck(_v, 6, 0, currVal_10); var currVal_11 = _co.icon; _ck(_v, 8, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; var currVal_1 = _co.tabindex; var currVal_2 = !!_co.icon; var currVal_3 = (_co.disabled || _co.loading); var currVal_4 = (_co.openInNewTab ? "_blank" : null); var currVal_5 = ((_co.href !== "") ? _co.href : null); var currVal_6 = i1.ɵnov(_v, 4).tabindex; var currVal_7 = i1.ɵnov(_v, 4).role; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_ButtonLinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button-link", [], null, null, null, View_ButtonLinkComponent_0, RenderType_ButtonLinkComponent)), i1.ɵdid(1, 49152, null, 0, i9.ButtonLinkComponent, [i10.Router], null, null)], null, null); }
var ButtonLinkComponentNgFactory = i1.ɵccf("app-button-link", i9.ButtonLinkComponent, View_ButtonLinkComponent_Host_0, { href: "href", route: "route", tabindex: "tabindex", disabled: "disabled", loading: "loading", openInNewTab: "openInNewTab", size: "size", theme: "theme", icon: "icon" }, {}, ["*"]);
export { ButtonLinkComponentNgFactory as ButtonLinkComponentNgFactory };
