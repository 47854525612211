import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ofActionCompleted, Select } from '@ngxs/store';
import { Subject } from 'rxjs';
import { OrdersState } from '../../states/orders.state';
import { FetchOrders } from '../../states/orders.actions';
import { takeUntil } from 'rxjs/operators';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import { PushMenuActiveAccountId } from 'src/app/states/menu.actions';
import { HttpErrorCode } from 'src/app/api/http-error-code.enum';
export class OrdersPageComponent {
    constructor(store, actions, router, route, title) {
        this.store = store;
        this.actions = actions;
        this.router = router;
        this.route = route;
        this.title = title;
        this.orders = [];
        /**
         * Określa czy aktualnie trwa pobieranie danych.
         */
        this.isLoading = false;
        /**
         * Token służący do anulowania pobierania danych.
         */
        this.cancellationToken = new Subject();
        this.gc = new Subject();
    }
    ngOnInit() {
        this.title.setTitle('Orders & Invoices - Dataedo Account');
        this.initEvents();
    }
    fetchData() {
        this.cancellationToken.next();
        this.isLoading = true;
        this.store.dispatch(new FetchOrders(this.accountId, this.cancellationToken));
    }
    initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));
        this.ordersListener
            .pipe(takeUntil(this.gc))
            .subscribe(orders => this.orders = orders);
        this.actions
            .pipe(ofActionCompleted(FetchOrders))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchOrders(event));
    }
    onPathChange(params) {
        this.accountId = parseInt(params.accountId, 10);
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));
        this.fetchData();
    }
    onCompletedFetchOrders(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = ResponseHandlingHelper.parse(event.result);
            if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
                yield this.router.navigate(['/accounts', this.accountId]);
            }
            this.isLoading = false;
        });
    }
    trackById(index, order) {
        return order.id;
    }
    ngOnDestroy() {
        this.cancellationToken.next();
        this.cancellationToken.complete();
        this.gc.next();
        this.gc.complete();
    }
}
tslib_1.__decorate([
    Select(OrdersState.orders)
], OrdersPageComponent.prototype, "ordersListener", void 0);
