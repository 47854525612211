import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FetchOrder } from '../../states/orders.actions';
import { ofActionCompleted, Select } from '@ngxs/store';
import { OrdersState } from '../../states/orders.state';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import { PushMenuActiveAccountId } from 'src/app/states/menu.actions';
import { HttpErrorCode } from 'src/app/api/http-error-code.enum';
export class OrderPageComponent {
    constructor(store, actions, router, route, title) {
        this.store = store;
        this.actions = actions;
        this.router = router;
        this.route = route;
        this.title = title;
        this.now = Date.now() / 1000;
        this.order = null;
        this.gc = new Subject();
        /**
         * Określa czy aktualnie trwa pobieranie danych.
         */
        this.isLoading = false;
        /**
         * Token służący do anulowania pobierania danych.
         */
        this.cancellationToken = new Subject();
    }
    ngOnInit() {
        this.title.setTitle('Order - Dataedo Account');
        this.initEvents();
    }
    initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));
        this.orderListener
            .pipe(takeUntil(this.gc))
            .subscribe(order => this.order = order);
        this.actions
            .pipe(ofActionCompleted(FetchOrder))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchOrder(event));
    }
    onPathChange(params) {
        this.accountId = parseInt(params.accountId, 10);
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));
        this.orderNo = params.orderNo.replace('-', '/');
        this.fetchData();
    }
    fetchData() {
        this.isLoading = true;
        this.cancellationToken.next();
        this.store.dispatch(new FetchOrder(this.accountId, this.orderNo, this.cancellationToken));
    }
    onCompletedFetchOrder(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = ResponseHandlingHelper.parse(event.result);
            if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
                yield this.router.navigate(['/accounts', this.accountId]);
            }
            this.isLoading = false;
        });
    }
    ngOnDestroy() {
        this.cancellationToken.next();
        this.cancellationToken.complete();
        this.gc.next();
        this.gc.complete();
    }
}
tslib_1.__decorate([
    Select(OrdersState.order)
], OrderPageComponent.prototype, "orderListener", void 0);
