import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-not-found-page',
    templateUrl: './not-found-page.component.html',
    styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

    public contactHref = `mailto:${environment.supportMailAddress}?subject=Broken link found&body=Broken link at page "${encodeURIComponent(document.referrer)}" points to "${encodeURIComponent(window.location.href)}".`;

    public isExternalReferrer = false;
    public isInternalReferrer = false;

    private dataedoHosts = [
        `${window.location.protocol}//${window.location.host}/`,
    ];

    constructor(private title: Title) {
    }

    public ngOnInit() {
        this.title.setTitle('Page not found - Dataedo Account');

        if (document.referrer === window.location.href) {
            // Referrer jest DOKLADNIE taki sam jak aktualna strona - pomijamy.
            return;
        }

        const referrer = document.referrer;
        const currentHost = `${window.location.protocol}//${window.location.host}/`;

        this.isInternalReferrer = this.dataedoHosts
            .filter(host => referrer.indexOf(host) === 0)
            .length > 0;

        this.isExternalReferrer = referrer
            && !this.isInternalReferrer;
    }

}
