<a #reference
   tabindex="0"
   class="element"
   (keydown.enter)="$event.target.click()"
   [ngxClipboard] [cbContent]="text"
   (cbOnSuccess)="copied($event)">
    <code>{{ text }}</code>
    <app-icon class="ml-sm" icon="copy-to-clipboard" size="16px"></app-icon>
</a>

<app-tooltip placement="bottom" [reference]="reference">
    Copy to clipboard
</app-tooltip>
