import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class EducationalLicensesRepositoryService {
    constructor(http) {
        this.http = http;
    }
    list() {
        return this.http.get(`${environment.apiUrl}v1/educational-licenses`);
    }
}
EducationalLicensesRepositoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EducationalLicensesRepositoryService_Factory() { return new EducationalLicensesRepositoryService(i0.ɵɵinject(i1.HttpClient)); }, token: EducationalLicensesRepositoryService, providedIn: "root" });
