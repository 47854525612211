import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import FormDataHelper from '../../helpers/form-data-helper';
import {ScopeEnum} from '../enums/scope.enum';
import {join, map} from 'lodash';
import {SingleSignOnAppRulesResponse} from '../responses/single-sign-on-app-rules-response';
import {SingleSignOnTokenResponse} from '../responses/single-sign-on-token-response';

@Injectable({
  providedIn: 'root'
})
export class SingleSignOnRepositoryService {

    constructor(private http: HttpClient) {
    }

    public verifyApp(url: string, scopes: ScopeEnum[]): Observable<SingleSignOnAppRulesResponse> {
        const scopesParams = join(map(scopes, scope => `scopes[]=${encodeURIComponent(scope)}`), '&');

        return this.http.get<SingleSignOnAppRulesResponse>(
            `${environment.apiUrl}v1/sso/verify?url=${encodeURIComponent(url)}&${scopesParams}`,
        );
    }

    public grantAccess(url: string, scopes: ScopeEnum[]): Observable<SingleSignOnTokenResponse> {
        const scopesParams = join(map(scopes, scope => `scopes[]=${encodeURIComponent(scope)}`), '&');

        return this.http.post<SingleSignOnTokenResponse>(
            `${environment.apiUrl}v1/sso/access?url=${encodeURIComponent(url)}&${scopesParams}`,
            null
        );
    }

}
