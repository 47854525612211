import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ofActionCompleted, Select } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import { FetchEducationalLicenses } from '../../states/educational-licenses.actions';
import { EducationalLicensesState } from '../../states/educational-licenses.state';
import { PushMenuActiveAccountId } from 'src/app/states/menu.actions';
export class EducationalLicensesPageComponent {
    constructor(store, title, actions) {
        this.store = store;
        this.title = title;
        this.actions = actions;
        this.educationalLicenseUrl = `${environment.websiteUrl}education-licenses`;
        this.licenses = [];
        /**
         * Określa czy aktualnie trwa pobieranie danych.
         */
        this.isLoading = false;
        this.gc = new Subject();
        /**
         * Token służący do anulowania pobierania danych.
         */
        this.cancellationToken = new Subject();
    }
    ngOnInit() {
        this.title.setTitle('Educational licenses - Dataedo Account');
        this.initEvents();
        this.fetchData();
    }
    fetchData() {
        this.isLoading = true;
        this.store.dispatch(new PushMenuActiveAccountId(null));
        this.store.dispatch(new FetchEducationalLicenses(this.cancellationToken));
    }
    initEvents() {
        this.licensesListener
            .pipe(takeUntil(this.gc))
            .subscribe(licenses => this.licenses = licenses);
        this.actions
            .pipe(ofActionCompleted(FetchEducationalLicenses))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchLicenses(event));
    }
    onCompletedFetchLicenses(event) {
        const response = ResponseHandlingHelper.parse(event.result);
        this.isLoading = false;
    }
    trackLicenseById(index, license) {
        return license.id;
    }
    ngOnDestroy() {
        this.cancellationToken.next();
        this.cancellationToken.complete();
        this.gc.next();
        this.gc.complete();
    }
}
tslib_1.__decorate([
    Select(EducationalLicensesState.licenses)
], EducationalLicensesPageComponent.prototype, "licensesListener", void 0);
