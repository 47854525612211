<div [ngClass]="['menu', theme]">
    <ng-container *ngFor="let group of visibleGroups; trackBy: trackByKey; let i = index">
        <!-- Groups separator -->
        <hr class="menu-divider" *ngIf=" i > 0">

        <!-- Group link/name -->
        <ng-container *ngIf="shouldDisplayGroupName(group)">
            <a *ngIf="group.routerLink" class="menu-link"
               [routerLink]="group.routerLink" routerLinkActive="active"
               [routerLinkActiveOptions]="{exact: true}">
                <span class="menu-link-label">{{ group.name }}</span>
                <app-badge *ngIf="group.badge" class="ml-xs" [outline]="true"
                           [color]="group.badge.color || 'info'">{{ group.badge.label }}
                </app-badge>
            </a>

            <div *ngIf="!group.routerLink" class="menu-link">
                <span class="menu-link-label">{{ group.name }}</span>
                <app-badge *ngIf="group.badge" class="ml-xs" [outline]="true"
                           [color]="group.badge.color || 'info'">{{ group.badge.label }}
                </app-badge>
            </div>
        </ng-container>

        <!-- Group items -->
        <a *ngFor="let item of itemsByGroup[group.key]; trackBy: trackByKey"
           class="menu-link"
           [routerLink]="item.routerLink"
           routerLinkActive="active">

            <app-icon class="menu-link-icon"
                      [size]="theme == 'small' ? '18px' : '22px'"
                      [icon]="item.icon ? item.icon : 'blank'">
            </app-icon>
            <span class="menu-link-label">{{ item.name }}</span>
            <app-badge *ngIf="item.badge" class="ml-xs" [outline]="true"
                       [color]="item.badge.color || 'info'">{{ item.badge.label }}
            </app-badge>
        </a>
    </ng-container>
</div>
