import { environment } from '../../../environments/environment';
import { join, map } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SingleSignOnRepositoryService {
    constructor(http) {
        this.http = http;
    }
    verifyApp(url, scopes) {
        const scopesParams = join(map(scopes, scope => `scopes[]=${encodeURIComponent(scope)}`), '&');
        return this.http.get(`${environment.apiUrl}v1/sso/verify?url=${encodeURIComponent(url)}&${scopesParams}`);
    }
    grantAccess(url, scopes) {
        const scopesParams = join(map(scopes, scope => `scopes[]=${encodeURIComponent(scope)}`), '&');
        return this.http.post(`${environment.apiUrl}v1/sso/access?url=${encodeURIComponent(url)}&${scopesParams}`, null);
    }
}
SingleSignOnRepositoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SingleSignOnRepositoryService_Factory() { return new SingleSignOnRepositoryService(i0.ɵɵinject(i1.HttpClient)); }, token: SingleSignOnRepositoryService, providedIn: "root" });
