import {HttpErrorCode} from '../api/http-error-code.enum';
import {error} from 'util';
import {HttpErrorResponse} from '@angular/common/http';

export interface Response {
    isSuccessful: boolean;
    isCanceled: boolean;
    isError: boolean;
    error: null | {
        /**
         * Wiadomość błędu zwrócona przez API dla błędów HTTP
         * lub domyślny komunikat błędu (w przypadku innych błędów)
         */
        message: string;

        /**
         * Kod błędu HTTP lub -1 jeśli to nie błąd HTTP.
         */
        status: HttpErrorCode;

        /**
         * Błędy walidacji formularza.
         */
        form: { [key: string]: string[] };

        /**
         * Dane zwrócone przez API.
         */
        data: any;

        /**
         * Oryginalny błąd.
         */
        native: Error;
    };
}

export interface RawResponse {
    successful: boolean;
    canceled: boolean;
    error?: Error;
}

export default class ResponseHandlingHelper {

    /**
     * Parsuje odpowiedź zwracaną przez zdarzenie ofActionCompleted, np.
     * this.actions.pipe(ofActionCompleted(LoginRequest)).subscribe(event => this.onCompletedLogin(event));
     */
    public static parse(response: RawResponse): Response {
        const shared = {
            isSuccessful: response.successful,
            isCanceled: response.canceled,
            isError: !response.successful && !response.canceled,
        };

        if (response.error instanceof HttpErrorResponse) {
            return {
                ...shared,
                error: response.error && {
                    message: response.error.error.message,
                    status: response.error.status,
                    form: response.error.status === HttpErrorCode.UnprocessableEntity
                        ? response.error.error.errors
                        : {},
                    data: response.error.error.data,
                    native: response.error,
                },
            };
        }

        return {
            ...shared,
            error: response.error && {
                message: `Unknown error occurred, please contact with administrator.`,
                status: HttpErrorCode.Unknown,
                form: {},
                data: null,
                native: response.error,
            },
        };
    }

    private static download(data, filename, type = 'application/octet-stream') {
        const dataStr = `data:${type};charset=utf-8,` + encodeURIComponent(data);
        const link = document.createElement('a');
        link.setAttribute('href', dataStr);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    public static downloadJson(json, filename) {
        this.download(JSON.stringify(json, null, 4), filename, 'text/json');
    }

    public static downloadFile(data, filename) {
        this.download(data, filename);
    }

}
