<app-stack-layout>
    <h1>Quotes</h1>

    <app-empty class="fullsize mt-lg" *ngIf="!isLoading && quotes.length === 0">
        <app-stack-layout class="text-center">
            <h2>No results found</h2>
            <p class="mt-sm">We didn't find any order in your history.</p>
        </app-stack-layout>
    </app-empty>
    <div class="mt-lg" *ngIf="quotes.length > 0 && quotes[0].account_id === accountId">
        <table>
            <tr>
                <th>#</th>
                <th>Doc no</th>
                <th>
                    <app-stack-layout class="header" direction="row">
                        <span>Date</span>
                        <app-icon class="ml-xs" icon="down-arrow" size="14px"></app-icon>
                    </app-stack-layout>
                </th>
                <th>Valid till</th>
                <th>Payment Status</th>
                <th>Customer</th>
                <th>Product</th>
                <th class="text-right">Amount</th>
                <th></th>
                <th class="table-fill-column"></th>
            </tr>
            <tr *ngFor="let quote of quotes; let i = index; trackBy: trackById">
                <td>{{ i + 1 }}</td>
                <td>
                    <a [routerLink]="['/accounts', accountId, 'quotes', quote.quote_no.replace('/', '-')]">
                        {{ quote.quote_no }}
                    </a>
                </td>
                <td>
                    <app-timestamp [timestamp]="quote.requested_at"></app-timestamp>
                </td>
                <td>
                    <app-timestamp [timestamp]="quote.expires_at"></app-timestamp>
                </td>
                <td class="text-no-wrap">
                    <app-badge [color]="quote.status.color">{{ quote.status.text }}</app-badge>
                </td>
                <td class="text-no-wrap">
                    {{ quote.billing_address?.company }}
                </td>
                <td class="text-no-wrap">
                    <div *ngFor="let line of quote.lines">
                        <span *ngIf="line.quantity">{{ line.quantity }} x </span>{{ line.name }}
                    </div>
                </td>
                <td class="text-no-wrap text-right">
                    {{ quote.amount | moneyFormat:quote.currency }}
                </td>
                <td>
                    <div class="text-no-wrap on-tr-hover">
                        <app-button-link class="mr-xs" size="small" [route]="['/accounts', accountId, 'quotes', quote.quote_no.replace('/', '-')]">
                            More
                        </app-button-link>

                        <app-button-link class="mr-xs" *ngIf="quote._place_an_order_url" [href]="quote._place_an_order_url" [openInNewTab]="true" size="small" theme="primary">
                            Order
                            <app-icon class="ml-xs" icon="external-link" size="16px"></app-icon>
                        </app-button-link>
                    </div>
                </td>
                <td class="table-fill-column"></td>
            </tr>
        </table>
    </div>
    <app-spinner class="fullsize" *ngIf="isLoading"></app-spinner>
</app-stack-layout>
