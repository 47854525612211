import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SingleSignOnRepositoryService} from '../../api/repositories/single-sign-on-repository.service';
import {ScopeEnum} from '../../api/enums/scope.enum';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SingleSignOnAppRulesResponse} from '../../api/responses/single-sign-on-app-rules-response';
import {isString} from 'util';
import {HttpErrorResponse} from '@angular/common/http';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-single-sign-on-page',
    templateUrl: './single-sign-on-page.component.html',
    styleUrls: ['./single-sign-on-page.component.scss'],
})
export class SingleSignOnPageComponent implements OnInit, OnDestroy {

    public readonly scopesInfo: { [scope: string]: { title: string, description: string } } = {
        [ScopeEnum.availableLicenses]: {
            title: 'View your active licenses',
            description: 'Used only to confirm that you have an active license.',
        },
    };

    public returnUrl: string;
    private userHash: string;
    public scopes: ScopeEnum[];

    public appRules?: SingleSignOnAppRulesResponse;
    public isLoggingIn = false;

    private gc = new Subject();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private singleSignOnRepository: SingleSignOnRepositoryService,
        private title: Title
    ) {
    }

    public ngOnInit() {
        this.title.setTitle('Sign in - Dataedo Account');
        const queryParams = this.route.snapshot.queryParams;
        this.returnUrl = queryParams.returnUrl;
        this.userHash = queryParams.hash;
        this.scopes = isString(queryParams.scopes)
            ? [queryParams.scopes]
            : (queryParams.scopes || []);

        this.validateParams();
        this.verifyExternalApp();
    }

    private validateParams() {
        if (!this.returnUrl) {
            return this.redirectOnError('The return url parameter is required.');
        } else if (this.returnUrl.trim().length <= 0) {
            return this.redirectOnError('The return url parameter can not be empty.');
        }

        if (!this.userHash) {
            return this.redirectOnError('The hash parameter is required.');
        } else if (this.userHash.trim().length <= 0) {
            return this.redirectOnError('The hash parameter can not be empty.');
        }

        for (const scope of this.scopes) {
            if (this.scopesInfo[scope] === undefined) {
                const availableScopes = Object.keys(this.scopesInfo).join(', ');
                this.redirectOnError(`The ${scope} scope is not supported, available scopes: ${availableScopes}.`);
            }
        }
    }

    /**
     * Pobiera informacje o aplikacji, m.in. ustala czy logowanie powinno
     * nastąpić automatycznie czy użytkownik powinien zostać najpierw poproszony
     * o akceptację uprawnień o których dostęp ubiega się aplikacja (dotyczy aplikacji
     * zewn. lub niezweryfikowanych).
     */
    private verifyExternalApp() {
        this.singleSignOnRepository
            .verifyApp(this.returnUrl, this.scopes)
            .pipe(takeUntil(this.gc))
            .subscribe(
                appRules => this.onAppVerified(appRules),
                error => this.onAppVerifiedFailed(error),
            );
    }

    private onAppVerified(rules: SingleSignOnAppRulesResponse) {
        this.appRules = rules;

        if (this.appRules.data.userShouldAccept === false) {
            this.signIn();
        }
    }

    public cancel() {
        this.redirectOnError('Operation cancelled by user.');
    }

    public signIn() {
        this.isLoggingIn = true;

        this.singleSignOnRepository
            .grantAccess(this.returnUrl, this.scopes)
            .pipe(takeUntil(this.gc))
            .subscribe(
                response => this.redirectOnSuccess(response.data.token),
                () => this.redirectOnError('The sign in process failed.'),
            );
    }

    private redirectOnSuccess(token: string) {
        window.location.href = `${this.returnUrl}?status=success`
            + `&hash=${encodeURIComponent(this.userHash)}`
            + `&token=${encodeURIComponent(token)}`;
    }

    private redirectOnError(message: string) {
        if (!this.returnUrl) {
            return window.history.back();
        }

        window.location.href = `${this.returnUrl}?status=error`
            + `&hash=${encodeURIComponent(this.userHash)}`
            + `&message=${encodeURIComponent(message)}`;
    }

    public ngOnDestroy(): void {
        this.gc.next();
        this.gc.complete();
    }

    private onAppVerifiedFailed(error: any) {
        if (error instanceof HttpErrorResponse && error.status === 401) {
            return; // Do nothing, user will be redirected to login page.
            // After succesfull sign in will be redirected back to form.
        }

        this.redirectOnError('The application verification failed.');
    }
}
