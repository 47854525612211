<app-stack-layout>
    <app-navbar class="navbar">
        <a data-menu-toggle-btn class="navbar-menu-toggle" (click)="toggleMenu()">
            <app-icon size="20px" icon="menu"></app-icon>
        </a>
    </app-navbar>
    <app-stack-layout direction="row">
        <app-licenses-menu class="menu"
                           [style.width]="menuWidth"
                           [@menu]="menuOpened ? 'open' : 'closed'">
        </app-licenses-menu>
        <main class="content">
            <div class="content-container">
                <router-outlet></router-outlet>
            </div>
        </main>
    </app-stack-layout>
</app-stack-layout>

<div class="overlay" [@overlay]="menuOpened ? 'open' : 'closed'" (click)="closeMenu()"></div>
