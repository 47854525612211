<app-stack-layout>
    <app-empty *ngIf="!isLoading && !hasAnyLicenses" class="fullsize">
        <app-stack-layout class="text-center">
            <app-icon class="empty-icon" icon="sad" size="120px"></app-icon>
            <h2 class="mt-md">No licenses found</h2>
            <p class="mt-sm">No licenses found assigned to your email or domain. No account has assigned access to this email.</p>
            <div class="mt-md">
<!--                <app-button theme="primary" routerLink="/legacy-license" class="mr-sm">-->
<!--                    Convert pre 10 license key-->
<!--                </app-button>-->
                <app-button (click)="openChat()" class="mr-sm">
                    Contact support
                </app-button>
                <app-button-link [href]="freeTrialUrl" [openInNewTab]="true">
                    Get free trial
                    <app-icon class="ml-xs" icon="external-link" size="16px"></app-icon>
                </app-button-link>
            </div>
        </app-stack-layout>
    </app-empty>
    <div *ngIf="hasAnyLicenses">
        <h1>Your accounts</h1>
        <p class="mt-xs">Accounts which you're a member are listed below:</p>

        <div class="mt-lg">
            <table>
                <tr *ngIf="shouldShowPersonalLicenses">
                    <td [style.width.px]="400">
                        <a routerLink="/trials">{{ menu.personal.accountName }}</a>
                    </td>
                </tr>
                <tr *ngFor="let menuItem of menu.accounts; let i = index; trackBy: trackById">
                    <td [style.width.px]="400">
                        <a routerLink="/accounts/{{ menuItem.accountId }}">{{ menuItem.accountName }}</a>
                        <app-badge class="ml-sm" color="#c2c2c2" [outline]="true">{{ menuItem.accountId }}</app-badge>
                    </td>
                </tr>
            </table>
        </div>

        <div class="mt-md">
            <a href="https://dataedo.com/docs/customer-account" target="_blank">Can't see your subscription? Learn more</a>
        </div>

        <div class="mt-md">
<!--            <app-button theme="primary" routerLink="/legacy-license" class="mr-sm">-->
<!--                Convert pre 10 license key-->
<!--            </app-button>-->
            <app-button (click)="openChat()" class="mr-sm">
                Contact support
            </app-button>
        </div>
    </div>
    <app-spinner class="fullsize" *ngIf="isLoading"></app-spinner>

    <div class="pt-md"><!-- Empty space at the bottom --></div>
</app-stack-layout>
