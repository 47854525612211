import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import QuotesResponse from '../responses/quotes-response';
import QuoteResponse from '../responses/quote-response';

@Injectable({
    providedIn: 'root'
})
export class QuotesRepositoryService {
    constructor(private http: HttpClient) {
    }

    public listByAccountId(accountId: number): Observable<QuotesResponse> {
        return this.http.get<QuotesResponse>(`${environment.apiUrl}v1/accounts/${accountId}/quotes`);
    }

    public findByAccountIdAndQuoteNo(accountId: number, quoteNo: string): Observable<QuoteResponse> {
        const quoteNoNorm = quoteNo.replace('/', '-');

        return this.http.get<QuoteResponse>(`${environment.apiUrl}v1/accounts/${accountId}/quotes/${quoteNoNorm}`);
    }
}
