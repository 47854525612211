import {Subject} from 'rxjs';

export class LoginRequest {
    public static readonly type = '[Auth] LoginRequest';

    constructor(public email: string, public cancellationToken?: Subject<any>) {
    }
}

export class LoginWithGoogle {
    public static readonly type = '[Auth] LoginWithGoogle';

    constructor(public cancellationToken?: Subject<any>) {
    }
}

export class LoginWithMicrosoft {
    public static readonly type = '[Auth] LoginWithMicrosoft';

    constructor(public cancellationToken?: Subject<any>) {
    }
}

export class ConfirmLoginRequest {
    public static readonly type = '[Auth] ConfirmLoginRequest';

    constructor(
        public email: string,
        public code: string,
        public remember: boolean,
        public cancellationToken?: Subject<any>
    ) {
    }
}

export class ConfirmOAuth2 {
    public static readonly type = '[Auth] ConfirmOAuth2';

    constructor(
        public email: string,
        public userId: number,
        public token: string,
        public expiresAt: number | null,
    ) {
    }
}

export class SessionRegisterRequest {
    public static readonly type = '[Auth] SessionRegisterRequest';

    constructor(
        public email: string,
        public code: string,
        public firstName: string,
        public lastName: string,
        public cancellationToken?: Subject<any>
    ) {
    }
}

export class FetchProfile {
    public static readonly type = '[Auth] FetchProfile';

    constructor(public cancellationToken?: Subject<any>) {
    }
}

export class AuthTokenRenewal {
    public static readonly type = '[Auth] AuthTokenRenewal';

    constructor(public expiresAt: number) {
    }
}

export class IntendedUrl {
    public static readonly type = '[Auth] IntendedUrl';

    constructor(public url: string | null) {
    }
}

export class Logout {
    public static readonly type = '[Auth] Logout';
}

