import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import OrdersResponse from 'src/app/api/responses/orders-response';
import {environment} from 'src/environments/environment';
import AccountRolesResponse from 'src/app/api/responses/account-roles-response';
import SubscriptionUsersResponse from 'src/app/api/responses/subscription-users-response';
import {SubscriptionRoleEnum} from 'src/app/api/enums/subscription-role.enum';
import FormDataHelper from 'src/app/helpers/form-data-helper';
import SubscriptionUsersAssignResponse from 'src/app/api/responses/subscription-users-assign.response';
import SubscriptionUsersRevokeResponse from 'src/app/api/responses/subscription-users-revoke.response';
import SubscriptionUsersDomainsResponse from '../responses/subscription-users-domains.response';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionUsersRepositoryService {

    constructor(private http: HttpClient) {
    }

    public listByAccountId(accountId: number): Observable<SubscriptionUsersResponse> {
        return this.http.get<SubscriptionUsersResponse>(`${environment.apiUrl}v1/accounts/${accountId}/users`);
    }

    public assignLicenses(accountId: number, packageId: string, emails: string[]): Observable<SubscriptionUsersAssignResponse> {
        return this.http.put<SubscriptionUsersAssignResponse>(
            `${environment.apiUrl}v1/accounts/${accountId}/users/assign`,
            FormDataHelper.convertToUrlEncoded({packageId, emails}),
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
        );
    }

    public revokeLicenses(accountId: number, packageId: string, emails: string[]): Observable<SubscriptionUsersRevokeResponse> {
        return this.http.put<SubscriptionUsersRevokeResponse>(
            `${environment.apiUrl}v1/accounts/${accountId}/users/revoke`,
            FormDataHelper.convertToUrlEncoded({packageId, emails}),
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
        );
    }

    public setDomains(accountId: number, packageId: string, domains: string[]): Observable<SubscriptionUsersDomainsResponse> {
        return this.http.put<SubscriptionUsersDomainsResponse>(
            `${environment.apiUrl}v1/accounts/${accountId}/domains`,
            FormDataHelper.convertToUrlEncoded({packageId, domains}),
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
        );
    }
}
