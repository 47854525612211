import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TrialsRepositoryService {
    constructor(http) {
        this.http = http;
    }
    list() {
        return this.http.get(`${environment.apiUrl}v1/trials`);
    }
    offlineLicense(trialId) {
        return this.http.get(`${environment.apiUrl}v1/trials/${trialId}/offline-license`, { responseType: 'text' });
    }
}
TrialsRepositoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TrialsRepositoryService_Factory() { return new TrialsRepositoryService(i0.ɵɵinject(i1.HttpClient)); }, token: TrialsRepositoryService, providedIn: "root" });
