<input #model
       [type]="type"
       [attr.name]="name"
       [attr.placeholder]="placeholder"
       [attr.disabled]="disabled ? 'disabled' : null"
       [style.textAlign]="textAlign"
       [class.small]="size === 'small'"
       [class.only-edit-mode]="previewMode === 'no'"
       [class.only-preview-mode]="previewMode === 'yes'"
       [value]="value"
       (change)="onChange(model.value)"
       (keydown)="onChange(model.value)"
       (keyup)="onChange(model.value)">
