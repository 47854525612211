<app-stack-layout>
    <h1>Administration</h1>
    <p class="mt-xs">List of privileged users in your organization.</p>
    <p class="mt-xs text-small text-secondary">This list is available only for account owners.</p>

    <app-empty class="fullsize mt-lg" *ngIf="!isLoading && roles?.items?.length === 0">
        <app-stack-layout class="text-center">
            <h2>No results found</h2>
            <p class="mt-sm">We didn't find any roles in your organization.</p>
        </app-stack-layout>
    </app-empty>
    <div class="mt-lg" *ngIf="roles?.items?.length > 0 && roles?.accountId === accountId">
        <table>
            <tr>
                <th>#</th>
                <th>Role</th>
                <th>
                    <app-stack-layout class="header" direction="row">
                        <span>Email</span>
                        <app-icon class="ml-xs" icon="down-arrow" size="14px"></app-icon>
                    </app-stack-layout>
                </th>
                <th class="table-fill-column"></th>
            </tr>
            <tr *ngFor="let role of roles.items; let i = index; trackBy: trackById" [@tableRow]>
                <td>{{ i + 1 }}</td>
                <td class="text-no-wrap">{{ role.role|titlecase }}</td>
                <td class="text-no-wrap">
                    {{ role.email }}
                    <app-badge *ngIf="role.email === userEmail" color="success" class="ml-xs">You</app-badge>
                </td>
                <td class="table-fill-column"></td>
            </tr>
        </table>

        <div class="mt-xs" *ngIf="roles._canAssignRoles || roles._canRevokeRoles">
            <app-button-link [route]="['/accounts', accountId, 'roles', 'edit']" icon="edit" size="small">Edit</app-button-link>
        </div>
    </div>
    <app-spinner class="fullsize" *ngIf="isLoading"></app-spinner>
</app-stack-layout>
