<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
    <app-stack-layout>
        <h2>Complete account data</h2>

        <app-label class="mt-md">First name</app-label>
        <app-input formControlName="firstName"></app-input>
        <div *ngIf="formErrors.firstName">
            <app-error>{{ formErrors.firstName[0] }}</app-error>
        </div>

        <app-label class="mt-md">Last name</app-label>
        <app-input formControlName="lastName"></app-input>
        <div *ngIf="formErrors.lastName">
            <app-error>{{ formErrors.lastName[0] }}</app-error>
        </div>

        <app-button [loading]="isLoading" theme="primary" class="mt-xs">Update account</app-button>

        <small class="mt-sm">
            <a routerLink="/">&larr; Back to login</a>
        </small>
    </app-stack-layout>
</form>
