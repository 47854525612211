import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import BaseFormComponent from '../BaseFormComponent';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: forwardRef(() => CheckboxComponent),
    }]
})
export class CheckboxComponent extends BaseFormComponent {

    @Input()
    public disabled = false;

    public writeValue(obj: any): void {
        this.value = [true, 1, 'true', '1'].indexOf(obj) >= 0;
        this.onChangeCallback(this.value);
    }

}
