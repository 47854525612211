import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {Store} from '@ngxs/store';
import {PushAlert} from '../../states/alerts.actions';
import {HttpErrorCode} from 'src/app/api/http-error-code.enum';

// Type HttpRequest<any> and HttpEvent<any> generated by Angular CLI.
// As a result, the following rule apply:
// tslint:disable:no-any

@Injectable()
export class InternalServerErrorInterceptor implements HttpInterceptor {

    private message = `<b>Internal server error</b>, please try later.`; // <br><p class="text-small">A report from this incident has been sent to our support team.</p>

    constructor(private store: Store) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === HttpErrorCode.InternalServerError) {
                this.handleError(error);
            }

            return throwError(error);
        }));
    }

    private handleError(error: HttpErrorResponse) {
        this.store.dispatch(new PushAlert(this.message, {type: 'error', html: true}));
    }

}
