/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./badge.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./badge.component";
var styles_BadgeComponent = [i0.styles];
var RenderType_BadgeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BadgeComponent, data: {} });
export { RenderType_BadgeComponent as RenderType_BadgeComponent };
export function View_BadgeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "badge"]], [[4, "color", null], [2, "is-outline", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "background"]], [[4, "backgroundColor", null]], null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.color; var currVal_1 = _co.outline; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.color; _ck(_v, 1, 0, currVal_2); }); }
export function View_BadgeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-badge", [], null, null, null, View_BadgeComponent_0, RenderType_BadgeComponent)), i1.ɵdid(1, 638976, null, 0, i2.BadgeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BadgeComponentNgFactory = i1.ɵccf("app-badge", i2.BadgeComponent, View_BadgeComponent_Host_0, { color: "color", outline: "outline" }, {}, ["*"]);
export { BadgeComponentNgFactory as BadgeComponentNgFactory };
