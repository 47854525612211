import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import OrdersResponse from 'src/app/api/responses/orders-response';
import {environment} from 'src/environments/environment';
import AccountRolesResponse from 'src/app/api/responses/account-roles-response';
import {AccountRoleEnum} from 'src/app/api/enums/account-role.enum';
import {SubscriptionRoleEnum} from 'src/app/api/enums/subscription-role.enum';
import SubscriptionUsersAssignResponse from 'src/app/api/responses/subscription-users-assign.response';
import FormDataHelper from 'src/app/helpers/form-data-helper';
import SubscriptionUsersRevokeResponse from 'src/app/api/responses/subscription-users-revoke.response';
import AccountRoleAssignResponse from 'src/app/api/responses/account-role-assign.response';
import AccountRoleRevokeResponse from 'src/app/api/responses/account-role-revoke.response';

@Injectable({
  providedIn: 'root'
})
export class AccountRolesRepositoryService {

    constructor(private http: HttpClient) {
    }

    public listByAccountId(accountId: number): Observable<AccountRolesResponse> {
        return this.http.get<AccountRolesResponse>(`${environment.apiUrl}v1/accounts/${accountId}/roles`);
    }

    public assignRole(accountId: number, role: AccountRoleEnum, email: string): Observable<AccountRoleAssignResponse> {
        return this.http.put<AccountRoleAssignResponse>(
            `${environment.apiUrl}v1/accounts/${accountId}/roles/assign`,
            FormDataHelper.convertToUrlEncoded({role, email}),
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
        );
    }

    public revokeRole(accountId: number, email: string): Observable<AccountRoleRevokeResponse> {
        return this.http.put<AccountRoleRevokeResponse>(
            `${environment.apiUrl}v1/accounts/${accountId}/roles/revoke`,
            FormDataHelper.convertToUrlEncoded({email}),
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
        );
    }
}
