import {isArray, isBoolean} from 'util';

export default class FormDataHelper {
    public static convertToMultipart(object): FormData {
        const form = new FormData();
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                const value = object[key];
                if (isBoolean(value)) {
                    form.append(key, value ? '1' : '0');
                } else {
                    form.append(key, value);
                }
            }
        }

        return form;
    }

    public static convertToUrlEncoded(object): string {
        const form = new URLSearchParams();
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                const value = object[key];
                if (isBoolean(value)) {
                    form.append(key, value ? '1' : '0');
                } else if (isArray(value)) {
                    for (const arrItem of value) {
                        form.append(`${key}[]`, arrItem);
                    }
                } else if (value === null) {
                    form.append(key, '');
                } else if (value === undefined) {
                    // Don't set fields with undefined value.
                } else {
                    form.append(key, value);
                }
            }
        }

        return form.toString();
    }
}
