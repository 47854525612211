import { environment } from '../../../environments/environment';
import FormDataHelper from '../../helpers/form-data-helper';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LicensesRepositoryService {
    constructor(http) {
        this.http = http;
    }
    convertLicense(email, key) {
        return this.http.post(`${environment.apiUrl}v1/licenses/convert-legacy`, FormDataHelper.convertToMultipart({ email, key }));
    }
}
LicensesRepositoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LicensesRepositoryService_Factory() { return new LicensesRepositoryService(i0.ɵɵinject(i1.HttpClient)); }, token: LicensesRepositoryService, providedIn: "root" });
