import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActionCompletion, Actions, ofActionCompleted, ofActionSuccessful, Store} from '@ngxs/store';
import {ActivatedRoute, Router} from '@angular/router';
import {PushAlert} from '../../states/alerts.actions';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import {HttpErrorCode} from '../../api/http-error-code.enum';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SessionRegisterRequest} from 'src/app/states/auth.actions';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Title} from '@angular/platform-browser';

interface FormData {
    email: string;
    code: string;
    firstName: string;
    lastName: string;
}

@Component({
    selector: 'app-login-register-page',
    templateUrl: './login-register-page.component.html',
    styleUrls: ['./login-register-page.component.scss']
})
export class LoginRegisterPageComponent implements OnInit, OnDestroy {

    /**
     * Określa czy aktualnie trwa próba zalogowania.
     */
    public isLoading = false;

    public form: FormGroup;
    public formErrors: { [key: string]: string[] } = {};

    private gc = new Subject();

    constructor(
        private store: Store,
        private router: Router,
        private actions: Actions,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private title: Title
    ) {
    }

    public async ngOnInit() {
        this.title.setTitle('Register - Dataedo Account');
        this.initForm();
        this.initEvents();

        if (!this.form.value.email || !this.form.value.code) {
            await this.redirectToHome();
        }
    }

    private initEvents() {
        this.actions.pipe(ofActionSuccessful(SessionRegisterRequest))
            .pipe(takeUntil(this.gc))
            .subscribe(action => this.onSuccessfulProfileChange(action));

        this.actions.pipe(ofActionCompleted(SessionRegisterRequest))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedProfileChange(event));
    }

    private initForm() {
        this.form = this.formBuilder.group({
            email: this.route.snapshot.queryParams.email || '',
            code: this.route.snapshot.queryParams.code || '',
            firstName: '',
            lastName: '',
        } as FormData);
    }

    private async redirectToHome() {
        // Użytkownik wszedł bezpośrednio pod adres bez danych
        // potrzebnych do poprawnego wysłania formularza.
        const message = 'Operation available only with link sent via email.';
        this.store.dispatch(new PushAlert(message, {type: 'warning'}));

        await this.router.navigateByUrl('/', {
            replaceUrl: true,
        });
    }

    public onSubmit(formData: FormData) {
        this.isLoading = true;
        this.formErrors = {};

        this.store.dispatch(new SessionRegisterRequest(formData.email, formData.code, formData.firstName, formData.lastName, this.gc));
    }

    private onSuccessfulProfileChange(action: SessionRegisterRequest) {
        // Wyświetlenie komunikatu o poprawnej rejestracji.
        const message = 'Your account has been successfully updated.';
        this.store.dispatch(new PushAlert(message, {type: 'success'}));

        this.router.navigateByUrl('/', {replaceUrl: true});
    }

    private onCompletedProfileChange(event: ActionCompletion<SessionRegisterRequest, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);

        if (response.isError && response.error.status !== HttpErrorCode.Conflict) {
            // Wyświetlenie błędów formularza.
            this.formErrors = response.error.form;
            this.isLoading = false;
        }

        if (response.isError && response.error.status === HttpErrorCode.Conflict) {
            // Wyświetlenie komunikatu o błędzie podczas rejestracji.
            this.store.dispatch(new PushAlert(response.error.message, {type: 'error'}));

            // Przekierowanie do strony do wpisania kodu uwierzytelniającego.
            return this.router.navigate(['/'], {replaceUrl: true});
        }
    }

    public ngOnDestroy(): void {
        this.gc.next();
        this.gc.complete();
    }

}
