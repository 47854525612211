import { environment } from 'src/environments/environment';
import FormDataHelper from 'src/app/helpers/form-data-helper';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AccountRegenerateTokenService {
    constructor(http) {
        this.http = http;
    }
    regenerateToken(accountId) {
        return this.http.post(`${environment.apiUrl}v1/accounts/${accountId}/regenerate-api-token`, FormDataHelper.convertToUrlEncoded({ accountId }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
    }
}
AccountRegenerateTokenService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountRegenerateTokenService_Factory() { return new AccountRegenerateTokenService(i0.ɵɵinject(i1.HttpClient)); }, token: AccountRegenerateTokenService, providedIn: "root" });
