import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActionCompletion, Actions, ofActionCompleted, Select, Store} from '@ngxs/store';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import {QuotesState} from '../../states/quotes.state';
import {FetchQuotes} from '../../states/quotes.actions';
import {QuotesModelResponse} from '../../api/responses/quotes-response';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {PushMenuActiveAccountId} from 'src/app/states/menu.actions';
import {HttpErrorCode} from 'src/app/api/http-error-code.enum';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-quotes-page',
    templateUrl: './quotes-page.component.html',
    styleUrls: ['./quotes-page.component.scss']
})
export class QuotesPageComponent implements OnInit, OnDestroy {

    public accountId: number;

    @Select(QuotesState.quotes)
    public quotesListener: Observable<QuotesModelResponse[]>;

    public quotes: QuotesModelResponse[] = [];

    /**
     * Określa czy aktualnie trwa pobieranie danych.
     */
    public isLoading = false;

    private gc = new Subject();

    /**
     * Token służący do anulowania pobierania danych.
     */
    private cancellationToken = new Subject();

    constructor(
        private store: Store,
        private actions: Actions,
        private router: Router,
        private route: ActivatedRoute,
        private title: Title
    ) {
    }

    public ngOnInit() {
        this.title.setTitle('Quotes - Dataedo Account');
        this.initEvents();
    }

    private fetchData() {
        this.cancellationToken.next();
        this.isLoading = true;

        this.store.dispatch(new FetchQuotes(this.accountId, this.cancellationToken));
    }

    private initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));

        this.quotesListener
            .pipe(takeUntil(this.gc))
            .subscribe(quotes => this.quotes = quotes);

        this.actions
            .pipe(ofActionCompleted(FetchQuotes))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchQuotes(event));
    }

    private onPathChange(params: Params) {
        this.accountId = parseInt(params.accountId, 10);
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));
        this.fetchData();
    }

    private async onCompletedFetchQuotes(event: ActionCompletion<FetchQuotes, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);
        if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
            await this.router.navigate(['/accounts', this.accountId]);
        }

        this.isLoading = false;
    }

    public trackById(index: number, quote: QuotesModelResponse): number {
        return quote.id;
    }

    public ngOnDestroy(): void {
        this.cancellationToken.next();
        this.cancellationToken.complete();

        this.gc.next();
        this.gc.complete();
    }
}
