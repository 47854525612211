import {Subject} from 'rxjs';
import {SubscriptionRoleEnum} from 'src/app/api/enums/subscription-role.enum';

export class FetchSubscriptionUsers {
    public static readonly type = '[Subscriptions] FetchSubscriptionUsers';

    constructor(public accountId: number, public cancellationToken?: Subject<any>) {
    }
}

export class SubscriptionUsersAssignLicenses {
    public static readonly type = '[Subscriptions] SubscriptionUsersAssignLicenses';

    constructor(
        public accountId: number,
        public packageId: string,
        public emails: string[],
        public cancellationToken?: Subject<any>
    ) {
    }
}

export class SubscriptionUsersRevokeLicenses {
    public static readonly type = '[Subscriptions] SubscriptionUsersRevokeLicenses';

    constructor(
        public accountId: number,
        public packageId: string,
        public emails: string[],
        public cancellationToken?: Subject<any>
    ) {
    }
}

export class SubscriptionUsersSetDomains {
    public static readonly type = '[Subscriptions] SubscriptionUsersSetDomains';

    constructor(
        public accountId: number,
        public packageId: string,
        public domains: string[],
        public cancellationToken?: Subject<any>
    ) {
    }
}
