import {Component, Input} from '@angular/core';
import {Store} from '@ngxs/store';
import {IClipboardResponse} from 'ngx-clipboard';
import {PushAlert} from '../../states/alerts.actions';

@Component({
    selector: 'app-copy-to-clipboard',
    templateUrl: './copy-to-clipboard.component.html',
    styleUrls: ['./copy-to-clipboard.component.scss']
})
export class CopyToClipboardComponent {

    @Input()
    public text: string;

    constructor(private store: Store) {
    }

    public copied(event: IClipboardResponse) {
        this.store.dispatch(new PushAlert('Copied to clipboard.'));
    }

}
