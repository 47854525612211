/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timestamp.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./timestamp.component";
var styles_TimestampComponent = [i0.styles];
var RenderType_TimestampComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimestampComponent, data: {} });
export { RenderType_TimestampComponent as RenderType_TimestampComponent };
export function View_TimestampComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "text-no-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v, 0), (_co.timestamp * 1000))); _ck(_v, 2, 0, currVal_0); }); }
export function View_TimestampComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timestamp", [], null, null, null, View_TimestampComponent_0, RenderType_TimestampComponent)), i1.ɵdid(1, 49152, null, 0, i3.TimestampComponent, [], null, null)], null, null); }
var TimestampComponentNgFactory = i1.ɵccf("app-timestamp", i3.TimestampComponent, View_TimestampComponent_Host_0, { timestamp: "timestamp" }, {}, []);
export { TimestampComponentNgFactory as TimestampComponentNgFactory };
