<app-stack-layout>
    <h1>Administration</h1>

    <app-empty class="fullsize mt-lg" *ngIf="!isLoading && roles?.items?.length === 0">
        <app-stack-layout class="text-center">
            <h2>No results found</h2>
            <p class="mt-sm">We didn't find any roles in your organization.</p>
        </app-stack-layout>
    </app-empty>
    <div class="mt-lg" *ngIf="roles?.items?.length > 0 && roles?.accountId === accountId">
        <table>
            <tr>
                <th>#</th>
                <th>Role</th>
                <th>
                    <app-stack-layout class="header" direction="row">
                        <span>Email</span>
                        <app-icon class="ml-xs" icon="down-arrow" size="14px"></app-icon>
                    </app-stack-layout>
                </th>
                <th></th>
                <th class="table-fill-column"></th>
            </tr>
            <tr *ngFor="let role of roles.items; let i = index; trackBy: trackById" [@tableRow]>
                <td>{{ i + 1 }}</td>
                <td class="text-no-wrap">
                    <span *ngIf="!roles._canAssignRoles">{{ role.role|titlecase }}</span>
                    <app-select *ngIf="roles._canAssignRoles"
                                [style.width.px]="90"
                                previewMode="auto"
                                [options]="availableRolesOptions"
                                [ngModel]="role.role"
                                (change)="assignRole(role.email, $event.target.value)"
                                [loading]="isAssigningRole[role.email] === 'pending' || isAssigningRole[role.email] === 'awaiting-refresh'"
                                size="small"></app-select>
                </td>
                <td class="text-no-wrap">
                    {{ role.email }}
                    <app-badge *ngIf="role.email === userEmail" color="success" class="ml-xs">You</app-badge>
                </td>
                <td>
                    <ng-container *ngIf="roles._canRevokeRoles">
                        <span #revokeButton class="d-flex">
                            <app-button theme="default" size="small" class="on-tr-hover"
                                        [class.on-tr-hover-force]="isRevokingRole[role.email] === 'pending' || isRevokingRole[role.email] === 'awaiting-refresh'"
                                        [loading]="isRevokingRole[role.email] === 'pending' || isRevokingRole[role.email] === 'awaiting-refresh'">
                                Revoke
                            </app-button>
                        </span>

                        <app-modal #revokeModal [reference]="revokeButton">
                            <app-stack-layout modalHeader>
                                <h2>Revoke role?</h2>
                            </app-stack-layout>

                            <app-stack-layout>
                                <div class="overflow-x">
                                    <ng-container *ngIf="this.userEmail !== role.email; else revokeSelfRole">
                                        Revoke role for {{ role.email }}?
                                    </ng-container>
                                    <ng-template #revokeSelfRole>
                                        Are you sure you want to revoke your role? This operation cannot be undone.
                                    </ng-template>
                                </div>
                            </app-stack-layout>

                            <div class="text-right" modalFooter>
                                <app-button (click)="revokeModal.hide()">Cancel</app-button>
                                <app-button class="ml-xs" (click)="revokeRole(revokeModal, role.email)">Revoke</app-button>
                            </div>
                        </app-modal>
                    </ng-container>
                </td>
                <td class="table-fill-column"></td>
            </tr>
            <tr *ngIf="roles._canAssignRoles">
                <td class="text-top">{{ roles.items.length + 1 }}</td>
                <td class="text-top">
                    <app-select #inputRole [style.width.px]="90"
                                [(ngModel)]="newRole"
                                [hasEmptyValue]="true"
                                [options]="availableRolesOptions"
                                [disabled]="isAssigningRole[newEmail] === 'pending' || isAssigningRole[newEmail] === 'awaiting-refresh'"
                                size="small"></app-select>
                    <div *ngIf="formErrors[newEmail]?.role">
                        <app-error>{{ formErrors[newEmail].role[0] }}</app-error>
                    </div>
                </td>
                <td class="text-top">
                    <app-input [(ngModel)]="newEmail" name="email" size="small"
                               [disabled]="isAssigningRole[newEmail] === 'pending' || isAssigningRole[newEmail] === 'awaiting-refresh'"
                               (keyup.enter)="assignRole(newEmail, newRole)"></app-input>
                    <div *ngIf="formErrors[newEmail]?.email">
                        <app-error>{{ formErrors[newEmail].email[0] }}</app-error>
                    </div>
                </td>
                <td class="text-top" colspan="2">
                    <app-button theme="default" size="small"
                                [loading]="isAssigningRole[newEmail] === 'pending' || isAssigningRole[newEmail] === 'awaiting-refresh'"
                                (click)="assignRole(newEmail, inputRole.value)">Assign
                    </app-button>
                </td>
                <td class="table-fill-column"></td>
            </tr>
        </table>

        <div class="mt-sm">
            <a [routerLink]="['/accounts', accountId, 'roles']" appTextAlign="oneline">
                <app-icon icon="left" size="14px" class="mr-xs"></app-icon>
                Back
            </a>
        </div>

        <app-page-bottom-space></app-page-bottom-space>
    </div>
    <app-spinner class="fullsize" *ngIf="isLoading"></app-spinner>
</app-stack-layout>
