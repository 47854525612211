import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import EducationalLicensesResponse from '../responses/educational-licenses-response';

@Injectable({
    providedIn: 'root'
})
export class EducationalLicensesRepositoryService {
    constructor(private http: HttpClient) {
    }

    public list(): Observable<EducationalLicensesResponse> {
        return this.http.get<EducationalLicensesResponse>(`${environment.apiUrl}v1/educational-licenses`);
    }
}
