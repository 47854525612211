import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {AuthState} from '../../states/auth.state';
import {AuthTokenRenewal, IntendedUrl, Logout} from '../../states/auth.actions';
import {HttpErrorCode} from 'src/app/api/http-error-code.enum';

// Type HttpRequest<any> and HttpEvent<any> generated by Angular CLI.
// As a result, the following rule apply:
// tslint:disable:no-any

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

    private static AUTH_TOKEN_RENEWAL_HEADER = 'X-Token-ExpiresAt';

    private loginFormUrl = '/';

    constructor(
        private store: Store,
        private router: Router,
        private ngZone: NgZone,
    ) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const loggedIn = this.store.selectSnapshot(AuthState.isAuthenticated);
        if (loggedIn && !req.headers.has('Authorization')) {
            const token = this.store.selectSnapshot(AuthState.token);
            req = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${token}`),
            });
        }

        return next.handle(req)
            .pipe(tap(response => this.tokenRenewal(response)))
            .pipe(catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === HttpErrorCode.Unauthorized) {
                    this.handleError();
                }

                return throwError(error);
            }));
    }

    private tokenRenewal(response: HttpEvent<any>) {
        if (response instanceof HttpResponse) {
            if (response.headers.has(UnauthorizedInterceptor.AUTH_TOKEN_RENEWAL_HEADER)) {
                const headerValue = response.headers.get(UnauthorizedInterceptor.AUTH_TOKEN_RENEWAL_HEADER);
                const tokenExpiresAt = parseInt(headerValue, 10);

                this.store.dispatch(new AuthTokenRenewal(tokenExpiresAt));
            }
        }
    }

    private handleError() {
        // Store the attempted URL for redirecting.
        const url = `${window.location.pathname}${window.location.search}${window.location.hash}`;
        this.store.dispatch(new IntendedUrl(url));

        this.store.dispatch(new Logout());
        this.ngZone.run(() => this.redirectToLoginForm()).then();
    }

    private redirectToLoginForm() {
        return this.router.navigate([this.loginFormUrl]);
    }
}
