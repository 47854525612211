import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {ActionCompletion, Actions, ofActionCompleted, ofActionSuccessful, Store} from '@ngxs/store';
import {
    ConfirmOAuth2,
    IntendedUrl,
    LoginRequest,
    LoginWithGoogle,
    LoginWithMicrosoft,
    Logout
} from '../../states/auth.actions';
import {ActivatedRoute, Router} from '@angular/router';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import {PushAlert} from '../../states/alerts.actions';
import {takeUntil} from 'rxjs/operators';
import {AuthState} from '../../states/auth.state';
import {Title} from '@angular/platform-browser';

interface FormData {
    email: string;
}

@Component({
    selector: 'app-login-email-page',
    templateUrl: './login-email-page.component.html',
    styleUrls: ['./login-email-page.component.scss']
})
export class LoginEmailPageComponent implements OnInit, OnDestroy {

    public privacyPolicyUrl = `${environment.websiteUrl}privacy`;

    /**
     * Określa czy aktualnie trwa próba zalogowania.
     */
    public isLoading = false;
    public isGoogleLoading = false;
    public isMicrosoftLoading = false;

    public form: FormGroup;
    public formErrors: { [key: string]: string[] } = {};

    /**
     * Token służący do anulowania aktualnej operacji logowania.
     */
    private cancellationToken = new Subject();

    private gc = new Subject();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private store: Store,
        private title: Title,
        private actions: Actions,
        private formBuilder: FormBuilder
    ) {
    }

    public ngOnInit() {
        this.title.setTitle('Login - Dataedo Account');
        this.initForm();
        this.initEvents();
        this.handleOAuth2();

        const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);
        if (isAuthenticated) {
            // Przy wejsciu na strone sprawdz czy uzytkownik jest zalogowany
            // i jesli jest to przekieruj go sekcji dla zalogowanych.
            return this.redirectToIntendedUrl();
        }
    }

    private initEvents() {
        this.actions.pipe(ofActionSuccessful(LoginRequest))
            .pipe(takeUntil(this.gc))
            .subscribe(action => this.onSuccessfulLogin(action));

        this.actions.pipe(ofActionCompleted(LoginRequest))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedLogin(event));

        this.actions.pipe(ofActionCompleted(LoginWithGoogle, LoginWithMicrosoft))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedLoginWithAttempt(event));
    }

    private initForm() {
        this.form = this.formBuilder.group({email: ''} as FormData);
    }

    public onSubmit(formData: FormData) {
        this.cancellationToken.next();
        this.isLoading = true;
        this.formErrors = {};

        this.store.dispatch(new LoginRequest(formData.email, this.cancellationToken));
    }

    private redirectToIntendedUrl() {
        const intendedUrl = this.store.selectSnapshot(AuthState.intendedUrl) || '/dashboard';

        // Po przekierowaniu musimy zresetować wartość intendedUrl,
        // nie możemy zrobić tego zaraz po akcji logowania, ponieważ
        // przekierowanie następuje później.
        this.store.dispatch(new IntendedUrl(null));

        return this.router.navigateByUrl(intendedUrl, {
            replaceUrl: true,
        });
    }

    private onSuccessfulLogin(action: LoginRequest) {
        // Wyświetlenie komunikatu o wysłaniu maila.
        const message = 'We have sent you an email, please check your inbox.';
        this.store.dispatch(new PushAlert(message, {type: 'success'}));

        // Przekierowanie do strony do wpisania kodu uwierzytelniającego.
        return this.router.navigate(['/login']);
    }

    private onCompletedLogin(event: ActionCompletion<LoginRequest, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);

        if (response.isError) {
            // Wyświetlenie błędów formularza.
            this.formErrors = response.error.form;
        }

        this.isLoading = false;
    }

    @HostListener('document:keydown.escape', ['$event'])
    public onEscape(event: KeyboardEvent) {
        // Cancel login action on escape button.
        this.cancellationToken.next();
    }

    private handleOAuth2() {
        const queryParams = this.route.snapshot.queryParams;
        if (queryParams.email) {
            // Jeśli jest dostępne to zasugeruj adres e-mail użytkownika,
            // ale nie zapisuj go nigdzie w lokalnej pamięci.
            this.form.get('email').setValue(queryParams.email);
        }

        if (queryParams.error) {
            // Przekazanie błędów OAuth2 do formularza.
            // Nastepuje takze automatyczne wylogowanie aby miec pewnosc,
            // że użytkownik zobaczy komunikat błędu i nie zostanie automatycznie
            // przekierowany na ostatnio zalogowane konto.
            this.formErrors = {email: [queryParams.error]};
            this.store.dispatch(new Logout());
        }

        if (queryParams.email && queryParams.userId && queryParams.token && queryParams.expiresAt) {
            // Użytkownik został poprawnie zalogowany za pomocą OAuth2,
            // posiadamy dane niezbędne do zalogowania użytkownika.
            this.store.dispatch(new ConfirmOAuth2(
                queryParams.email,
                queryParams.userId,
                queryParams.token,
                queryParams.expiresAt
            ));
        }
    }

    public loginWithGoogle() {
        this.cancellationToken.next();
        this.isGoogleLoading = true;

        this.store.dispatch(new LoginWithGoogle(this.cancellationToken));
    }

    public loginWithMicrosoft() {
        this.cancellationToken.next();
        this.isMicrosoftLoading = true;

        this.store.dispatch(new LoginWithMicrosoft(this.cancellationToken));
    }

    private onCompletedLoginWithAttempt(event: ActionCompletion<LoginRequest, Error>) {
        const message = 'Signing in, please wait...';
        this.store.dispatch(new PushAlert(message, {type: 'info'}));
    }

    public ngOnDestroy(): void {
        this.cancellationToken.next();
        this.cancellationToken.complete();

        this.gc.next();
        this.gc.complete();
    }
}
