import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ofActionCompleted, Select } from '@ngxs/store';
import { AccountsState } from '../../states/accounts.state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FetchAccountSummary } from '../../states/accounts.actions';
import { FetchMenu, PushMenuActiveAccountId } from '../../states/menu.actions';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import { HttpErrorCode } from '../../api/http-error-code.enum';
export class AccountHomePageComponent {
    constructor(store, actions, router, route, title) {
        this.store = store;
        this.actions = actions;
        this.router = router;
        this.route = route;
        this.title = title;
        this.summary = null;
        /**
         * Określa czy aktualnie trwa pobieranie danych.
         */
        this.isLoading = false;
        this.isCreatingToken = false;
        /**
         * Token służący do anulowania pobierania danych.
         */
        this.cancellationToken = new Subject();
        this.gc = new Subject();
    }
    ngOnInit() {
        this.title.setTitle('Dataedo Account');
        this.initEvents();
    }
    initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));
        this.summaryListener
            .pipe(takeUntil(this.gc))
            .subscribe(summary => this.onSummaryChanged(summary));
        this.actions
            .pipe(ofActionCompleted(FetchAccountSummary))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetch(event));
    }
    onSummaryChanged(summary) {
        if (summary) {
            this.title.setTitle(`${summary.accountName} (${summary.accountId}) - Dataedo Account`);
        }
        return this.summary = summary;
    }
    onPathChange(params) {
        this.accountId = parseInt(params.accountId, 10);
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));
        this.fetchData();
    }
    fetchData() {
        this.isLoading = true;
        this.cancellationToken.next();
        this.store.dispatch(new FetchAccountSummary(this.accountId, this.cancellationToken));
        this.store.dispatch(new FetchMenu(this.cancellationToken));
    }
    onCompletedFetch(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = ResponseHandlingHelper.parse(event.result);
            if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
                yield this.router.navigate(['/accounts']);
            }
            this.isLoading = false;
        });
    }
    ngOnDestroy() {
        this.cancellationToken.next();
        this.cancellationToken.complete();
        this.gc.next();
        this.gc.complete();
    }
    // do ukrywania/pokazywania subscrypcji ktore są trwają
    checkIfDateIsEarlier(elem) {
        const dateNow = new Date();
        const dateToCheck = new Date(elem);
        return dateToCheck <= dateNow;
    }
    checkIfDateIsLater(elem) {
        const dateNow = new Date();
        const dateToCheck = new Date(elem);
        return dateToCheck >= dateNow;
    }
}
tslib_1.__decorate([
    Select(AccountsState.summary)
], AccountHomePageComponent.prototype, "summaryListener", void 0);
