import {Directive, HostBinding, HostListener, Input} from '@angular/core';

// tslint:disable:no-input-rename
// https://www.bennadel.com/blog/3633-giving-click-anchor-links-tab-access-using-a-directive-in-angular-7-2-15.htm

@Directive({
    selector: 'a[click]:not([href]):not([role]):not([tabindex])'
})
export class TabbedLinksDirective {
    /**
     * Adding [tabindex] allows tab-based access to this element. The "0" indicates
     * that the tabbing order should follow the native DOM element ordering.
     */
    @Input('tabindex')
    @HostBinding('tabindex')
    public tabindex = 0;

    /**
     * Adding [role] tells screen readers that this "link" is really a "button",
     * in that it triggers an action, but doesn't navigate to a new resource.
     */
    @Input('role')
    @HostBinding('attr.role')
    public role = 'button';

    /**
     * Adding (keydown) allows us to translate the Enter license_key into a "click" event.
     * This is the native behavior of a link.
     */
    @HostListener('keydown.enter', ['$event'])
    public onEnterKey(event: KeyboardEvent) {
        event.preventDefault();
        (event.target as HTMLElement).click();
    }
}
