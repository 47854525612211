import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-login-troubleshooting',
  templateUrl: './login-troubleshooting.component.html',
  styleUrls: ['./login-troubleshooting.component.scss']
})
export class LoginTroubleshootingComponent implements OnInit {

    private mailSubject = 'Problem with sign in (code not delivered)';
    public contactHref = `mailto:${environment.supportMailAddress}?subject${this.mailSubject}`;

    constructor(private title: Title) {
    }

    public ngOnInit(): void {
        this.title.setTitle('Troubleshooting - Dataedo Account');
    }

}
