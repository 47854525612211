import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActionCompletion, Actions, ofActionCompleted, Select, Store} from '@ngxs/store';
import {AccountsState} from 'src/app/states/accounts.state';
import {Observable, Subject} from 'rxjs';
import {AccountBillingModelResponse} from 'src/app/api/responses/account-billing-response';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {FetchAccountBilling, UpdateBillingDetails} from 'src/app/states/accounts.actions';
import {PushMenuActiveAccountId} from 'src/app/states/menu.actions';
import ResponseHandlingHelper from 'src/app/helpers/response-handling-helper';
import {HttpErrorCode} from 'src/app/api/http-error-code.enum';
import {CountriesModelResponse} from 'src/app/api/responses/countries-response';
import {CountriesState} from 'src/app/states/countries.state';
import {FetchCountries} from 'src/app/states/countries.actions';
import SelectOption from 'src/app/elements/select/select-option';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PushAlert} from 'src/app/states/alerts.actions';
import {Title} from '@angular/platform-browser';

interface FormData {
    company: string;
    address: string;
    address2: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;
    vatID: string;
}

@Component({
    selector: 'app-account-billing-edit-page',
    templateUrl: './account-billing-edit-page.component.html',
    styleUrls: ['./account-billing-edit-page.component.scss']
})
export class AccountBillingEditPageComponent implements OnInit, OnDestroy {

    public accountId: number;

    @Select(AccountsState.billing)
    public billingListener: Observable<AccountBillingModelResponse | null>;
    public billing: AccountBillingModelResponse | null = null;

    @Select(CountriesState.glossary)
    public countriesListener: Observable<CountriesModelResponse[]>;
    public countries: CountriesModelResponse[] = [];

    public countriesOptions: SelectOption[] = [];

    public form: FormGroup;
    public formErrors: { [key: string]: string[] } = {};

    private gc = new Subject();

    /**
     * Określa czy aktualnie trwa pobieranie danych.
     */
    public isLoadingBilling = false;
    public isLoadingCountries = false;
    public isFormProcessing = false;

    /**
     * Token służący do anulowania pobierania danych.
     */
    private cancellationToken = new Subject();
    private formCancellationToken = new Subject();

    constructor(
        private store: Store,
        private actions: Actions,
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private title: Title
    ) {
    }

    public ngOnInit() {
        this.title.setTitle('Billing & Payment - Dataedo Account');
        this.initForm();
        this.initEvents();
    }

    private initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));

        this.billingListener
            .pipe(takeUntil(this.gc))
            .subscribe(billing => {
                this.billing = billing;
                if (billing && billing.billingDetails) {
                    this.form.get('company').setValue(billing.billingDetails.companyName);
                    this.form.get('address').setValue((billing.billingDetails.address && billing.billingDetails.address[0]) || '');
                    this.form.get('address2').setValue((billing.billingDetails.address && billing.billingDetails.address[1]) || '');
                    this.form.get('postalCode').setValue(billing.billingDetails.postalCode);
                    this.form.get('city').setValue(billing.billingDetails.city);
                    this.form.get('state').setValue(billing.billingDetails.state);
                    this.form.get('country').setValue(billing.billingDetails.country);
                    this.form.get('vatID').setValue(billing.billingDetails.vatID);
                }
            });

        this.countriesListener
            .pipe(takeUntil(this.gc))
            .subscribe(countries => {
                this.countries = countries;
                this.countriesOptions = countries.map(x => ({value: x.id, text: x.name}))
            });

        this.actions
            .pipe(ofActionCompleted(FetchAccountBilling))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchBilling(event));

        this.actions
            .pipe(ofActionCompleted(FetchCountries))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchCountries(event));

        this.actions
            .pipe(ofActionCompleted(UpdateBillingDetails))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedUpdateBilling(event));
    }

    private initForm() {
        this.form = this.formBuilder.group({
            company: '',
            address: '',
            address2: '',
            postalCode: '',
            city: '',
            state: '',
            country: '',
            vatID: '',
        } as FormData);
    }

    private onPathChange(params: Params) {
        this.accountId = parseInt(params.accountId, 10);
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));

        this.fetchData();
    }

    private fetchData() {
        this.isLoadingBilling = true;
        this.isLoadingCountries = true;
        this.isFormProcessing = false;

        this.cancellationToken.next();
        this.store.dispatch(new FetchCountries(this.cancellationToken));
        this.store.dispatch(new FetchAccountBilling(this.accountId, this.cancellationToken));
    }

    private async onCompletedFetchBilling(event: ActionCompletion<FetchAccountBilling, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);
        if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
            await this.router.navigate(['/accounts', this.accountId]);
        }

        this.isLoadingBilling = false;
    }

    private onCompletedFetchCountries(event: ActionCompletion<any, Error>) {
        this.isLoadingCountries = false;
    }

    public onSubmit(formData: FormData) {
        this.formCancellationToken.next();
        this.isFormProcessing = true;
        this.formErrors = {};

        this.store.dispatch(new UpdateBillingDetails(this.accountId, formData, this.formCancellationToken));
    }

    private onCompletedUpdateBilling(event: ActionCompletion<UpdateBillingDetails, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);
        if (response.isError && response.error.status === HttpErrorCode.UnprocessableEntity) {
            this.formErrors = response.error.form;
        }

        if (response.isSuccessful) {
            this.store.dispatch(new PushAlert('Billing details have been successfully updated.', {type: 'success'}));
            this.router.navigate(['/accounts', this.accountId, 'billing']);
        }

        this.isFormProcessing = false;
    }

    public ngOnDestroy(): void {
        this.cancellationToken.next();
        this.cancellationToken.complete();

        this.gc.next();
        this.gc.complete();
    }
}
