<app-stack-layout>
    <h1>Educational Licenses</h1>

    <app-empty class="fullsize mt-lg" *ngIf="!isLoading && licenses.length === 0">
        <app-stack-layout class="text-center">
            <h2>No results found</h2>
            <p class="mt-sm">You don't have any educational licenses.</p>
            <div class="mt-md">
                <a [attr.href]="educationalLicenseUrl" target="_blank">
                    Apply for educational license
                    <app-icon icon="external-link" size="16px"></app-icon>
                </a>
            </div>
        </app-stack-layout>
    </app-empty>
    <div class="mt-lg" *ngIf="licenses.length > 0">
        <table>
            <tr>
                <th>#</th>
                <th>
                    <app-stack-layout class="header" direction="row">
                        <span>Started on</span>
                        <app-icon class="ml-xs" icon="down-arrow" size="14px"></app-icon>
                    </app-stack-layout>
                </th>
                <th>Expires on</th>
                <th>Status</th>
                <th>License key</th>
                <th class="table-fill-column"></th>
            </tr>
            <tr *ngFor="let license of licenses; let i = index; trackBy: trackLicenseById">
                <td>{{ i + 1 }}</td>
                <td>
                    <app-timestamp [timestamp]="license.started_at"></app-timestamp>
                </td>
                <td>
                    <app-timestamp [timestamp]="license.expires_at"></app-timestamp>
                </td>
                <td>
                    <app-badge [color]="license.status.color">{{ license.status.text }}</app-badge>
                </td>
                <td>
                    <app-license-key [licenseKey]="license.license_key"></app-license-key>
                </td>
                <td class="table-fill-column"></td>
            </tr>
        </table>
    </div>
    <app-spinner class="fullsize" *ngIf="isLoading"></app-spinner>
</app-stack-layout>
