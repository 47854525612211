import {Component, Input, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {PackagesRepositoryService} from '../../api/repositories/packages-repository.service';
import {finalize, takeUntil} from 'rxjs/operators';
import PackageModulesResponse from '../../api/responses/package-modules-response';

@Component({
    selector: 'app-package-modules',
    templateUrl: './package-modules.component.html',
    styleUrls: ['./package-modules.component.scss'],
})
export class PackageModulesComponent implements OnDestroy {

    @Input()
    public packageCode?: string;
    public modules?: PackageModulesResponse;

    @Input()
    public header?: string;

    public loading = false;
    public error?: string;

    private cancellationToken = new Subject();

    constructor(private packagesRepositoryService: PackagesRepositoryService) {
    }

    public loadPackageDetails(): void {
        this.cancellationToken.next();
        this.loading = true;
        this.error = undefined;

        this.packagesRepositoryService
            .getModules(this.packageCode)
            .pipe(finalize(() => this.loading = false))
            .pipe(takeUntil(this.cancellationToken))
            .subscribe({
                next: response => this.modules = response,
                error: _ => this.error = 'Something goes wrong, please try again later.',
            });
    }

    public ngOnDestroy(): void {
        this.cancellationToken.next();
        this.cancellationToken.complete();
    }
}
