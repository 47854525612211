<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
    <app-stack-layout>
        <h2>Sign in</h2>
        <p class="mt-sm">Please login with your email.</p>

        <app-label class="mt-md">E-mail</app-label>
        <app-stack-layout direction="row" class="align-items-start">
            <app-input name="email" formControlName="email"></app-input>
            <app-button [loading]="isLoading" theme="primary" class="ml-xs">Next</app-button>
        </app-stack-layout>
        <div *ngIf="formErrors.email">
            <app-error>{{ formErrors.email[0] }}</app-error>
        </div>
    </app-stack-layout>
</form>

<app-stack-layout>
    <div class="line my-md">
        <span class="line-label">or</span>
    </div>

    <app-button [loading]="isMicrosoftLoading" (click)="loginWithMicrosoft()"
                icon="microsoft" iconTheme="color">
        Sign in with Microsoft
    </app-button>

    <app-button [loading]="isGoogleLoading" (click)="loginWithGoogle()"
                icon="google" iconTheme="color" class="mt-xs">
        Sign in with Google
    </app-button>

    <small class="mt-lg">
        By using Dataedo Account you agree to <a [href]="privacyPolicyUrl" target="_blank">Privacy Policy</a>.
    </small>
</app-stack-layout>
