import {Subject} from 'rxjs';

export class FetchAccountSummary {
    public static readonly type = '[Accounts] FetchAccountSummary';

    constructor(public accountId: number, public cancellationToken?: Subject<any>) {
    }
}

export class FetchAccountSubscription {
    public static readonly type = '[Accounts] FetchAccountSubscription';

    constructor(public accountId: number, public cancellationToken?: Subject<any>) {
    }
}

export class FetchAccountBilling {
    public static readonly type = '[Accounts] FetchAccountBilling';

    constructor(public accountId: number, public cancellationToken?: Subject<any>) {
    }
}

export class DownloadOfflineLicense {
    public static readonly type = '[Accounts] DownloadOfflineLicense';

    constructor(public accountId: number, public cancellationToken?: Subject<any>) {
    }
}

export interface UpdateBillingDetailsForm {
    company: string;
    address: string;
    address2: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;
    vatID: string;
}

export class UpdateBillingDetails {
    public static readonly type = '[Accounts] UpdateBillingDetails';

    constructor(public accountId: number, public formData: UpdateBillingDetailsForm, public cancellationToken?: Subject<any>) {
    }
}
