import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActionCompletion, Actions, ofActionCompleted, Select, Store} from '@ngxs/store';
import {AccountsState} from '../../states/accounts.state';
import {Observable, Subject} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {takeUntil} from 'rxjs/operators';
import {FetchAccountSummary} from '../../states/accounts.actions';
import {FetchMenu, PushMenuActiveAccountId} from '../../states/menu.actions';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import {HttpErrorCode} from '../../api/http-error-code.enum';
import {AccountSummaryModelResponse} from '../../api/responses/account-summary.response';

@Component({
    selector: 'app-account-home-page',
    templateUrl: './account-home-page.component.html',
    styleUrls: ['./account-home-page.component.scss'],
})
export class AccountHomePageComponent implements OnInit, OnDestroy {

    public accountId: number;

    @Select(AccountsState.summary)
    public summaryListener: Observable<AccountSummaryModelResponse | null>;
    public summary: AccountSummaryModelResponse | null = null;

    /**
     * Określa czy aktualnie trwa pobieranie danych.
     */
    public isLoading = false;
    public isCreatingToken = false;

    /**
     * Token służący do anulowania pobierania danych.
     */
    private cancellationToken = new Subject();

    private gc = new Subject();

    constructor(
        private store: Store,
        private actions: Actions,
        private router: Router,
        private route: ActivatedRoute,
        private title: Title
    ) {
    }

    public ngOnInit() {
        this.title.setTitle('Dataedo Account');
        this.initEvents();
    }

    private initEvents() {
        this.route.params
            .pipe(takeUntil(this.gc))
            .subscribe(params => this.onPathChange(params));

        this.summaryListener
            .pipe(takeUntil(this.gc))
            .subscribe(summary => this.onSummaryChanged(summary));

        this.actions
            .pipe(ofActionCompleted(FetchAccountSummary))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetch(event));
    }

    private onSummaryChanged(summary: AccountSummaryModelResponse) {
        if (summary) {
            this.title.setTitle(`${summary.accountName} (${summary.accountId}) - Dataedo Account`);
        }

        return this.summary = summary;
    }

    private onPathChange(params: Params) {
        this.accountId = parseInt(params.accountId, 10);
        this.store.dispatch(new PushMenuActiveAccountId(this.accountId));

        this.fetchData();
    }

    private fetchData() {
        this.isLoading = true;

        this.cancellationToken.next();
        this.store.dispatch(new FetchAccountSummary(this.accountId, this.cancellationToken));
        this.store.dispatch(new FetchMenu(this.cancellationToken));
    }

    private async onCompletedFetch(event: ActionCompletion<FetchAccountSummary, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);
        if (response.isError && response.error.status === HttpErrorCode.Forbidden) {
            await this.router.navigate(['/accounts']);
        }

        this.isLoading = false;
    }

    public ngOnDestroy(): void {
        this.cancellationToken.next();
        this.cancellationToken.complete();

        this.gc.next();
        this.gc.complete();
    }

    // do ukrywania/pokazywania subscrypcji ktore są trwają
    public checkIfDateIsEarlier(elem) {
        const dateNow = new Date();
        const dateToCheck = new Date(elem);

        return dateToCheck <= dateNow;
    }

    public checkIfDateIsLater(elem) {
        const dateNow = new Date();
        const dateToCheck = new Date(elem);

        return dateToCheck >= dateNow;
    }
}
