import {AlertOptions} from './alerts.state';

/**
 * Dodaje wiadomość na koniec kolejki.
 */
export class PushAlert {
    public static readonly type = '[Alerts] PushAlert';

    constructor(
        public message: string,
        public options?: AlertOptions
    ) {
    }
}

/**
 * Usuwa wiadomość z początku kolejki
 */
export class PopAlert {
    public static readonly type = '[Alerts] PopAlert';
}
